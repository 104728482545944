<template>
  <div
    class="product-card"
    role="button"
    tabIndex="0"
    @click="emit('handleSelect', product.id)"
    @keypress="emit('handleSelect', product.id)"
  >
    <div class="product-image" :class="{ active: isLastSelectedProduct }">
      <OptimizedImg
        :alt="product.title"
        :size="{
          height: 224,
          maxWidth: 170,
          displayWidth: 'calc(100vw - 250px)',
        }"
        :src="product.image"
      />
      <div class="add-to-cart">
        <template v-if="isSelected">
          <SvgIcon class="product-remove-icon" name="product-remove" />
          <SvgIcon class="product-added-icon" name="product-added" />
        </template>
        <SvgIcon v-else class="product-add-icon" name="product-add" />
      </div>
    </div>
    <div class="product-info">
      <p class="product-description">{{ product.cartInfoText }}</p>
      <p class="product-title">
        {{ product.title }}
      </p>
      <p class="product-price">
        <span v-if="product.price">
          <template v-if="product.salePrice">
            <span class="active-price">
              {{ floatToCurrency(product.salePrice) }}
            </span>
            <span class="disabled-price">
              {{ floatToCurrency(product.price) }}
            </span>
          </template>
          <span v-else class="active-price">
            {{ floatToCurrency(product.price) }}
          </span>
        </span>
        <span class="vat">
          {{
            showTax ? __('products', 'incl. VAT') : __('products', 'excl. VAT')
          }}
        </span>
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { floatToCurrency, getCurrentSessionValue } from '@/Helpers/Global'
import { __ } from '@/Helpers/i18n'
import SvgIcon from '@/Partials/SvgIcon.vue'
import OptimizedImg from '@/Partials/ui/OptimizedImg.vue'
import { ProductVMType } from '@/ViewModels/ProductVM'
import { computed } from 'vue'

defineProps<{
  product: ProductVMType<'gallery_photos' | 'sample_set_products'>
  isSelected: boolean
  isLastSelectedProduct: boolean
}>()

const emit = defineEmits<{
  (e: 'handleSelect', data: number): void
}>()

const showTax = computed(() => !!getCurrentSessionValue('SHOW_TAX'))
</script>

<style scoped lang="scss">
.product-card {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  user-select: none;
  width: 100%;
  transition: 0.2s ease;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

@media (hover: hover) {
  .product-card:hover .product-image {
    outline: 1.8px solid $gray-8;
    padding: 4px;

    .product-added-icon {
      opacity: 0;
    }
    .product-remove-icon {
      opacity: 1;
    }

    .product-add-icon {
      fill: $black;
      color: $white;
    }
  }

  .product-card:hover .active {
    outline: 1.8px solid $black;
    padding: 4px;
  }
}

.product-remove-icon {
  opacity: 0;
}
.product-added-icon {
  opacity: 1;
}

.product-card {
  cursor: pointer;
}

.product-image {
  position: relative;
  overflow: hidden;
  height: 224px;
  outline: 1.8px solid transparent;
  transition: 0.2s ease;
}

.active {
  outline: 1.8px solid $black;
  padding: 0;
}

.product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.add-to-cart {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 20px;
  width: 20px;
  svg {
    position: absolute;
    height: 20px;
    width: 20px;
    transition: 0.2s ease;
  }
}

.product-added-icon {
  fill: $black;
  color: $white;
}

.product-add-icon {
  fill: $white;
  color: $black;
}

.product-remove-icon {
  fill: $black;
  color: $white;
}

.product-info {
  padding-right: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-content: stretch;
  flex: 1;
}

.product-title {
  font-size: 14px;
  font-weight: 500;
  margin-top: 4px;
}

.product-description {
  font-size: 10px;
  color: $gray-1;
  font-weight: 500;
  margin-top: 16px;
}

.product-price {
  margin-top: 4px;
}

.product-block-bottom {
  margin-top: 0.6rem;
  transition: 0.2s ease;
}

.disabled-price {
  color: $gray-3;
  text-decoration: line-through;
  margin-right: 0.6rem;
}

.active-price {
  margin-right: 0.6rem;
}

.vat {
  color: $gray-1;
  font-size: 1.2rem;
}
</style>
