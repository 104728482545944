<template>
  <button
    class="btn"
    :class="[btnStyle, size, { loading: delayedLoading }]"
    :disabled
    :type
  >
    <slot name="icon-left"></slot>
    <span class="text"><slot></slot></span>
    <slot name="icon-right"></slot>
  </button>
</template>

<script setup lang="ts">
import { onBeforeUnmount, ref, watch } from 'vue'

const props = withDefaults(
  defineProps<{
    type: 'button' | 'submit'
    btnStyle?:
      | 'btn-primary'
      | 'btn-secondary'
      | 'btn-cta'
      | 'btn-orange'
      | 'btn-orange-inverted'
      | 'btn-gray'
      | 'btn-success'
      | 'btn-danger'
      | 'btn-outlined'
      | 'btn-outlined-black'
      | 'btn-outlined-white'
      | 'btn-transparent'
      | 'btn-white'
    disabled?: boolean
    count?: number | null
    isLoading?: boolean
    size?: 'btn-small' | 'btn-medium' | 'btn-large'
  }>(),
  {
    btnStyle: 'btn-primary',
    disabled: false,
    count: null,
    isLoading: false,
    size: 'btn-medium',
  },
)

const delayedLoading = ref(false)
let timeoutId: ReturnType<typeof setTimeout> | null = null

watch(
  () => props.isLoading,
  (newVal) => {
    if (newVal) {
      timeoutId = setTimeout(() => {
        delayedLoading.value = true
      }, 1000) // Delay of 1 second
    } else {
      if (timeoutId) {
        clearTimeout(timeoutId)
        timeoutId = null
      }
      delayedLoading.value = false
    }
  },
)

onBeforeUnmount(() => {
  if (timeoutId) {
    clearTimeout(timeoutId)
  }
})
</script>

<style scoped lang="scss">
.btn {
  outline: none;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  border-radius: 100px;
  display: flex;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  transition: 0.3s all;
  font-size: 12px;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;

  &-small {
    padding: 12px 14px;
  }

  &-medium {
    padding: 14px 24px;
  }

  &-large {
    padding: 16px 24px;
  }

  &-primary {
    background: $black;
    color: $white;
    border: 1px solid $black;

    &:hover,
    &:focus {
      background: $gray-7;
    }
  }

  &-secondary {
    background: $white;
    color: $black;
    border: 1px solid $black;

    &:hover,
    &:focus {
      background: $black;
      color: $white;
    }
  }

  &-gray {
    background: $gray-4;
    color: $black;
    border: 1px solid transparent;

    &:hover,
    &:focus {
      background: $gray-7;
      color: $white;
    }
  }

  &-white {
    border: 1px solid $white;
    background-color: $white;
    color: $black;

    &:hover {
      background: $black;
      border-color: $black;
      color: $white;
    }
  }
}
.text {
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  position: relative;
  pointer-events: none;

  &:after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.0' viewBox='0 0 128 70' xml:space='preserve'%3E%3Crect width='100%25' height='100%25' fill='transparent'/%3E%3Ccircle fill='%23000' r='11' transform='translate(16 56)'%3E%3CanimateTransform attributeName='transform' type='translate' additive='sum' values='0,1;0,-40;0,1;0,1;0,1;0,1;0,1;0,1;0,1;0,1' dur='1800ms' repeatCount='indefinite'/%3E%3C/circle%3E%3Ccircle fill='%23000' r='11' transform='translate(64 56)'%3E%3CanimateTransform attributeName='transform' type='translate' additive='sum' values='0,1;0,1;0,1;0,1;0,-40;0,1;0,1;0,1;0,1;0,1' dur='1800ms' repeatCount='indefinite'/%3E%3C/circle%3E%3Ccircle fill='%23000' r='11' transform='translate(112 56)'%3E%3CanimateTransform attributeName='transform' type='translate' additive='sum' values='0,1;0,1;0,1;0,1;0,1;0,1;0,1;0,-40;0,1;0,1' dur='1800ms' repeatCount='indefinite'/%3E%3C/circle%3E%3C/svg%3E");
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: 24px;
    background-position: center;
    background-color: $white;
    z-index: 2;
    border-radius: 100px;
  }
}

button:disabled {
  opacity: 1;
  color: $gray-1;
  background: $gray-8;
  border: 1px solid $gray-8;
}
</style>
