export enum VatStatus {
  DENIED = 'DENIED',
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
}

export type Profile = {
  id: string
  first_name: string | null
  last_name: string | null
  email: string | null
  phone: string | null
  is_confirmed: boolean
  is_onboarding_completed: boolean
  is_privileged: boolean
  is_pro: boolean
  receive_emails: boolean
  receive_sms: boolean
  new_sign_up: boolean
  legal_entity: boolean
  company_name: string | null
  registration_number: string | null
  country: string | null
  address: string | null
  user_discounts: { [key: string]: string } | null
  vat_id: string | null
  vat_status: VatStatus | null
  vat_status_decoded: string | null
  vat_certificate: string | null
}

export type BannerData = {
  bannerClass: string
  bannerIcon?: string
  buttonLabel?: string
  buttonClass?: string
  bannerText?: string
  bannerImageSrc?: string
  bannerImageAlt?: string
  descriptionText?: string
  descriptionTextClass?: string
  headerText1?: string
  headerText2?: string
  headerTextPercentage?: string
  headerText?: string
  iconName?: string
  iconClass?: string
  imageClass?: string
  mobileBannerImageSrc?: string
  mobileBannerImageAlt?: string
  showButton: boolean
  serviceProductCode?: string
  textClass?: string
  textWrapper?: string
}
