<template>
  <h1 class="form-flow-heading">
    {{ __('onboarding', 'name.main-heading.text') }}
  </h1>
  <form action="" class="name-form-container" @submit.prevent="handleSubmit">
    <BaseInputGroup>
      <BaseInput
        v-model="firstName"
        :error-message="getFieldError(errors, 'first_name')"
        id-name="first_name"
        :label="__('onboarding', 'name.first-name-input.text')"
        type="text"
      />
      <BaseInput
        v-model="lastName"
        :error-message="getFieldError(errors, 'last_name')"
        id-name="last_name"
        :label="__('onboarding', 'name.last-name-input.text')"
        type="text"
      />
    </BaseInputGroup>
    <div class="buttons-container">
      <BaseButton :is-loading="isSubmitting" type="submit">{{
        __('global', 'Continue')
      }}</BaseButton>
    </div>
  </form>
</template>

<script setup lang="ts">
import BaseButton from '@/Components/BaseButton.vue'
import BaseInput from '@/Components/BaseInput.vue'
import { getFieldError } from '@/Helpers/Errors'
import { __ } from '@/Helpers/i18n'
import { onMounted, ref } from 'vue'
import BaseInputGroup from '@/Components/BaseInputGroup.vue'
import {
  useOnboardingStore,
  useOnboardingStoreRefs,
} from '../Store/useOnboardingStore'

const firstName = ref('')
const lastName = ref('')
const onboardingStore = useOnboardingStore()
const { updateProfileName } = onboardingStore
const { isSubmitting, errors, onboardingInfo } = useOnboardingStoreRefs()

const handleSubmit = () => {
  updateProfileName({
    first_name: firstName.value,
    last_name: lastName.value,
  })
}

onMounted(() => {
  firstName.value = onboardingInfo.value!.first_name || ''
  lastName.value = onboardingInfo.value!.last_name || ''
})
</script>

<style lang="scss" scoped>
.name-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  width: 100%;
  margin: auto;
  margin-top: 32px;
  @media (min-width: 1025px) {
    margin-top: 40px;
  }
}

.buttons-container {
  display: flex;
  gap: 6px;
  margin-top: 48px;
}
</style>
