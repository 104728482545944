import { SiteTreeSectionType } from '@/Types/SiteTreeSection'
import { PlatformType } from '@/Types/Store'

const showNewLandingPages =
  import.meta.env.VITE_SHOW_NEW_LANDING_PAGES === 'true'

const navigationConfig = [
  {
    mainTitle: 'navigation.skincare.main-title',
    mainBlocks: [
      {
        type: 'CATALOGUE' as const,
        titleBadge: {
          text: 'navigation.skincare.catalogue-block.title-badge.text',
          variant: 'lightblue' as const,
        },
        // TODO: define the actual product categories (site tree types) here when they are defined via site-tree
      },
      {
        type: 'SUGGESTIONS' as const,
        titleBadge: {
          text: 'navigation.skincare.suggestions-block.title-badge.text',
          variant: 'lightpink' as const,
        },
        image: {
          url: 'navigation.skincare.suggestions-block.image.url',
          alt: 'navigation.skincare.suggestions-block.image.alt',
        },
        title: 'navigation.skincare.suggestions-block.title',
        description: 'navigation.skincare.suggestions-block.description',
        link: {
          text: 'navigation.skincare.suggestions-block.link.text',
          url: 'navigation.skincare.suggestions-block.link.url',
        },
      },
    ],
    sidebarTitle: 'navigation.skincare.sidebar-title',
    sidebarBlocks: [
      {
        type: 'PLAIN_LINKS' as const,
        titleBadge: {
          text: 'navigation.skincare.white-label-block.title-badge.text',
        },
        sectionTypes: [
          'face-care',
          'body-care',
          'hair-care',
          'collections',
        ] satisfies SiteTreeSectionType[],
      },
      {
        type: 'PLAIN_LINKS' as const,
        titleBadge: {
          text: 'navigation.skincare.manufacturing-block.title-badge.text',
        },
        sectionTypes: ['formulas', 'claims'] satisfies SiteTreeSectionType[],
      },
    ],
  },
  {
    mainTitle: 'navigation.services.main-title',
    mainBlocks: [
      {
        type: 'TEXTS_WITH_ICONS' as const,
        titleBadge: {
          text: 'navigation.services.selling-block.title-badge.text',
          variant: 'dark-green' as const,
        },
        items: [
          {
            icon: 'book-open-2' as const,
            title: 'navigation.services.selling-block.how-it-works.title',
            description:
              'navigation.services.selling-block.how-it-works.description',
            url: 'navigation.services.selling-block.how-it-works.url',
          },
          {
            icon: 'globe' as const,
            title: 'navigation.services.selling-block.dropshipping.title',
            description:
              'navigation.services.selling-block.dropshipping.description',
            url: 'navigation.services.selling-block.dropshipping.url',
          },
          ...(showNewLandingPages
            ? [
                {
                  icon: 'package-sent' as const,
                  title: 'navigation.services.selling-block.selling.title',
                  description:
                    'navigation.services.selling-block.selling.description',
                  url: 'navigation.services.selling-block.selling.url',
                },
              ]
            : []),
        ],
      },
      {
        type: 'TEXTS_WITH_ICONS' as const,
        titleBadge: {
          text: 'navigation.services.design-block.title-badge.text',
          variant: 'light-green' as const,
        },
        items: [
          {
            icon: 'paintbrush' as const,
            title: 'navigation.services.design-block.design-service.title',
            description:
              'navigation.services.design-block.design-service.description',
            url: 'navigation.services.design-block.design-service.url',
          },
          ...(showNewLandingPages
            ? [
                {
                  icon: 'landscape' as const,
                  title: 'navigation.services.design-block.design-studio.title',
                  description:
                    'navigation.services.design-block.design-studio.description',
                  url: 'navigation.services.design-block.design-studio.url',
                },
              ]
            : []),
        ],
      },
      {
        type: 'INTEGRATIONS' as const,
        titleBadge: {
          text: 'navigation.services.integrations-block.title-badge.text',
          variant: 'gray-black' as const,
        },
        integrations: [
          {
            type: PlatformType.Shopify,
            url: 'navigation.services.integrations-block.shopify.url',
          },
          {
            type: PlatformType.WooCommerce,
            url: 'navigation.services.integrations-block.woocommerce.url',
          },
        ],
      },
    ],
    sidebarTitle: 'navigation.services.sidebar-title',
    sidebarBlocks: [
      {
        type: 'PLAIN_LINKS' as const,
        titleBadge: {
          text: 'navigation.services.industries-block.title-badge.text',
          variant: 'darkgray' as const,
        },
        links: [
          {
            text: 'navigation.services.industries-block.enterprise.text',
            url: 'navigation.services.industries-block.enterprise.url',
          },
          {
            text: 'navigation.services.industries-block.e-commerce.text',
            url: 'navigation.services.industries-block.e-commerce.url',
          },
        ],
      },
      {
        type: 'TEXTS_WITH_IMAGE' as const,
        titleBadge: {
          text: 'navigation.services.suggested-block.title-badge.text',
          variant: 'darkblue' as const,
        },
        items: [
          {
            image: {
              url: 'navigation.services.suggested-block.item-1.image.url',
              alt: 'navigation.services.suggested-block.item-1.image.alt',
            },
            title: 'navigation.services.suggested-block.item-1.title',
            description:
              'navigation.services.suggested-block.item-1.description',
            link: {
              text: 'navigation.services.suggested-block.item-1.link.text',
              url: 'navigation.services.suggested-block.item-1.link.url',
            },
          },
        ],
      },
    ],
  },
  {
    mainTitle: 'navigation.resources.main-title',
    mainBlocks: [
      {
        type: 'PLAIN_LINKS' as const,
        variant: 'lg' as const,
        titleBadge: {
          text: 'navigation.resources.help-center-block.title-badge.text',
          variant: 'light-green' as const,
        },
        links: [
          {
            text: 'navigation.resources.help-center-block.getting-started.text',
            url: 'navigation.resources.help-center-block.getting-started.url',
          },
          {
            text: 'navigation.resources.help-center-block.shipping.text',
            url: 'navigation.resources.help-center-block.shipping.url',
          },
          {
            text: 'navigation.resources.help-center-block.integrations.text',
            url: 'navigation.resources.help-center-block.integrations.url',
          },
          {
            text: 'navigation.resources.help-center-block.compliance.text',
            url: 'navigation.resources.help-center-block.compliance.url',
          },
          {
            text: 'navigation.resources.help-center-block.about-us.text',
            url: 'navigation.resources.help-center-block.about-us.url',
          },
        ],
      },
      {
        type: 'TUTORIALS' as const,
        titleBadge: {
          text: 'navigation.resources.tutorials-block.title-badge.text',
          variant: 'lightblue' as const,
        },
        buttonText: 'navigation.resources.tutorials-block.button-text',
        tutorials: [
          {
            image: {
              url: 'navigation.resources.tutorials-block.tutorials.tutorial-1.image.url',
              alt: 'navigation.resources.tutorials-block.tutorials.tutorial-1.image.alt',
            },
            url: 'navigation.resources.tutorials-block.tutorials.tutorial-1.url',
          },
          {
            image: {
              url: 'navigation.resources.tutorials-block.tutorials.tutorial-2.image.url',
              alt: 'navigation.resources.tutorials-block.tutorials.tutorial-2.image.alt',
            },
            url: 'navigation.resources.tutorials-block.tutorials.tutorial-2.url',
          },
          {
            image: {
              url: 'navigation.resources.tutorials-block.tutorials.tutorial-3.image.url',
              alt: 'navigation.resources.tutorials-block.tutorials.tutorial-3.image.alt',
            },
            url: 'navigation.resources.tutorials-block.tutorials.tutorial-3.url',
          },
        ],
      },
    ],
    sidebarTitle: 'navigation.resources.sidebar-title',
    sidebarBlocks: [
      {
        type: 'TEXTS_WITH_IMAGE' as const,
        titleBadge: {
          text: 'navigation.resources.blogs-block.title-badge.text',
          variant: 'darkpink' as const,
        },
        items: [
          {
            image: {
              url: 'navigation.resources.blogs-block.item-1.image.url',
              alt: 'navigation.resources.blogs-block.item-1.image.alt',
            },
            title: 'navigation.resources.blogs-block.item-1.title',
            description: 'navigation.resources.blogs-block.item-1.description',
            link: {
              text: 'navigation.resources.blogs-block.item-1.link.text',
              url: 'navigation.resources.blogs-block.item-1.link.url',
            },
          },
          {
            image: {
              url: 'navigation.resources.blogs-block.item-2.image.url',
              alt: 'navigation.resources.blogs-block.item-2.image.alt',
            },
            title: 'navigation.resources.blogs-block.item-2.title',
            description: 'navigation.resources.blogs-block.item-2.description',
            link: {
              text: 'navigation.resources.blogs-block.item-2.link.text',
              url: 'navigation.resources.blogs-block.item-2.link.url',
            },
          },
          {
            image: {
              url: 'navigation.resources.blogs-block.item-3.image.url',
              alt: 'navigation.resources.blogs-block.item-3.image.alt',
            },
            title: 'navigation.resources.blogs-block.item-3.title',
            description: 'navigation.resources.blogs-block.item-3.description',
            link: {
              text: 'navigation.resources.blogs-block.item-3.link.text',
              url: 'navigation.resources.blogs-block.item-3.link.url',
            },
          },
        ],
        ctaButton: {
          text: 'navigation.resources.blogs-block.cta-button.text',
          url: 'navigation.resources.blogs-block.cta-button.url',
        },
      },
    ],
  },
]

export default navigationConfig

type AnyNavigationBlock = (typeof navigationConfig)[number][
  | 'mainBlocks'
  | 'sidebarBlocks'][number]

export type NavigationBlock<
  T extends AnyNavigationBlock['type'] = AnyNavigationBlock['type'],
> = AnyNavigationBlock & { type: T }
