<template>
  <div v-show="open" class="side-cart-overlay">
    <transition name="slide">
      <div v-show="open" class="side-cart">
        <p class="title">
          <span>{{ __('cart', 'Your item added to bag!') }}</span>
          <a aria-label="close" href="#" @click.prevent="emit('close')">
            <SvgIcon name="exit" />
          </a>
        </p>
        <CartTotals hide-delivery-cost />
        <CartItems />
        <div class="buttons">
          <router-link class="btn btn-primary" :to="productsUrl">
            <span>{{ __('cart', 'More products') }}</span>
          </router-link>
          <router-link
            class="btn btn-secondary"
            :to="`/${langStore.getLang()}/cart`"
          >
            <span>{{ __('cart', 'To my bag') }}</span>
          </router-link>
          <button
            v-if="isTest"
            class="btn btn-secondary tapbox-button"
            :class="{ loading: tapboxLoader }"
            @click.prevent="sentTestTapbox"
          >
            <span>{{ __('cart', 'Send to tapbox') }}</span>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import CartTotals from '@/Partials/CartTotals.vue'
import CartItems from '@/Partials/CartItems.vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import { computed, ref } from 'vue'
import { __ } from '@/Helpers/i18n'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { useCartStore } from '@/Store/useCartStore'
import { postTestTapbox } from '@/Api/Cart'
import useLangStore from '@/Store/useLangStore'

defineProps<{ open: boolean }>()

const emit = defineEmits<{ close: [] }>()

const globalStore = useGlobalStore()
const cartStore = useCartStore()
const langStore = useLangStore()

const isTest = import.meta.env.VITE_ADYEN_ENV === 'test'

const tapboxLoader = ref(false)

const productsUrl = computed(() => globalStore.productsUrl as string)
const cart = computed(() => cartStore.cart!)

const sentTestTapbox = async () => {
  tapboxLoader.value = true
  await postTestTapbox({
    id: cart.value.id,
  })
  tapboxLoader.value = false
}
</script>

<style lang="scss">
.side-cart-overlay {
  background: rgba($black, 0.1);
  position: fixed;
  inset: 0;
  z-index: 12;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s;
}

.side-cart {
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: $white;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  @include border-radius(md);
  position: fixed;
  z-index: 9999999999;
  @media (min-width: 480px) {
    width: 360px;
    right: 8px;
    top: 8px;
    bottom: 8px;
    height: calc(100vh - 16px);
  }

  .title {
    border-bottom: 1px solid $light-gray;
    padding: 3.2rem 3.2rem 1.2rem 3.2rem;
    text-transform: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background: $white;

    svg {
      width: 12px;
      height: 12px;
      color: $black;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    background: $white;
    border-top: 1px solid $light-gray;
    padding: 1.6rem 3.2rem;
    flex-wrap: wrap;
  }

  .tapbox-button {
    margin-top: 1rem;
  }
}
</style>
