import useLangStore from '@/Store/useLangStore'
import axios from 'axios'

export const getOnboarding = () => {
  const lang = useLangStore().getLang()
  return axios.get(`/${lang}/public-user-onboardings`)
}

export const patchOnboardingStep = async (data: any, id: number) => {
  const lang = useLangStore().getLang()
  return axios.patch(`/${lang}/public-user-onboardings/${id}`, data)
}

export const skipOnboardingStep = async (data: any, id: number) => {
  const lang = useLangStore().getLang()
  return axios.patch(`/${lang}/public-user-onboardings/${id}?skip`, data)
}
