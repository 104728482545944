<template>
  <div
    v-if="isVisible"
    v-click-away="closePopup"
    class="popup-overlay"
    role="button"
    tabindex="0"
    @click="closePopup"
    @keydown.esc="closePopup"
  >
    <div class="popup-content" @click.stop>
      <div
        class="close-btn"
        role="button"
        tabindex="0"
        @click="closePopup"
        @keydown.enter.prevent="closePopup"
      >
        <SvgIcon name="exit" />
      </div>

      <div class="offer-tag-wrapper">
        <div class="offer-tag">
          {{ bannerTexts.firstOrderPopUp.OfferTagText }}
        </div>
      </div>
      <div class="image-container">
        <OptimizedImg
          alt="Offer Image"
          class="offer-image"
          :size="{ width: 442, height: 302 }"
          :src="bannerTexts.firstOrderPopUp.bannerImageSrc"
        />
      </div>

      <div class="offer-details">
        <h2>
          {{ bannerTexts.firstOrderPopUp.headerText1 }}
          <span class="highlight">{{
            bannerTexts.firstOrderPopUp.headerTextPercentage
          }}</span>
          {{ bannerTexts.firstOrderPopUp.headerText2 }}
        </h2>
        <p>
          {{ bannerTexts.firstOrderPopUp.descriptionText }}
        </p>
        <button
          class="btn btn-primary"
          type="button"
          @click="handleButtonClick"
        >
          {{ bannerTexts.firstOrderPopUp.buttonLabel }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onUnmounted } from 'vue'
import Cookies from 'js-cookie'
import OptimizedImg from './ui/OptimizedImg.vue'
import SvgIcon from './SvgIcon.vue'
import { __ } from '@/Helpers/i18n'
import { useRoute, useRouter } from 'vue-router'
import { SCROLL_LOCK_CSS_CLASS, toggleBodyCssClass } from '@/Helpers/Global'
import { useLoginStore } from '@/Store/useLoginStore'
import { useGlobalPopupStore } from '@/Store/useGlobalPopupStore'
import useLangStore from '@/Store/useLangStore'

const loginStore = useLoginStore()
const router = useRouter()
const isVisible = ref(false)
const popupStore = useGlobalPopupStore()
let popupTimeout: ReturnType<typeof setTimeout> | null = null
const route = useRoute()
const langStore = useLangStore()

const getRestrictedRoutes = () => {
  const lang = langStore.getLang()
  return [
    `/${lang}/onboarding`,
    `/${lang}/create-account`,
    `/${lang}/account-registration`,
    `/${lang}/login`,
    `/${lang}/confirm`,
    `/${lang}/payment-response`,
  ]
}

const PopupCookieName = {
  AFTER_LOGIN: 'firstOrderPopupClosedAfter',
  BEFORE_LOGIN: 'firstOrderPopupClosed',
} as const

const closePopup = () => {
  isVisible.value = false
  toggleBodyCssClass(SCROLL_LOCK_CSS_CLASS, false)

  const expirationTime = loginStore.userLoggedIn ? 0.25 : 1
  const cookieName = loginStore.userLoggedIn
    ? PopupCookieName.AFTER_LOGIN
    : PopupCookieName.BEFORE_LOGIN
  Cookies.set(cookieName, 'true', { expires: expirationTime })
}

const handleButtonClick = () => {
  closePopup()
  router.push({
    path: bannerTexts.value.firstOrderPopUp.buttonURL,
  })
}

const checkAndShowPopup = () => {
  if (!popupStore.locationPopupCountry) return

  if (
    (Cookies.get(PopupCookieName.BEFORE_LOGIN) && !loginStore.userLoggedIn) ||
    (Cookies.get(PopupCookieName.AFTER_LOGIN) && loginStore.userLoggedIn)
  ) {
    return
  }

  if (getRestrictedRoutes().includes(route.path)) {
    return
  }

  if (
    !loginStore.userLoggedIn ||
    loginStore.profile?.user_discounts?.FIRST_ORDER !== null
  ) {
    popupTimeout = setTimeout(() => {
      isVisible.value = true
      toggleBodyCssClass(SCROLL_LOCK_CSS_CLASS, true)
    }, 10000)
  }
}

watch(
  () => loginStore.userLoggedIn,
  (isLoggedIn) => {
    if (
      isLoggedIn &&
      loginStore.profile?.user_discounts?.FIRST_ORDER !== null
    ) {
      checkAndShowPopup()
    }
  },
)

watch(
  () => route.path,
  async (newPath) => {
    if (
      getRestrictedRoutes().some((restrictedRoute) =>
        newPath.startsWith(restrictedRoute),
      )
    ) {
      isVisible.value = false
      if (popupTimeout) {
        clearTimeout(popupTimeout)
        popupTimeout = null
      }
    } else {
      checkAndShowPopup()
    }
  },
)

watch(() => popupStore.locationPopupCountry, checkAndShowPopup)

onUnmounted(() => {
  if (popupTimeout) {
    clearTimeout(popupTimeout)
  }
})

const bannerTexts = computed(() => ({
  firstOrderPopUp: {
    headerText1: __(
      'global',
      loginStore.userLoggedIn
        ? 'first-order.discount.popup.after-login.header-1'
        : 'first-order.discount.popup.header-1',
    ),
    headerText2: __(
      'global',
      loginStore.userLoggedIn
        ? 'first-order.discount.popup.after-login.header-2'
        : 'first-order.discount.popup.header-2',
    ),
    headerTextPercentage: __(
      'global',
      loginStore.userLoggedIn
        ? 'first-order.discount.popup.after-login.percentage'
        : 'first-order.discount.popup.percentage',
    ),
    descriptionText: __(
      'global',
      loginStore.userLoggedIn
        ? 'first-order.discount.popup.after-login.description'
        : 'first-order.discount.popup.description',
    ),
    OfferTagText: __(
      'global',
      loginStore.userLoggedIn
        ? 'first-order.discount.popup.after-login.tag'
        : 'first-order.discount.popup.tag',
    ),
    buttonURL: __(
      'global',
      loginStore.userLoggedIn
        ? 'first-order.discount.popup.after-login.button.href'
        : 'first-order.discount.popup.button.href',
    ),
    buttonLabel: __(
      'global',
      loginStore.userLoggedIn
        ? 'first-order.discount.popup.after-login.button.label'
        : 'first-order.discount.popup.button.label',
    ),
    bannerImageSrc: __(
      'global',
      loginStore.userLoggedIn
        ? 'first-order.discount.popup.after-login.image.url'
        : 'first-order.discount.popup.image.url',
    ),
    bannerImageAlt: __(
      'global',
      loginStore.userLoggedIn
        ? 'first-order.discount.popup.after-login.image.alt'
        : 'first-order.discount.popup.image.alt',
    ),
    mobileBannerImageSrc: __(
      'global',
      loginStore.userLoggedIn
        ? 'first-order.discount.popup.after-login.mobile-image.url'
        : 'first-order.discount.popup.mobile-image.url',
    ),
    mobileBannerImageAlt: __(
      'global',
      loginStore.userLoggedIn
        ? 'first-order.discount.popup.after-login.mobile-image.alt'
        : 'first-order.discount.popup.mobile-image.alt',
    ),
  },
}))
</script>

<style scoped lang="scss">
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  border-radius: 10px;
  position: relative;
  margin: 0 16px;
  max-width: 442px;
  -webkit-tap-highlight-color: transparent;
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 28px;
  background: none;
  border: none;
  cursor: pointer;
}

.offer-tag {
  background: rgba(224, 236, 254, 1);
  color: black;
  font-size: 12px;
  font-weight: 600;
  line-height: 160%;

  padding: 5px 10px;
  border-radius: 12px;
  display: inline-block;
}

.offer-tag-wrapper {
  text-align: left;
  padding: 16px 24px 16px 24px;
}

.image-container {
  position: relative;
}

.offer-image {
  width: 100%;
}

.badge {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #6d5eff;
  color: white;
  padding: 8px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

.offer-details {
  padding: 14px 26px;
}

h2 {
  font-size: 32px;
  line-height: 120%;
  font-weight: 500;
  letter-spacing: -0.4px;
  margin-bottom: 12px;
}

.highlight {
  background: rgba(255, 200, 220, 1);
  padding: 3px 8px;
  border-radius: 11px;
  font-size: 22px;
  line-height: 100%;
  letter-spacing: -0.4px;
  font-weight: 600;
  vertical-align: middle;
  top: -1px;
  position: relative;
  white-space: nowrap;
}

p {
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: -0.1px;
  color: rgba(12, 13, 18, 1);
  margin-bottom: 21px;
}

button {
  margin-bottom: 19px;
}

svg {
  width: 12px;
  height: 12px;
}

.cta-btn {
  background: black;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}
</style>
