<template>
  <div class="waiting-list-overlay">
    <div class="waiting-list-form-wrapper">
      <div class="waiting-list-form">
        <a
          aria-label="Close"
          class="close-button"
          href="#"
          @click.prevent="emit('close')"
        >
          <SvgIcon name="exit-new" />
        </a>
        <div class="description-wrapper">
          <p class="description">{{ texts.description }}</p>
        </div>
        <form v-if="!success" action="" @submit.prevent="addToWaitingList">
          <div class="form-wrap">
            <div class="input-element">
              <BaseInput
                v-model.trim="email"
                :error-message="
                  getFieldError(errors, 'email') ||
                  getFieldError(errors, 'recaptcha')
                "
                id-name="email"
                label="Email"
                type="email"
              />
            </div>
            <button
              class="btn btn-primary custom-button"
              :class="{ loading: loading }"
              type="submit"
            >
              {{ __('global', 'Submit') }}
            </button>
          </div>
        </form>
        <div v-else class="button-wrapper">
          <button
            class="btn btn-primary custom-button got-it"
            @click.prevent="emit('close')"
          >
            {{ __('global', 'Got it') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SvgIcon from '../SvgIcon.vue'
import { __ } from '@/Helpers/i18n'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { postWaitingListSubmission } from '@/Api/Products'
import { ProductUnavailabilityStatusType } from '@/Types/Product'
import { ProductForWaitingList } from '@/Composables/useProductActions'
import { SCROLL_LOCK_CSS_CLASS, toggleBodyCssClass } from '@/Helpers/Global'
import { getFieldError } from '@/Helpers/Errors'
import BaseInput from '@/Components/BaseInput.vue'

const props = defineProps<{ product: ProductForWaitingList }>()

const emit = defineEmits<{ close: [] }>()

onMounted(() => {
  toggleBodyCssClass(SCROLL_LOCK_CSS_CLASS, true)
})

onUnmounted(() => {
  toggleBodyCssClass(SCROLL_LOCK_CSS_CLASS, false)
})

//@ts-ignore
const { executeRecaptcha } = useReCaptcha()

const texts = computed(() =>
  props.product.unavailability_status ===
  ProductUnavailabilityStatusType.OUT_OF_STOCK
    ? success.value
      ? {
          title: __(
            'products',
            'global.waiting-list-form.out-of-stock.success-title',
          ),
          description: __(
            'products',
            'global.waiting-list-form.out-of-stock.success-description',
          ),
        }
      : {
          title: __('products', 'global.waiting-list-form.out-of-stock.title'),
          description: __(
            'products',
            'global.waiting-list-form.out-of-stock.description',
          ),
        }
    : success.value
      ? {
          title: __(
            'products',
            'global.waiting-list-form.coming-soon.success-title',
          ),
          description: __(
            'products',
            'global.waiting-list-form.coming-soon.success-description',
          ),
        }
      : {
          title: __('products', 'global.waiting-list-form.coming-soon.title'),
          description: __(
            'products',
            'global.waiting-list-form.coming-soon.description',
          ),
        },
)

const email = ref('')
const errors = ref()
const success = ref(false)
const loading = ref(false)
const addToWaitingList = async () => {
  errors.value = null
  success.value = false
  loading.value = true
  const token = await executeRecaptcha('submitWaitingListForm')
  try {
    await postWaitingListSubmission(props.product.id, email.value, token)
    success.value = true
  } catch (e: any) {
    errors.value = e.response.data
  } finally {
    loading.value = false
  }
}
</script>

<style scoped lang="scss">
.waiting-list-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}
.waiting-list-form-wrapper {
  @media (max-width: 991px) {
    width: 100%;
    padding: 0px 12px;
    -webkit-tap-highlight-color: transparent;
  }
}

.waiting-list-form {
  background: $white;
  z-index: 3;
  padding: 24px 20px;
  height: fit-content;
  width: 100%;
  border-radius: 16px;
  @include desktop-only {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 520px;
    height: fit-content;
    display: block;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.05);
    width: 100%;
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .btn-primary:focus {
    background-color: #0c0d12 !important;
  }

  .custom-button {
    margin-bottom: 48px;
    @media (min-width: 992px) {
      margin-bottom: 32px;
      justify-content: center;
    }
  }

  svg {
    margin-right: 4px;
    width: 24px;
    height: 24px;
    color: $black;
  }

  .description {
    margin-bottom: 32px;
    margin-top: 6px;
    width: 358px;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: -0.3px;
    text-align: center;
    @media (max-width: 991px) {
      margin: 22px 8px 32px 8px;
    }
  }

  .description-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .form-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include desktop-only {
    }

    .btn {
      width: 129px;
    }
  }

  .input-element {
    width: 100%;
    margin-bottom: 40px;
    @include desktop-only {
      width: calc(100% - 145px);
    }
  }
  .btn.got-it {
    width: auto;
    @include desktop-only {
      background: $black;
      color: $white;
    }
  }

  .close-button {
    justify-content: end;
    display: flex;
    top: 3rem;
    right: 3rem;
    @media (max-width: 991px) {
      position: unset;
      justify-content: end;
      display: flex;
      margin-right: 4px;
    }
  }
}
</style>
