import { useInfiniteQuery } from 'vue-query'
import {
  getAttributeFilterIds,
  getProducts,
  GetProductsParams,
} from '@/Api/Products'
import { computed, onMounted, ref, unref, watch, watchEffect } from 'vue'
import { FEATURE_FLAGS, getCurrentSessionValue } from '@/Helpers/Global'
import { useRoute, useRouter } from 'vue-router'
import { useProductsStore } from '@/Store/useProductsStore'
import { MaybeRef } from '@vueuse/core'
import {
  ExtendedProductType,
  FILTER_TAGS_TYPES,
  ProductType,
} from '@/Types/Product'
import {
  PRO_PRODUCTS_SLUG,
  SAMPLE_SETS_SLUGS,
  COLLECTION_BOXES_SLUG,
} from '@/Helpers/Constants'
import { debounce } from 'lodash'
import useSorting from './useSorting'

const useProductCatalogue = (options?: {
  baseUrl?: MaybeRef<string>
  staticParams?: GetProductsParams
}) => {
  const router = useRouter()
  const route = useRoute()
  const productsStore = useProductsStore()

  const productsLoaded = ref(false)
  const filterIds = ref<number[]>([])
  const filterCategoryIds = ref<number[]>([])
  const showTax = computed(() => !!getCurrentSessionValue('SHOW_TAX'))
  const { sort, setSort } = useSorting()
  const attributeGroupIds = computed(() =>
    getAttributeFilterIds('product-catalog.filter.tag-product-ids'),
  )

  const getProductTypeAndCategorySlug = (pathSplit: string[]) => {
    const pathAfterBaseUrl = pathSplit[1].substring(1) ?? null
    const pathParts = pathAfterBaseUrl.split('/')
    const categoryOrType = pathParts[0]

    let productType = ExtendedProductType.REGULAR
    let categorySlug = productsStore.productCategories?.find(
      (item) => item.slug === categoryOrType,
    )

    if (SAMPLE_SETS_SLUGS.includes(categoryOrType)) {
      productType = ExtendedProductType.SAMPLE_SET
      categorySlug = productsStore.productCategories?.find((item) =>
        SAMPLE_SETS_SLUGS.some((slug) => item.slug === slug),
      )
    }

    if (categoryOrType === COLLECTION_BOXES_SLUG) {
      productType = ExtendedProductType.COLLECTION_BOX
      categorySlug = productsStore.productCategories?.find(
        (item) => item.slug === COLLECTION_BOXES_SLUG,
      )
    }

    if (categoryOrType === PRO_PRODUCTS_SLUG) {
      productType = ExtendedProductType.PRO
      if (pathParts[1]) {
        categorySlug = productsStore.productCategories?.find(
          (item) => item.slug === pathParts[1],
        )
      }
    }

    return { productType, categorySlug }
  }

  const activeCategory = computed(() => {
    if (!options?.baseUrl) return
    return getProductTypeAndCategorySlug(
      route.path.split(unref(options.baseUrl)),
    ).categorySlug
  })

  const activeCategoryId = computed(() => activeCategory.value?.id)

  onMounted(() => {
    if (activeCategoryId.value) {
      filterCategoryIds.value = [activeCategoryId.value]
      addParamsToLocation({ categoryIds: activeCategoryId.value.toString() })
    }
  })

  watch(activeCategoryId, (newCategoryId) => {
    if (newCategoryId) {
      filterCategoryIds.value = [newCategoryId]
      addParamsToLocation({ categoryIds: newCategoryId.toString() })
    }
  })

  const productType = computed(() => {
    if (!options?.baseUrl) return ProductType.REGULAR

    return getProductTypeAndCategorySlug(
      route.path.split(unref(options.baseUrl)),
    ).productType
  })

  const requestParams = computed(() => {
    if (FEATURE_FLAGS.SHOW_NEW_PRODUCTS_CATALOG) {
      const selectedIds = filterIds.value
      const proId = attributeGroupIds.value.find(
        (item) => item.type === FILTER_TAGS_TYPES.PRO,
      )?.id
      const discountId = attributeGroupIds.value.find(
        (item) => item.type === FILTER_TAGS_TYPES.DISCOUNT,
      )?.id
      const comingSoonId = attributeGroupIds.value.find(
        (item) => item.type === FILTER_TAGS_TYPES.COMING_SOON,
      )?.id
      const newId = attributeGroupIds.value.find(
        (item) => item.type === FILTER_TAGS_TYPES.NEW,
      )?.id
      const bestsellerId = attributeGroupIds.value.find(
        (item) => item.type === FILTER_TAGS_TYPES.BESTSELLER,
      )?.id

      return {
        ...(options?.staticParams ?? {}),
        product_attribute_ids: selectedIds.filter(
          (id) => ![proId, discountId, comingSoonId].includes(id),
        ),
        categoryIds: filterCategoryIds.value,
        types: route.query.type
          ? [route.query.type as ProductType]
          : [
              ProductType.REGULAR,
              ProductType.COLLECTION_BOX,
              ProductType.SAMPLE_SET,
            ],
        is_pro: proId && selectedIds.includes(proId) ? true : undefined,
        is_on_sale:
          discountId && selectedIds.includes(discountId) ? true : undefined,
        coming_soon:
          comingSoonId && selectedIds.includes(comingSoonId)
            ? 'true'
            : undefined,
        is_new: newId && selectedIds.includes(newId) ? true : undefined,
        is_bestseller:
          bestsellerId && selectedIds.includes(bestsellerId) ? true : undefined,
        sort: sort.value,
      }
    } else {
      return {
        ...(options?.staticParams ?? {}),
        product_attribute_ids: filterIds.value,
        category: activeCategory.value?.id ?? undefined,
        types:
          productType.value === ExtendedProductType.PRO
            ? []
            : [productType.value as ProductType],
        is_pro: productType.value === ExtendedProductType.PRO,
      }
    }
  })

  const {
    data: products,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['products', showTax, requestParams],
    async ({ pageParam = 1 }) => {
      const data = await getProducts({
        data: { ...requestParams.value, page: pageParam },
        expandArray: [
          'gallery_photos',
          'inspiration_gallery_photos',
          'blacklisted_countries',
          'sample_set_products',
          'collection_box_products',
          'product_attributes',
          'variant_products_count',
        ],
      })
      productsLoaded.value = true
      return data
    },
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      getNextPageParam: (lastPage) =>
        lastPage.page < lastPage.page_count ? lastPage.page + 1 : undefined,
    },
  )

  const addParamsToLocation = (params: Record<string, string | undefined>) => {
    router.replace({ query: { ...params } })
  }

  const reset = () => {
    filterIds.value = []
    filterCategoryIds.value = []
    addParamsToLocation({})
  }

  watchEffect(() => {
    const idsFromRoute = route.query.ids
      ? route.query.ids.toString().split(',').map(Number)
      : []
    const categoryIdsFromRoute = route.query.categoryIds
      ? route.query.categoryIds.toString().split(',').map(Number)
      : []

    filterIds.value = idsFromRoute
    filterCategoryIds.value = categoryIdsFromRoute
  })

  const debouncedFilter = debounce(() => {
    const ids = filterIds.value
    const categoryIds = filterCategoryIds.value

    if (ids.length === 0 && categoryIds.length === 0) {
      router.replace({ path: unref(options?.baseUrl ?? route.path), query: {} })
    } else {
      addParamsToLocation({
        ...(ids.length > 0 ? { ids: ids.join(',') } : {}),
        ...(categoryIds.length > 0
          ? { categoryIds: categoryIds.join(',') }
          : {}),
      })
    }
  }, 100)

  const filter = (ids: number[], categoryIds: number[] = []) => {
    filterIds.value = ids
    filterCategoryIds.value = categoryIds
    debouncedFilter()
  }

  return {
    productType,
    products,
    hasNextPage,
    productsLoaded,
    activeCategory,
    fetchNextPage,
    filterIds,
    filterCategoryIds,
    filter,
    reset,
    sort,
    setSort,
  }
}

export default useProductCatalogue
