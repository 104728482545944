<template>
  <div v-if="cartStore.cart?.cart_items" class="cart-items">
    <p class="cart-items-title">{{ __('cart', 'Your cart') }}</p>
    <div class="wrapper">
      <div
        v-for="item in cartStore.cart.cart_items"
        :key="item.id"
        class="item"
      >
        <div class="image">
          <OptimizedImg
            :alt="item.product.title"
            :size="{
              mobile: { height: 132, width: 100 },
              desktop: { height: 248, width: 187 },
            }"
            :src="item.productImage"
          />
        </div>
        <div class="text">
          <p class="volume">{{ item.product.cartInfoText }}</p>
          <p>{{ item.product.title }}</p>
          <p v-if="item.total !== null" class="price">
            {{ floatToCurrency(item.total) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { __ } from '@/Helpers/i18n'
import { useCartStore } from '@/Store/useCartStore'
import { floatToCurrency } from '@/Helpers/Global'
import OptimizedImg from '@/Partials/ui/OptimizedImg.vue'

const cartStore = useCartStore()
</script>

<style lang="scss">
.cart-items {
  padding: 3.2rem;
  border-top: 1px solid $light-gray;

  .cart-items-title {
    margin-bottom: 1.6rem;
  }

  .wrapper {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $light-gray;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $dark-gray;
    }

    .item {
      display: flex;
      align-items: stretch;
      padding-bottom: 1.2rem;

      &:not(:last-child) {
        border-bottom: 1px solid $light-gray;
        margin-bottom: 2rem;
      }
    }

    .image {
      flex-shrink: 0;
    }

    img {
      @include border-radius(sm);
      width: 70px;
      height: 96px;
      object-fit: cover;
      object-position: center;
    }

    .text {
      display: flex;
      flex-direction: column;
      height: auto;
      padding-left: 0.8rem;
    }

    .volume {
      font-size: 1.2rem;
      color: $gray-10;
    }

    .price {
      margin-top: auto;
      font-size: 1.8rem;
    }
  }
}
</style>
