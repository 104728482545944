import { defineStore, storeToRefs } from 'pinia'
import { computed, markRaw, ref } from 'vue'
import { useRouter } from 'vue-router'
import {
  BrandStep,
  CategoryStep,
  FieldError,
  MonthlyOrderCountStep,
  NameStep,
  OnboardingData,
  OnboardingStepIndexes,
  OnboardingSteps,
  PrimaryMotivationStep,
  ProductsInterestedStep,
  ReferralStep,
} from '@/Pages/OnBoarding/Types/onboarding'
import {
  getOnboarding,
  patchOnboardingStep,
  skipOnboardingStep,
} from '@/Api/PublicUserOnboarding'
import useLoading from '@/Composables/useLoading'
import { getProfile, patchProfile } from '@/Api/Profile'
import { ErrorData, setErrors } from '@/Helpers/Errors'
import { __ } from '@/Helpers/i18n'
import Name from '@/Pages/OnBoarding/Partials/Name.vue'
import Brand from '@/Pages/OnBoarding/Partials/Brand.vue'
import Category from '@/Pages/OnBoarding/Partials/Category/Category.vue'
import MonthlyOrders from '@/Pages/OnBoarding/Partials/MonthlyOrders.vue'
import Motivation from '@/Pages/OnBoarding/Partials/Motivation.vue'
import ProductsInterested from '@/Pages/OnBoarding/Partials/ProductsInterested.vue'
import Referral from '@/Pages/OnBoarding/Partials/Referral.vue'
import Newsletter from '@/Pages/OnBoarding/Partials/Newsletter.vue'
import OnboardingSuccess from '@/Pages/OnBoarding/Partials/Success/OnboardingSuccess.vue'
import { useLoginStore } from '@/Store/useLoginStore'
import useLangStore from '@/Store/useLangStore'

export const useOnboardingStore = defineStore('onboarding', () => {
  const currentStepIndex = ref(0)
  const errors = ref<FieldError[] | null>(null)
  const isSubmitting = ref(false)
  const isSkipping = ref(false)
  const isNavigation = ref(false)
  const onboardingInfo = ref<OnboardingData | null>(null)

  const router = useRouter()
  const loginStore = useLoginStore()
  const { setLoading } = useLoading()

  const stepOrder = [
    { key: 'first_name', index: OnboardingStepIndexes.NAME },
    { key: 'last_name', index: OnboardingStepIndexes.NAME },
    { key: 'brand_name', index: OnboardingStepIndexes.BRAND },
    { key: 'public_url', index: OnboardingStepIndexes.BRAND },
    { key: 'category', index: OnboardingStepIndexes.CATEGORY },
    {
      key: 'monthly_order_count',
      index: OnboardingStepIndexes.MONTHLY_ORDER_COUNT,
    },
    {
      key: 'primary_motivation',
      index: OnboardingStepIndexes.PRIMARY_MOTIVATION,
    },
    {
      key: 'products_interested',
      index: OnboardingStepIndexes.PRODUCTS_INTERESTED,
    },
    { key: 'referral', index: OnboardingStepIndexes.REFERRAL },
  ]

  const {
    NAME,
    BRAND,
    CATEGORY,
    MONTHLY_ORDER_COUNT,
    PRIMARY_MOTIVATION,
    PRODUCTS_INTERESTED,
    REFERRAL,
    NEWSLETTER,
  } = OnboardingSteps

  const onboardingSteps = [
    {
      step: NAME,
      component: markRaw(Name),
      imageAlt: __('onboarding', 'name.image-section.alt'),
      imageSrc: __('onboarding', 'name.image-section.url'),
      currentProgress: 1,
    },
    {
      step: BRAND,
      component: markRaw(Brand),
      imageAlt: __('onboarding', 'brand.image-section.alt'),
      imageSrc: __('onboarding', 'brand.image-section.url'),
      currentProgress: 2,
    },
    {
      step: CATEGORY,
      component: markRaw(Category),
      imageAlt: __('onboarding', 'category.image-section.alt'),
      imageSrc: __('onboarding', 'category.image-section.url'),
      currentProgress: 3,
    },
    {
      step: MONTHLY_ORDER_COUNT,
      component: markRaw(MonthlyOrders),
      imageAlt: __('onboarding', 'monthly-orders.image-section.alt'),
      imageSrc: __('onboarding', 'monthly-orders.image-section.url'),
      currentProgress: 3,
    },
    {
      step: PRIMARY_MOTIVATION,
      component: markRaw(Motivation),
      imageAlt: __('onboarding', 'motivation.image-section.alt'),
      imageSrc: __('onboarding', 'motivation.image-section.url'),
      currentProgress: 3,
    },
    {
      step: PRODUCTS_INTERESTED,
      component: markRaw(ProductsInterested),
      imageAlt: __('onboarding', 'products-interested.image-section.alt'),
      imageSrc: __('onboarding', 'products-interested.image-section.url'),
      currentProgress: 3,
    },
    {
      step: REFERRAL,
      component: markRaw(Referral),
      imageAlt: __('onboarding', 'referrals.image-section.alt'),
      imageSrc: __('onboarding', 'referrals.image-section.url'),
      currentProgress: 4,
    },
    {
      step: NEWSLETTER,
      component: markRaw(Newsletter),
      imageAlt: __('onboarding', 'newsletter.image-section.alt'),
      imageSrc: __('onboarding', 'newsletter.image-section.url'),
      currentProgress: 4,
    },
    {
      component: markRaw(OnboardingSuccess),
      imageAlt: __('onboarding', 'success.image-section.alt'),
      imageSrc: __('onboarding', 'success.image-section.url'),
      currentProgress: 4,
    },
  ]

  const maxProgress = Math.max(
    ...onboardingSteps.map(({ currentProgress }) => currentProgress),
  )

  const currentStep = computed(() => onboardingSteps[currentStepIndex.value])

  const completedSteps = computed(
    () => onboardingSteps[completedStepsIndex.value],
  )

  const completedStepsIndex = computed(() =>
    onboardingInfo.value?.last_step
      ? onboardingSteps.findIndex(
          (step) => step.step === onboardingInfo.value?.last_step,
        )
      : OnboardingStepIndexes.NAME,
  )

  const setIsNavigation = (value: boolean) => {
    isNavigation.value = value
  }

  const setStepIndex = (index: number) => {
    currentStepIndex.value = index
  }

  const updateProfileNewsletter = async (receiveEmails: boolean) => {
    isSubmitting.value = true
    errors.value = null

    const data: any = {}
    data.terms_accepted = true
    data.receive_emails = receiveEmails
    await patchProfile(data).catch((error: ErrorData) => {
      errors.value = setErrors(error)
    })

    isSubmitting.value = false
    if (errors.value) return

    onboardingInfo.value = {
      ...onboardingInfo.value!,
      receive_emails: receiveEmails,
    }

    currentStepIndex.value++
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const updateProfileName = async (data: NameStep) => {
    isSubmitting.value = true
    errors.value = null
    await patchProfile({
      first_name: data.first_name,
      last_name: data.last_name,
    }).catch((error: ErrorData) => {
      errors.value = setErrors(error)
    })

    isSubmitting.value = false
    if (errors.value) return

    onboardingInfo.value = {
      ...onboardingInfo.value!,
      first_name: data.first_name,
      last_name: data.last_name,
    }

    const profileResponse = await getProfile()

    if (
      profileResponse.data.is_onboarding_completed &&
      onboardingInfo.value!.receive_emails === false
    ) {
      currentStepIndex.value = OnboardingStepIndexes.NEWSLETTER
      return
    }

    if (profileResponse.data.is_onboarding_completed) {
      currentStepIndex.value = OnboardingStepIndexes.SUCCESS
      return
    }

    currentStepIndex.value++
  }

  const submitForm = async (
    data:
      | BrandStep
      | CategoryStep
      | MonthlyOrderCountStep
      | PrimaryMotivationStep
      | ProductsInterestedStep
      | ReferralStep,
  ) => {
    isSubmitting.value = true
    errors.value = null

    const response = await patchOnboardingStep(
      data,
      onboardingInfo.value!.id,
    ).catch((error: ErrorData) => {
      errors.value = setErrors(error)
    })

    const onboardingData = response?.data

    isSubmitting.value = false
    if (errors.value) return

    onboardingInfo.value = {
      ...onboardingInfo.value,
      ...onboardingData,
    }
    const profileResponse = await getProfile()

    scrollToTop()
    if (
      profileResponse.data.is_onboarding_completed &&
      onboardingInfo.value!.receive_emails === false
    ) {
      currentStepIndex.value = OnboardingStepIndexes.NEWSLETTER
      return
    }

    if (profileResponse.data.is_onboarding_completed) {
      currentStepIndex.value = OnboardingStepIndexes.SUCCESS
      return
    }

    if (
      data.current_step === OnboardingSteps.REFERRAL &&
      onboardingInfo.value!.receive_emails === true
    ) {
      currentStepIndex.value += 2 // Skip the NEWSLETTER step
      return
    }
    currentStepIndex.value++
  }

  const goPreviousStep = () => {
    currentStepIndex.value--
    scrollToTop()
  }

  const skipStep = async (currentStep: OnboardingSteps) => {
    isSkipping.value = true
    errors.value = null
    const data = { current_step: currentStep }

    const response = await skipOnboardingStep(
      data,
      onboardingInfo.value!.id,
    ).catch((error: ErrorData) => {
      errors.value = setErrors(error)
    })

    const onboardingData = response?.data
    isSkipping.value = false
    if (errors.value) return

    onboardingInfo.value = {
      ...onboardingInfo.value,
      ...onboardingData,
    }

    if (
      currentStep === OnboardingSteps.REFERRAL &&
      onboardingInfo.value!.receive_emails === true
    ) {
      currentStepIndex.value++ // Skip the NEWSLETTER step
    }
    currentStepIndex.value++
    isSkipping.value = false
    scrollToTop()
  }

  const updateProgressBarOnPageReload = () => {
    const { first_name, last_name, last_step } = onboardingInfo.value!
    if (!last_step && !first_name && !last_name) {
      currentStepIndex.value = OnboardingStepIndexes.NAME
      return
    }
    currentStepIndex.value = completedStepsIndex.value
  }

  const updateProgressBarOnNavigation = () => {
    for (const step of stepOrder) {
      if (onboardingInfo.value![step.key as keyof OnboardingData] === null) {
        currentStepIndex.value = step.index
        return
      }
    }
  }

  const loadOnboardingData = async () => {
    setLoading(true)
    const onboardingData: OnboardingData = (await getOnboarding()).data
    onboardingInfo.value = {
      ...onboardingData,
      first_name: loginStore.profile!.first_name,
      last_name: loginStore.profile!.last_name,
      receive_emails: loginStore.profile!.receive_emails,
    }
    if (loginStore.profile?.is_onboarding_completed) {
      router.push(`/${useLangStore().getLang()}/profile/dashboard`)
      return
    }
    setLoading(false)
  }

  return {
    currentStepIndex,
    errors,
    isSubmitting,
    isSkipping,
    isNavigation,
    onboardingInfo,
    maxProgress,
    currentStep,
    completedSteps,
    setIsNavigation,
    setStepIndex,
    loadOnboardingData,
    goPreviousStep,
    skipStep,
    submitForm,
    updateProfileName,
    updateProfileNewsletter,
    updateProgressBarOnPageReload,
    updateProgressBarOnNavigation,
  }
})

export const useOnboardingStoreRefs = () => {
  const onboardingStore = useOnboardingStore()
  return storeToRefs(onboardingStore)
}
