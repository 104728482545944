<template>
  <FormFlow
    v-if="!emailProvided"
    :image-section-alt="__('auth', 'provide-email.image-section.alt')"
    :image-section-src="__('auth', 'provide-email.image-section.url')"
    :show-previous-step-button="false"
  >
    <template #form>
      <h2 class="form-flow-heading">
        {{ __('auth', 'provide-email.main-heading.text') }}
      </h2>
      <form
        v-if="inputs"
        action=""
        class="provide-email-form"
        @submit.prevent="updateProfile"
      >
        <BaseInput
          v-for="item in inputs"
          :key="item.name"
          v-model="item.value"
          :error-message="getFieldError(errors, item.name)"
          :id-name="item.name"
          :label="item.label"
          :type="item.type"
        />
        <ReceiveEmailsToggle
          :error-message="getFieldError(errors, 'receive_emails')"
        >
          <ToggleSwitch id="receive-emails" v-model="receiveEmails" />
        </ReceiveEmailsToggle>
        <BaseButton class="submit-button" :is-loading="loading" type="submit">
          {{ __('forms', 'update') }}</BaseButton
        >
      </form>
    </template>
    <template #alternative-options>
      <div class="alternative-option">
        <span>{{ __('auth', 'provide-email.user-agrees.text') }}</span>
        <a :href="pageLinks ? pageLinks.value.terms_url : ''" target="_blank">{{
          __('auth', 'provide-email.terms-and-conditions.text')
        }}</a>
        <span>{{ __('global', 'and') }}</span>
        <a
          :href="pageLinks ? pageLinks.value.privacy_policy_url : ''"
          target="_blank"
          >{{ __('auth', 'provide-email.privacy-policy.text') }}</a
        >
      </div>
    </template>
  </FormFlow>

  <Success
    v-else
    :main-heading-text="__('auth', 'provide-email-success.main-heading.text')"
    :secondary-heading-text="
      __('auth', 'provide-email-success.update-button.text')
    "
    :submit-button-text="__('global', 'finish')"
  />
</template>

<script setup lang="ts">
import { __ } from '@/Helpers/i18n'
import BaseInput from '@/Components/BaseInput.vue'
import BaseButton from '@/Components/BaseButton.vue'
import FormFlow from '@/Components/FormFlow.vue'

import { computed, onMounted, ref } from 'vue'
import { getAcceptSocialInputs } from '@/Helpers/Forms'
import { useLoginStore } from '@/Store/useLoginStore'
import { patchProfile } from '@/Api/Profile'
import { getFieldError, setErrors } from '@/Helpers/Errors'
import Success from '@/Components/Success.vue'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { PageLinkSetting } from '@/Types/Setting'
import ToggleSwitch from '@/Components/ToggleSwitch.vue'
import ReceiveEmailsToggle from '@/Components/ReceiveEmailsToggle.vue'
import { withLang } from '@/routes'
import { useRouter } from 'vue-router'

const loginStore = useLoginStore()
const globalStore = useGlobalStore()
const router = useRouter()
const inputs = ref(getAcceptSocialInputs())
const errors = ref()
const receiveEmails = ref(false)
const loading = ref(false)
const emailProvided = ref(false)

const pageLinks = computed(
  () =>
    globalStore.getSettingByKey('pagelinksetting') as
      | PageLinkSetting
      | undefined,
)

const updateProfile = async () => {
  loading.value = true
  errors.value = null
  const data: any = {}

  inputs.value.forEach((item: any) => {
    if (item.value) {
      data[item.name] = item.value
    }
  })

  data.terms_accepted = true
  data.receive_emails = receiveEmails.value
  await patchProfile(data).catch((error: any) => {
    errors.value = setErrors(error)
  })

  loading.value = false

  if (errors.value) return
  emailProvided.value = true
  inputs.value.forEach((item: any) => {
    item.value = null
  })
  router.push(withLang({ name: 'onboarding' }))
}

onMounted(() => {
  inputs.value.forEach((item) => {
    if (!loginStore.profile) return
    if (loginStore.profile[item.name as keyof typeof loginStore.profile]) {
      item.value = loginStore.profile[
        item.name as keyof typeof loginStore.profile
      ] as string
    }
  })
})
</script>

<style lang="scss" scoped>
.form-flow-secondary-heading {
  max-width: 445px;
  text-align: center;
}

.provide-email-form {
  @include centered-flex-column;
  margin-top: 32px;
  width: 100%;
  max-width: 320px;
  @media (min-width: 768px) {
    margin-top: 40px;
  }

  .submit-button {
    margin-top: 48px;
  }
}
</style>
