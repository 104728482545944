import { ref } from 'vue'
import { RouteLocationRaw } from 'vue-router'

const signupRedirectUrl = ref<RouteLocationRaw | null>(null)

export default function useSignupPopupActions() {
  const signUpOpen = ref(false)
  const noRedirectAfterSignup = ref(false)

  const openSignUp = () => (signUpOpen.value = true)
  const closeSignUp = () => {
    signupRedirectUrl.value = null
    noRedirectAfterSignup.value = false
    signUpOpen.value = false
  }

  return {
    signUpOpen,
    openSignUp,
    closeSignUp,
    signupRedirectUrl,
    noRedirectAfterSignup,
  }
}
