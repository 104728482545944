<template>
  <BaseBlock :data="data">
    <div class="wrapper">
      <router-link
        v-for="link in links"
        :key="link.id"
        :to="link.to"
        @click="closeMenu"
      >
        <OptimizedImg
          v-if="link.image"
          alt=""
          :size="{
            mobile: {
              height: 118,
              maxWidth: (991 - 32 - 12) / 2,
              displayWidth: 'calc(50vw - 16px - 6px)',
            },
            desktop: { height: 110, width: 137 },
          }"
          :src="link.image"
        />
        <p class="text">{{ link.name }} <Arrow /></p>
      </router-link>
    </div>
  </BaseBlock>
</template>

<script setup lang="ts">
import { NavigationBlock } from '@/Partials/MainNavigation/navigationConfig'
import { useProductsStore } from '@/Store/useProductsStore'
import { useGlobalStore } from '@/Store/useGlobalStore'
import BaseBlock from '@/Partials/MainNavigation/BaseBlock.vue'
import OptimizedImg from '@/Partials/ui/OptimizedImg.vue'
import { computed } from 'vue'
import { MAKEUP_CATEGORY_SLUGS, SAMPLE_SETS_SLUGS } from '@/Helpers/Constants'
import Arrow from '@/Partials/Arrow.vue'
import useLayoutStore from '@/Store/useLayoutStore'

defineProps<{ data: NavigationBlock<'CATALOGUE'> }>()

const globalStore = useGlobalStore()
const productsStore = useProductsStore()
const layoutStore = useLayoutStore()

const links = computed(() =>
  (productsStore.productCategories ?? [])
    .filter(
      (category) =>
        ![...SAMPLE_SETS_SLUGS, ...MAKEUP_CATEGORY_SLUGS].includes(
          category.slug,
        ),
    )
    .map((category) => ({
      ...category,
      to: `${globalStore.productsUrl}/${category.slug}`,
    })),
)

const closeMenu = () => {
  layoutStore.mobileMenuOpen = false
}
</script>

<style scoped lang="scss">
.wrapper {
  display: grid;
  align-items: end;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem 1.2rem;
  @include desktop-only {
    grid-template-columns: repeat(4, 1fr);
  }
}

img {
  margin-bottom: 1.2rem;
  background: $light-gray;
  object-fit: cover;
  @include border-radius(2xl);
  height: 11.8rem;
  width: calc(50vw - 16px - 6px);
  @include desktop-only {
    height: 11rem;
    width: 100%;
  }
}

.text {
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.02em;
  padding: 0 0.4rem;
  color: $black !important;
  white-space: nowrap;
}
</style>
