import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useGlobalPopupStore = defineStore('globalPopup', () => {
  const locationPopupCountry = ref(
    localStorage.getItem('locationPopupCountry') || null,
  )

  return {
    locationPopupCountry,
  }
})
