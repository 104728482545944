<template>
  <div class="category-filter">
    <div
      v-for="attribute in item.product_attributes"
      :key="attribute.id"
      class="category-item"
      :class="{ active: attribute.active }"
      role="button"
      tabindex="0"
      @click="toggleAttribute(attribute)"
      @focusin="hoveredAttribute = attribute.id"
      @focusout="hoveredAttribute = null"
      @keydown.enter.prevent="toggleAttribute(attribute)"
      @mouseenter="hoveredAttribute = attribute.id"
      @mouseleave="hoveredAttribute = null"
    >
      <SvgIcon
        class="category-icon"
        :class="[
          { active: attribute.active },
          getBackgroundClass(attribute.id),
          getBorderClass(attribute.id),
          getColorClass(attribute.id),
        ]"
        :name="getIconName(attribute)"
      />
      <span v-if="isDesktop" class="category-label">{{ attribute.name }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import { Modify } from '@/Types/Shared'
import { ProductAttributeGroupResource } from '@/Types/AttributeGroup'
import { ProductAttributeResource } from '@/Types/ProductAttribute'
import useScreenBreakpoints from '@/Composables/UseScreenBreakpoints'
import { getAttributeFilterIds } from '@/Api/Products'

const props = defineProps<{
  baseUrl: string
  item: Modify<
    ProductAttributeGroupResource<'product_attributes'>,
    {
      product_attributes: Array<ProductAttributeResource & { active: boolean }>
    }
  > & { open: boolean }
  attributeKey: string
}>()

const emit = defineEmits<{
  (e: 'filter'): void
}>()

const { isDesktop } = useScreenBreakpoints()

const attributeColorIds = computed(() =>
  getAttributeFilterIds('product-catalog.filter.color-product-ids'),
)

const selectedIds = ref<number[]>([])

const hoveredAttribute = ref<number | null>(null)

const toggleAttribute = (
  attribute: ProductAttributeResource & { active: boolean },
) => {
  attribute.active = !attribute.active
  selectedIds.value = props.item.product_attributes
    .filter((attr) => attr.active)
    .map((attr) => attr.id)
  emit('filter')
}

const getIconName = (
  attribute: ProductAttributeResource & { active: boolean },
) => {
  if (!attribute.active) {
    return hoveredAttribute.value === attribute.id && isDesktop.value
      ? 'bold-plus'
      : ''
  }

  if (isDesktop.value) {
    return hoveredAttribute.value === attribute.id ? 'bold-minus' : 'bold-check'
  }

  return 'bold-check'
}

const getColorClass = (attributeId: number) => {
  const attribute = attributeColorIds.value.find(
    (attr) => attr.id === attributeId,
  )
  if (attribute?.type === 'white' || attribute?.type === 'transparent') {
    return 'icon-dark-color'
  }
  return 'icon-light-color'
}

type AttributeType = 'white' | 'amber' | 'green' | 'black' | 'transparent'
type BackgroundClass =
  | 'bg-white'
  | 'bg-amber'
  | 'bg-green'
  | 'bg-black'
  | 'bg-transparent'

const backgroundClassMap: Record<AttributeType, BackgroundClass> = {
  white: 'bg-white',
  amber: 'bg-amber',
  green: 'bg-green',
  black: 'bg-black',
  transparent: 'bg-transparent',
}

const getBackgroundClass = (attributeId: number) => {
  const attribute = attributeColorIds.value.find(
    (attr) => attr.id === attributeId,
  )
  return attribute
    ? backgroundClassMap[attribute.type as AttributeType]
    : 'bg-transparent'
}

const getBorderClass = (attributeId: number) => {
  const attribute = attributeColorIds.value.find(
    (attr) => attr.id === attributeId,
  )
  if (attribute?.type === 'white') return 'border-white'
  if (attribute?.type === 'transparent') return 'border-transparent'
  return ''
}
</script>

<style lang="scss" scoped>
.category-filter {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 10px;
  background: #fff;
  border-radius: 16px;
  width: 408px;
  position: absolute;
  margin-top: 24px;
  border: 2px solid rgba(241, 241, 244, 1);
  z-index: 3;
  @media (max-width: 991px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(56px, 1fr));
    gap: 12px;
    padding: 0 16px 16px 16px;
    width: 100%;
    position: relative;
    border-radius: 0;
    margin-top: -3px;
    border: none;
  }
}

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 128px;
  border-radius: 12px;
  gap: 26px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:focus {
    outline: transparent;
  }
  @media (max-width: 991px) {
    position: relative;
    width: 56px;
    height: 56px;
  }
}

.category-item:hover {
  background: rgb(241, 241, 244);
}

.category-icon {
  width: 28px;
  margin-top: 6.2px;
  height: 28px;
  color: transparent;
  transition: color 0.3s ease-in-out;
  stroke-width: 20px;
  padding: 8px;
  border-radius: 8px;
  background: linear-gradient(180deg, #551a00 16%, #d29423 100%);
  @media (max-width: 991px) {
    width: 56px;
    height: 56px;
    padding: 21px;
    margin-top: 0;
    border-radius: 12px;
  }
}

.category-label {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: black;
}

.icon-dark-color {
  color: rgba(12, 13, 18, 1);
  padding: 19.5px;
  @media (min-width: 992px) {
    padding: 7.5px;
  }
}

.icon-light-color {
  color: white;
  @media (min-width: 992px) {
    padding: 9px;
  }
}

.bg-white {
  background: rgb(255, 255, 255);
}

.bg-amber {
  background: linear-gradient(180deg, #551a00 16%, #d29423 100%);
}

.bg-green {
  background: linear-gradient(180deg, #17370f 16%, #98ac6a 100%);
}

.bg-black {
  background: linear-gradient(180deg, #171717 16%, #4d4d4d 100%);
}

.bg-transparent {
  background: rgb(255, 255, 255);
}

.border-white {
  border: 1.8px solid rgba(233, 234, 236, 1);
}

.border-transparent {
  border: 1.8px dashed rgba(233, 234, 236, 1);
}
</style>
