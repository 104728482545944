<template>
  <div class="badge-wrapper">
    <ProProductBadge v-if="product.is_pro" />
    <div v-if="product.salePrice" class="badge">
      –{{ product.sale_discount }}%
    </div>
    <div
      v-for="(badge, index) in badges"
      :key="index"
      class="badge status"
      :class="badge.cssClass"
    >
      {{ badge.text }}
    </div>
  </div>
</template>

<script setup lang="ts">
import ProProductBadge from '@/Partials/Products/ProProductBadge.vue'
import { computed } from 'vue'
import { ProductUnavailabilityStatusType } from '@/Types/Product'
import { ProductType } from '@/Types/Product'
import type { ProductVMType } from '@/ViewModels/ProductVM'
import { __ } from '@/Helpers/i18n'

const props = defineProps<{
  product: Omit<
    ProductVMType<
      | 'gallery_photos'
      | 'inspiration_gallery_photos'
      | 'blacklisted_countries'
      | 'product_attributes'
      | 'variant_products_count'
    >,
    'collection_box_products' | 'sample_set_products'
  >
}>()

const BADGE_TEXT = {
  OUT_OF_STOCK: __('products', 'catalog.badge.out-of-stock.text'),
  COLLECTION_BOX: __('products', 'catalog.badge.collection-box.text'),
  COMING_SOON: __('products', 'catalog.badge.coming-soon.text'),
  NEW: __('products', 'catalog.badge.new.text'),
  BESTSELLER: __('products', 'catalog.badge.bestseller.text'),
}

const BADGE_CLASSES = {
  OUT_OF_STOCK: '',
  COLLECTION_BOX: 'collection-box',
  COMING_SOON: 'coming-soon',
  NEW: 'new',
  BESTSELLER: 'bestseller',
}

const badges = computed(() => {
  const badgeList: { text: string; cssClass: string }[] = []

  if (
    props.product.unavailability_status ===
    ProductUnavailabilityStatusType.OUT_OF_STOCK
  ) {
    badgeList.push({
      text: BADGE_TEXT.OUT_OF_STOCK,
      cssClass: BADGE_CLASSES.OUT_OF_STOCK,
    })
  }

  if (props.product.type === ProductType.COLLECTION_BOX) {
    badgeList.push({
      text: BADGE_TEXT.COLLECTION_BOX,
      cssClass: BADGE_CLASSES.COLLECTION_BOX,
    })
  }

  if (
    props.product.unavailability_status ===
    ProductUnavailabilityStatusType.COMING_SOON
  ) {
    badgeList.push({
      text: BADGE_TEXT.COMING_SOON,
      cssClass: BADGE_CLASSES.COMING_SOON,
    })
  }

  if (props.product.is_new) {
    badgeList.push({
      text: BADGE_TEXT.NEW,
      cssClass: BADGE_CLASSES.NEW,
    })
  }

  if (props.product.is_bestseller) {
    badgeList.push({
      text: BADGE_TEXT.BESTSELLER,
      cssClass: BADGE_CLASSES.BESTSELLER,
    })
  }

  return badgeList
})
</script>

<style scoped lang="scss">
.badge-wrapper {
  position: absolute;
  top: 12px;
  left: 12px;
  right: 20px;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  max-width: 100%;
  word-break: break-word;
  @media (max-width: 991px) {
    top: 8px;
    left: 8px;
    right: 8px;
  }
}

.badge {
  background: $black;
  color: $white;
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 11px;
  padding: 6px 8px;
  line-height: 11px;
  font-weight: 600;
  border-radius: 6px;

  &.status {
    background-color: $gray-1;
  }
}

.badge.status.coming-soon {
  background: rgb(245, 205, 145);
  color: rgb(12, 13, 18);
}

.badge.collection-box {
  background: rgb(118, 161, 242);
  color: rgb(255, 255, 255);
}

.badge.new {
  background: rgb(143, 189, 99);
  color: rgb(255, 255, 255);
}

.badge.bestseller {
  background: rgb(243, 128, 130);
  color: rgb(255, 255, 255);
}
</style>
