import {
  LayoutCodeComboResource,
  ProductResource,
  ProductResourceExpansionKeys,
  ProductType,
  UNIT_TYPES,
} from '@/Types/Product'
import { getCurrentSessionValue } from '@/Helpers/Global'
import { __ } from '@/Helpers/i18n'
import { CountryResource } from '@/Types/Country'
import { PrintDesignTemplateResource } from '@/Types/PrintDesignTemplate'
import { ProductDescriptionType } from '@/Types/ProductDescription'
import { ProductAttributeResource } from '@/Types/ProductAttribute'
import { GalleryPhotoResource } from '@/Types/GalleryPhoto'
import {
  UNITED_STATES_COUNTRY_CODE,
  US_FL_OZ_TO_G,
  US_FL_OZ_TO_ML,
} from '@/Helpers/Constants'

export default class ProductVM<
  T extends ProductResourceExpansionKeys | never = never,
> {
  private readonly product: ProductResource<T>
  private readonly countryCode: string

  private constructor(
    product: ProductResource<T>,
    countryCode = getCurrentSessionValue('CURRENT_COUNTRY').code,
  ) {
    this.product = product
    // make all the properties of product accessible straight from the view model;
    // do not remove, else the cast `new ProductVM(product) as ProductVMType<T>` becomes a lie.
    // if there is a conflict, the view model property takes precedence
    Object.keys(this.product).forEach((key) => {
      const prototypeDescriptor = Object.getOwnPropertyDescriptor(
        Object.getPrototypeOf(this),
        key,
      )
      if (!prototypeDescriptor?.get) {
        Object.defineProperty(this, key, {
          get: () => this.product[key as keyof typeof product],
          enumerable: true,
        })
      }
    })

    this.countryCode = countryCode
  }

  public static createFrom<
    T extends ProductResourceExpansionKeys | never = never,
  >(product: ProductResource<T>, countryCode?: string) {
    /** @see the class constructor */
    return new ProductVM(product, countryCode) as ProductVMType<T>
  }

  retrieveIngredientLayerUrl = (type: 'LABEL' | 'BOX') => {
    const currentCountry = getCurrentSessionValue('CURRENT_COUNTRY')

    if (!this.product.ingredient_layers) {
      if (this.product.type === ProductType.REGULAR || this.product.is_pro) {
        console.warn(
          `Product with type ${this.product.type} has no ingredient layers`,
        )
      }

      return null
    }

    const ingredientLayersOfType = this.product.ingredient_layers.filter(
      (l) => l.type === type,
    )

    let currentCountryIngredientLayer:
      | (typeof this.product.ingredient_layers)[number]
      | null = null
    let defaultIngredientLayer:
      | (typeof this.product.ingredient_layers)[number]
      | null = null

    ingredientLayersOfType.forEach((layer) => {
      if (!('countries' in layer)) {
        console.error('Ingredient layer countries must be expanded!')
        return
      }

      const countries = layer.countries as CountryResource[]

      if (
        !currentCountryIngredientLayer &&
        countries.some((c) => c.id === currentCountry.id)
      ) {
        currentCountryIngredientLayer = layer
      }

      if (!defaultIngredientLayer && countries.length === 0) {
        defaultIngredientLayer = layer
      }
    })

    return (
      currentCountryIngredientLayer ??
      defaultIngredientLayer ??
      ingredientLayersOfType[0]
    )
  }

  retrievePrintDesignTemplates = (
    layoutCode?:
      | LayoutCodeComboResource['label_layout_code']
      | LayoutCodeComboResource['box_layout_code']
      | null
      | undefined,
  ) => {
    if (
      !this.product.print_design_templates?.length ||
      !this.product.print_design_templates[0].countries
    ) {
      return null
    }

    const currentCountry = getCurrentSessionValue('CURRENT_COUNTRY')

    const printDesignTemplatesOfType = (
      this.product
        .print_design_templates as PrintDesignTemplateResource<'countries'>[]
    ).filter((t) => t.code === layoutCode)

    let currentCountryPrintDesignTemplate: PrintDesignTemplateResource<'countries'> | null =
      null
    let defaultPrintDesignTemplate: PrintDesignTemplateResource<'countries'> | null =
      null

    printDesignTemplatesOfType.forEach((template) => {
      if (!('countries' in template)) {
        console.error('Print design template countries must be expanded!')
        return
      }

      const countries = template.countries

      if (
        !currentCountryPrintDesignTemplate &&
        countries.some((c) => c.id === currentCountry.id)
      ) {
        currentCountryPrintDesignTemplate = template
      }

      if (!defaultPrintDesignTemplate && countries.length === 0) {
        defaultPrintDesignTemplate = template
      }
    })

    return (
      currentCountryPrintDesignTemplate ??
      defaultPrintDesignTemplate ??
      printDesignTemplatesOfType[0]
    )
  }

  get sampleSetsProductCount() {
    return this.product.sample_set_products?.length ?? 0
  }

  get title() {
    return this.countryCode === UNITED_STATES_COUNTRY_CODE &&
      this.product.us_title
      ? this.product.us_title
      : this.product.title
  }

  get collectionBoxProductsCount() {
    return this.product.collection_box_products?.length ?? 0
  }

  get boxPrintDesignTemplate() {
    return this.retrievePrintDesignTemplates(
      this.product.layout_code_combo?.box_layout_code,
    )
  }
  get labelPrintDesignTemplate() {
    return this.retrievePrintDesignTemplates(
      this.product.layout_code_combo?.label_layout_code,
    )
  }
  get gallery_photos() {
    if (!this.product.gallery_photos) return []

    return [...(this.product.gallery_photos as GalleryPhotoResource[])].sort(
      (a, b) =>
        // sort by position, then by id
        a.position !== b.position ? a.position - b.position : a.id - b.id,
    )
  }
  get image() {
    if (!this.product.gallery_photos) {
      console.warn('getting product image when gallery_photos not expanded')
    }
    return this.gallery_photos?.[0]?.image ?? null
  }
  get is_sample_set() {
    return this.product.type === ProductType.SAMPLE_SET
  }
  get ingredient_layers() {
    return {
      label: this.retrieveIngredientLayerUrl('LABEL'),
      box: this.product.has_box ? this.retrieveIngredientLayerUrl('BOX') : null,
    }
  }
  get volume() {
    return this.product.type === ProductType.REGULAR ||
      this.product.type === ProductType.COLLECTION_BOX ||
      this.product.is_pro
      ? `${this.product.volume} ml / ${(this.product.volume / US_FL_OZ_TO_ML).toFixed(2)} fl oz`
      : null
  }
  get titleAndVolume() {
    if (this.product.type === ProductType.COLLECTION_BOX) {
      return this.title
    }

    if (this.product.unit_type === UNIT_TYPES.WEIGHT) {
      return [this.title, this.weight].join(', ')
    }

    return [this.title, this.volume].join(', ')
  }
  get weight() {
    if (this.product.unit_type !== UNIT_TYPES.WEIGHT) return null

    const ounces = this.product.volume / US_FL_OZ_TO_G

    return `${this.product.volume} g / ${ounces.toFixed(2)} oz`
  }

  get label_print_design_code() {
    return this.product.layout_code_combo?.label_layout_code ?? null
  }
  get box_print_design_code() {
    return this.product.layout_code_combo?.box_layout_code ?? null
  }
  get cartInfoText() {
    let count = 0
    const showProductCount =
      this.product.type === ProductType.SAMPLE_SET ||
      this.product.type === ProductType.COLLECTION_BOX

    if (showProductCount) {
      count = this.sampleSetsProductCount || this.collectionBoxProductsCount
    }

    if (showProductCount) {
      return `${count} ${__('global', 'products')}`
    }

    if (this.product.unit_type === UNIT_TYPES.WEIGHT) {
      return this.weight
    }

    if (this.product.unit_type === UNIT_TYPES.VOLUME) {
      return this.volume
    }

    return this.product.type === ProductType.SERVICE
      ? __('profile', 'Services')
      : ''
  }

  /** same as cartInfoText, but for regular products it is "Volume 250ml" not "250ml" */
  get productCardInfoText() {
    if (
      ProductType.REGULAR ||
      (this.product.is_pro && this.product.unit_type === UNIT_TYPES.WEIGHT)
    ) {
      return __('global', 'Weight') + ' ' + this.weight
    }

    if (
      ProductType.REGULAR ||
      (this.product.is_pro && this.product.unit_type === UNIT_TYPES.VOLUME)
    ) {
      return __('global', 'Volume') + ' ' + this.volume
    }

    return this.cartInfoText
  }

  get salePrice() {
    return this.product.price &&
      this.product.sale_discount &&
      this.product.is_on_sale
      ? this.product.price * ((100 - this.product.sale_discount) / 100)
      : null
  }

  get saleNetPrice() {
    return this.product.net_price &&
      this.product.sale_discount &&
      this.product.is_on_sale
      ? this.product.net_price * ((100 - this.product.sale_discount) / 100)
      : null
  }

  get saleGrossPrice() {
    return this.product.gross_price &&
      this.product.sale_discount &&
      this.product.is_on_sale
      ? this.product.gross_price * ((100 - this.product.sale_discount) / 100)
      : null
  }

  get lowestAvailablePrice() {
    return this.salePrice ?? this.product.price
  }
  get minimumQuantityText() {
    return this.product.minimum_quantity
      ? this.product.minimum_quantity + ' ' + __('global', 'Units')
      : __('products', 'No MOQ')
  }
  get saleMinimumQuantityText() {
    return this.product.sale_minimum_quantity
      ? this.product.sale_minimum_quantity + ' ' + __('global', 'Units')
      : ''
  }
  get useSaleMinimumQuantity() {
    return !!(this.product.is_on_sale && this.product.sale_minimum_quantity)
  }
  get currentMinimumQuantity() {
    return this.useSaleMinimumQuantity
      ? (this.product.sale_minimum_quantity ?? this.product.minimum_quantity)
      : this.product.minimum_quantity
  }
  get minimumQuantityToUse() {
    const defaultMoq = 1
    return this.useSaleMinimumQuantity
      ? (this.product.sale_minimum_quantity ??
          this.product.minimum_quantity ??
          defaultMoq)
      : (this.product.minimum_quantity ?? defaultMoq)
  }
  get showOverviewTab() {
    return !!(
      this.product.product_tags?.length ||
      this.attributesGrouped.concern.length ||
      this.attributesGrouped.skinType.length ||
      this.product.certification ||
      this.product.tags?.length ||
      this.product.aroma
    )
  }
  get showInciTab() {
    return !!(
      this.product.inci ||
      this.product.inci_title ||
      this.product.inci_ingredients_description ||
      this.product.natural_origin_total ||
      this.product.organic_origin_total ||
      this.product.certification
    )
  }
  get showUspsTab() {
    if (!this.product.product_descriptions) return false
    return this.product.product_descriptions.some(
      (description) => description.type === ProductDescriptionType.USP,
    )
  }
  get showProductionTab() {
    if (!this.product.product_descriptions) return false
    return this.product.product_descriptions.some(
      (description) => description.type === ProductDescriptionType.PRODUCTION,
    )
  }
  get showDeliveryTab() {
    if (!this.product.product_descriptions) return false
    return this.product.product_descriptions.some(
      (description) => description.type === ProductDescriptionType.DELIVERY,
    )
  }
  get attributesGrouped() {
    const groups = {
      concern: [] as ProductAttributeResource<'parent'>[],
      skinType: [] as ProductAttributeResource<'parent'>[],
    }
    if (!this.product.product_attributes) {
      console.error(
        'Getting product attributes when product_attributes not expanded!',
      )
      return groups
    }
    if (!this.product.product_attributes.length) {
      return groups
    }
    if (!this.product.product_attributes[0].parent) {
      console.error('Product attribute parent must be expanded!')
      return groups
    }

    return (
      this.product.product_attributes as ProductAttributeResource<'parent'>[]
    ).reduce((groups, attribute) => {
      const attributeGroupName = attribute.parent.name.toLowerCase().trim()
      switch (attributeGroupName) {
        case 'concern':
          groups.concern.push(attribute)
          break
        case 'skin type':
          groups.skinType.push(attribute)
          break
        default:
          break
      }
      return groups
    }, groups)
  }

  downloadTemplates() {
    if (this.boxPrintDesignTemplate?.file) {
      window.open(this.boxPrintDesignTemplate.file, '_blank')
    }
    if (this.labelPrintDesignTemplate?.file) {
      window.open(this.labelPrintDesignTemplate.file, '_blank')
    }
  }
}

export type ProductVMType<
  T extends ProductResourceExpansionKeys | never = never,
> = Omit<ProductResource<T>, keyof ProductVM<T>> & ProductVM<T>
