<template>
  <div class="inner-banner" :class="bannerData.bannerClass">
    <div class="banner-text" :class="bannerData.bannerText">
      <div
        v-if="bannerData.iconName"
        class="banner-icon"
        :class="bannerData.bannerIcon"
      >
        <SvgIcon
          alt="Discount Icon"
          :class="bannerData.iconClass"
          :name="bannerData.iconName"
        />
      </div>
      <div class="text-wrapper" :class="bannerData.textWrapper">
        <div
          v-if="bannerData.headerText"
          class="banner-title"
          :class="bannerData.textClass"
        >
          {{ bannerData.headerText }}
        </div>
        <div
          v-if="bannerData.descriptionText"
          class="banner-subtitle"
          :class="bannerData.textClass"
        >
          {{ bannerData.descriptionText }}
        </div>
      </div>
    </div>
    <button
      v-if="bannerData.showButton"
      class="btn desktop"
      :class="bannerData.buttonClass"
      :disabled="ctaButtonDisabled"
      type="button"
      @click="handleCtaButtonClick"
    >
      {{ bannerData.buttonLabel }}
    </button>
  </div>
  <SideCart v-if="cartStore.cart" :open="sideCartOpen" @close="closeSideCart" />
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { computed, ref } from 'vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import { useLoginStore } from '@/Store/useLoginStore'
import useProductActions from '@/Composables/useProductActions'
import SideCart from '@/Components/SideCart.vue'
import useSideCartActions from '@/Composables/useSideCartActions'
import { useCartStore } from '@/Store/useCartStore'
import { DISCOUNT_KEYS } from '@/Helpers/Constants'
import { getProducts } from '@/Api/Products'
import { BannerData } from '@/Types/Profile'

const props = defineProps<{
  bannerData: BannerData
}>()

const loginStore = useLoginStore()
const router = useRouter()
const cartStore = useCartStore()
const { addToCart } = useProductActions()
const { sideCartOpen, openSideCart, closeSideCart } = useSideCartActions()

const hasDropshippingDiscount = computed(
  () => loginStore.profile?.user_discounts?.[DISCOUNT_KEYS.ECOMMERCE] ?? null,
)

const ctaButtonDisabled = ref(false)
const handleCtaButtonClick = async () => {
  if (hasDropshippingDiscount.value) {
    await router.push({
      path: 'dashboard.dropshipping-discount.banner.button.href',
    })
  } else {
    if (!props.bannerData.serviceProductCode) return

    ctaButtonDisabled.value = true

    try {
      const product = (
        await getProducts({
          data: { code: props.bannerData.serviceProductCode, types: [] },
          expandArray: [],
        })
      ).data[0]

      if (!product) return

      await addToCart(product)
      openSideCart()
    } catch (e) {
      console.error(e)
    } finally {
      ctaButtonDisabled.value = false
    }
  }
}
</script>

<style>
:root {
  --color-dropshipper-background: rgb(215, 233, 216);
  --color-dropshipper-text: rgb(35, 50, 45);
  --color-user-text: rgb(22, 31, 77);
  --color-icon-user: rgb(224, 236, 254);
}
</style>

<style scoped>
.inner-banner {
  align-items: center;
  border: solid #ffffff 6px;
  border-radius: 24px;
  display: flex;
  justify-content: space-between;
  padding: 26px;
}

.banner-text {
  display: flex;
}

.banner-icon {
  display: flex;
  margin-left: 16.5px;
  margin-right: 36px;
  align-items: center;
}

.banner-icon .dropshipper-icon,
.banner-icon .user-icon {
  height: 60px;
  width: 60px;
}

.dropshipper-icon {
  color: var(--color-dropshipper-background);
}

.user-icon {
  color: var(--color-icon-user);
}

.banner-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 160%;
  letter-spacing: -0.3px;
}

.banner-subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;
  margin-top: 4px;
  letter-spacing: -0.1px;
}

.btn {
  color: #ffffff;
  margin-right: 22px;
  white-space: nowrap;
}

.dropshipper-banner {
  background-color: var(--color-dropshipper-background);
}

.user-banner {
  background-color: var(--color-icon-user);
}

.dropshipper-text {
  color: var(--color-dropshipper-text);
}

.user-text {
  color: var(--color-user-text);
}

.dropshipper-button {
  background-color: var(--color-dropshipper-text);
}

.user-button {
  background-color: var(--color-user-text);
}

.first-order-banner {
  background-color: var(--color-icon-user);
  border: none;
  border-radius: 16px;
  padding: 12px 21px;
  margin-bottom: 27px;
  margin-top: 27px;
}

.first-order-icon {
  color: var(--color-icon-user);
  height: 40px;
  width: 40px;
}

.first-order-text {
  line-height: 40px;
  color: var(--color-user-text);
  font-size: 18px;
}

.first-order-icon-wrapper {
  margin-right: 20px;
  margin-left: 15px;
}

@media (max-width: 768px) {
  .inner-banner {
    align-items: flex-start;
    border: solid white 5px;
    border-radius: 32px;
    flex-direction: column;
    margin-top: 0;
  }

  .banner-text {
    flex-direction: column;
  }

  .banner-icon {
    margin-top: 3px;
    margin-left: 6px;
    margin-bottom: 16px;
  }

  .banner-icon .dropshipper-icon,
  .banner-icon .user-icon {
    height: 40px;
    width: 40px;
  }

  .text-wrapper {
    margin: 0 6px;
    margin-bottom: 28px;
  }

  .banner-title {
    font-size: 16px;
    margin-bottom: 8px;
    line-height: 150%;
    letter-spacing: -0.2px;
  }

  .banner-subtitle {
    font-size: 12px;
    line-height: 160%;
    font-weight: 400;
    margin-top: 0;
    letter-spacing: 0;
  }

  .btn {
    font-size: 12px;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 9px;
  }

  .first-order-banner {
    align-items: flex-start;
    border: none;
    flex-direction: column;
    padding: 23px;
    border-radius: 18px;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  .first-order-icon {
    margin-bottom: 0;
  }

  .first-order-text {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0;
  }

  .first-order-global-text {
    flex-direction: row;
  }

  .first-order-text-wrapper {
    margin-bottom: 0;
    align-content: center;
  }

  .first-order-diamond-percent {
    margin-bottom: 0;
  }

  .first-order-icon-wrapper {
    margin: 0 16px 0 6px;
  }
}
</style>
