<template>
  <p class="product-block-bottom" :class="{ highlighted }">
    <span v-if="product.price" class="price-wrapper">
      <span class="active-price">{{ displayPrice }}</span>

      <span v-if="product.salePrice" class="disabled-price">
        {{
          showTax
            ? floatToCurrency(product.gross_price ?? 0)
            : floatToCurrency(product.net_price ?? 0)
        }}
      </span>
    </span>

    <span class="vat">
      {{ showTax ? __('products', 'incl. VAT') : __('products', 'excl. VAT') }}
    </span>
  </p>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { floatToCurrency } from '@/Helpers/Global'
import { __ } from '@/Helpers/i18n'
import { ProductVMType } from '@/ViewModels/ProductVM'

const props = defineProps<{
  product: Pick<
    ProductVMType,
    | 'price'
    | 'salePrice'
    | 'saleNetPrice'
    | 'saleGrossPrice'
    | 'gross_price'
    | 'net_price'
  >
  highlighted: boolean
  showTax: boolean
}>()

const displayPrice = computed(() =>
  floatToCurrency(
    props.product.salePrice
      ? props.showTax
        ? (props.product.saleGrossPrice ?? 0)
        : (props.product.saleNetPrice ?? 0)
      : props.showTax
        ? (props.product.gross_price ?? 0)
        : (props.product.net_price ?? 0),
  ),
)
</script>

<style scoped lang="scss">
.product-block-bottom {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
  transition: 0.3s all;
  gap: 8px;
  align-items: center;
  @media (max-width: 991px) {
    flex-direction: row;
    gap: 2px;
  }
}

.price-wrapper {
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
}

.disabled-price {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 49%;
    height: 1.5px;
    background-color: rgba(0, 0, 0, 1);
  }
}

.active-price {
  letter-spacing: -0.2px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  @media (max-width: 991px) {
    margin-right: 8px;
  }
}

.vat {
  align-self: flex-end;
  color: $gray-1;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 1px;
}
</style>
