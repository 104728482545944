<template>
  <div class="input-group-container">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.input-group-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}
</style>
