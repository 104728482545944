<template>
  <FormFlow
    :image-section-alt="__('auth', 'check-email.image-section.alt')"
    :image-section-src="__('auth', 'check-email.image-section.url')"
    :show-previous-step-button="false"
  >
    <template #form>
      <h2 class="form-flow-heading">
        {{ __('auth', 'check-email.main-heading.text') }}
      </h2>
      <h5 class="check-email-secondary-heading form-flow-secondary-heading">
        {{ __('auth', 'check-email.secondary-heading.text') }}
      </h5>
      <form action="" class="check-email-form" @submit.prevent="resendEmail">
        <p class="email">{{ registrationEmail }}</p>
        <BaseButton
          btn-style="btn-secondary"
          class="change-email-button"
          type="button"
          @click="emit('changeEmail')"
          >{{ __('auth', 'check-email.change-email-button.text') }}</BaseButton
        >
      </form>
    </template>
  </FormFlow>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { __ } from '@/Helpers/i18n'
import FormFlow from '@/Components/FormFlow.vue'
import BaseButton from '@/Components/BaseButton.vue'
import { postRegistration } from '@/Api/Registration'

const props = defineProps<{
  registrationEmail: string
  receiveEmails: boolean
}>()

const emit = defineEmits<{
  changeEmail: []
}>()
const registrationErrors = ref()
const loading = ref(false)

const resendEmail = async () => {
  loading.value = true
  registrationErrors.value = null
  const data: { [key: string]: any } = {
    terms_accepted: true,
    receive_emails: props.receiveEmails,
    email: props.registrationEmail,
  }

  await postRegistration(data).catch((error: any) => {
    registrationErrors.value = error.response.data
  })

  loading.value = false
}
</script>

<style lang="scss" scoped>
.input-margin-bottom {
  margin-bottom: 12px;
}

.form-flow-secondary-heading {
  max-width: 445px;
}

.check-email-secondary-heading {
  margin-top: 40px;

  @media (min-width: 768px) {
    margin-top: 32px;
  }
}

.check-email-form {
  @include centered-flex-column;
  margin-top: 12px;

  .email {
    background: $gray-4;
    padding: 10px 16px;
    border-radius: 12px;
  }

  .change-email-button {
    margin-top: 48px;
  }
}
</style>
