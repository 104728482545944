<template>
  <header class="site-header">
    <div
      v-if="layoutStore.showGlobalMessageBanner"
      ref="globalMessageBanner"
      class="global-message-banner"
      :class="{ 'hide-via-max-height': !showTopBanner }"
    >
      <div
        class="global-message-banner-text"
        v-html="__('home', 'Header global message')"
      ></div>
    </div>
    <div
      class="container-wrapper"
      :class="{ 'new-container-wrapper': newHeaderDesign }"
    >
      <div
        v-if="showHeaderContent"
        class="container"
        :class="{ 'hide-via-max-height': headerScrolled }"
      >
        <button
          v-if="layoutStore.mobileMenuActiveItem"
          class="btn btn-mob-back btn-transparent"
          @click="layoutStore.mobileMenuActiveItem = null"
        >
          <SvgIcon name="chevron" />
        </button>
        <router-link class="logo" :to="`/${langStore.getLang()}`">
          <OptimizedImg
            alt="Logo"
            :size="{ height: 18, width: 148 }"
            src="selfnamed-logo.svg"
          />
        </router-link>
        <Menu @open-sign-up="(data) => (authenticationData = data)" />
        <div v-if="isDesktop" class="right-menu">
          <div
            class="options"
            :class="{ 'logged-in': loginStore.userLoggedIn }"
          >
            <div
              v-if="sessionSettings && countries"
              v-click-away="closePreferencesMenu"
              class="desktop-preferences"
            >
              <p v-if="currentCountry" class="header-bubble">
                <span>{{ currentCountry }}</span>
              </p>
              <a href="#" @click.prevent="togglePreferencesMenu">
                <SvgIcon name="globe" />
              </a>
              <PreferencesDropdownDesktop
                v-if="openPreferences"
                @close="closePreferencesMenu"
              />
            </div>
            <ConditionalRouterLink
              v-if="loginStore.userLoggedIn"
              :active="loginStore.userLoggedIn"
              class="profile"
              :to="toProfile"
              @disabled-click="
                () =>
                  (authenticationData = {
                    redirectUrl: toProfile,
                    isLogin: false,
                  })
              "
            >
              <SvgIcon name="profile" />
            </ConditionalRouterLink>
            <ConditionalRouterLink
              v-if="loginStore.userLoggedIn"
              :active="loginStore.userLoggedIn"
              class="cart"
              :to="toCart"
              @disabled-click="
                () =>
                  (authenticationData = { redirectUrl: toCart, isLogin: false })
              "
            >
              <p v-if="cartStore.cartItemQuantity" class="header-bubble">
                <span>{{ cartStore.cartItemQuantity }}</span>
              </p>
              <SvgIcon name="cart" />
            </ConditionalRouterLink>
          </div>
          <div v-if="!loginStore.userLoggedIn" class="login-btn-row">
            <template v-if="FEATURE_FLAGS.SHOW_SIGN_UP_FLOW">
              <router-link :to="toLogin">
                <button class="btn btn-secondary type-login">
                  {{ __('global', 'Sign in') }}
                </button>
              </router-link>
              <a
                v-if="bookCallButtonActive"
                class="btn btn-primary btn-sign-up"
                :href="toBookCall"
                rel="noopener noreferrer"
                target="_blank"
              >
                {{ __('home', 'header.demo-call-button.text') }}
              </a>
              <router-link v-else :to="toSignUp">
                <button class="btn btn-primary btn-sign-up">
                  {{ __('global', 'Sign up') }}
                </button>
              </router-link>
            </template>
            <template v-else>
              <button
                class="btn btn-secondary type-login"
                @click="authenticationData = { isLogin: true }"
              >
                {{ __('global', 'Sign in') }}
              </button>
              <a
                v-if="bookCallButtonActive"
                class="btn btn-primary btn-sign-up"
                :href="toBookCall"
                rel="noopener noreferrer"
                target="_blank"
              >
                {{ __('home', 'header.demo-call-button.text') }}
              </a>
              <button
                v-else
                class="btn btn-primary btn-sign-up"
                @click="authenticationData = { isLogin: false }"
              >
                {{ __('global', 'Sign up') }}
              </button>
            </template>
          </div>
        </div>
        <div v-if="isMobile" class="menu-toggle">
          <router-link v-if="cartStore.cart" class="burger" :to="toCart">
            <p v-if="cartStore.cartItemQuantity" class="header-bubble">
              <span>{{ cartStore.cartItemQuantity }}</span>
            </p>
            <SvgIcon name="cart" />
          </router-link>
          <a
            v-show="!layoutStore.mobileMenuOpen"
            aria-label="Menu toggle"
            class="burger"
            href="#"
            @click.prevent="layoutStore.mobileMenuOpen = true"
          >
            <SvgIcon name="burger" />
          </a>
          <a
            v-show="layoutStore.mobileMenuOpen"
            aria-label="Menu toggle"
            class="exit"
            href="#"
            @click.prevent="layoutStore.mobileMenuOpen = false"
          >
            <SvgIcon name="exit-new" />
          </a>
        </div>
      </div>
    </div>
    <SignUpModal
      v-if="authenticationData"
      :login="authenticationData.isLogin"
      open
      :redirect-url="authenticationData.redirectUrl"
      @close="authenticationData = null"
    />
  </header>
</template>

<script setup lang="ts">
import Menu from '@/Partials/Menu/Menu.vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import { SessionSetting } from '@/Types/SessionSetting'
import { CountryResourceMainFields } from '@/Types/Country'
import {
  computed,
  onMounted,
  ref,
  useTemplateRef,
  watch,
  watchEffect,
} from 'vue'
import { RouteLocationRaw, useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import { __ } from '@/Helpers/i18n'
import { useLoginStore } from '@/Store/useLoginStore'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { useCartStore } from '@/Store/useCartStore'
import useLangStore from '@/Store/useLangStore'
import SignUpModal from './SignUpModal.vue'
import { withLang } from '@/routes'
import ConditionalRouterLink from '@/Partials/ConditionalRouterLink.vue'
import OptimizedImg from '@/Partials/ui/OptimizedImg.vue'
import { useElementSize, useWindowScroll } from '@vueuse/core'
import PreferencesDropdownDesktop from '@/Partials/Menu/PreferencesDropdownDesktop.vue'
import useLayoutStore from '@/Store/useLayoutStore'
import useScreenBreakpoints from '@/Composables/UseScreenBreakpoints'
import { FEATURE_FLAGS } from '@/Helpers/Global'
import { UNITED_STATES_COUNTRY_CODE } from '@/Helpers/Constants'

defineProps<{ showHeaderContent: boolean }>()

// Reactive properties
const loginStore = useLoginStore()
const globalStore = useGlobalStore()
const cartStore = useCartStore()
const langStore = useLangStore()
const layoutStore = useLayoutStore()
const router = useRouter()

// Refs
const headerScrolled = ref(false)
const openPreferences = ref(false)
const signUpOpen = ref(false)
const authenticationData = ref<{
  isLogin: boolean
  redirectUrl?: RouteLocationRaw
} | null>(null)

//Template refs
const { y: windowScrollY } = useWindowScroll()
const { isMobile, isDesktop } = useScreenBreakpoints()
const globalMessageBanner = useTemplateRef<HTMLDivElement>(
  'globalMessageBanner',
)

const { height: globalMessageBannerHeight } = useElementSize(
  globalMessageBanner,
  undefined,
  { box: 'border-box' },
)

//Properties
const toSignUp = withLang({ name: 'create-account' })
const toLogin = withLang({ name: 'login' })

// Computed properties
const showTopBanner = computed(() => windowScrollY.value <= 20)
const sessionSettings = computed(() => globalStore.sessionSettings)
const countries = computed(() => globalStore.countries!)
const currentCountry = computed(
  () =>
    countries.value.find(
      (item: CountryResourceMainFields) =>
        item.id ===
        sessionSettings.value?.find(
          (item: SessionSetting) => item.id === 'CURRENT_COUNTRY',
        )?.value,
    )?.code,
)

const toBookCall = computed(() =>
  __('home', 'header.demo-call-button.url').trim(),
)
const bookCallButtonActive = computed(
  () =>
    import.meta.env.VITE_SHOW_USA_BOOK_CALL_BUTTON === 'true' &&
    currentCountry.value === UNITED_STATES_COUNTRY_CODE,
)
const newHeaderDesign = computed(
  () =>
    import.meta.env.VITE_SHOW_NEW_PRODUCTS_CATALOG === 'true' &&
    router.currentRoute.value.meta.type === 'product',
)
const toProfile = computed(() => withLang({ name: 'profile' }))
const toCart = computed(() => withLang({ name: 'cart' }))

// Watchers
watch(windowScrollY, (n, o) => {
  const productFiltersOpen = document.querySelector('.products .filters.open')
  headerScrolled.value =
    !(signUpOpen.value || layoutStore.mobileMenuOpen || productFiltersOpen) &&
    n >= Math.max(80, o)
})

watchEffect(() =>
  layoutStore.setGlobalMessageBannerHeight(globalMessageBannerHeight.value),
)
// Lifecycle hooks
onMounted(() => {
  router.beforeEach((to, from, next) => {
    layoutStore.mobileMenuOpen = false
    next()
  })
})

// Lifecycle hooks
onMounted(() => {
  router.beforeEach((to, from, next) => {
    layoutStore.mobileMenuOpen = false
    next()
  })
})

//Methods
const togglePreferencesMenu = () => {
  if (
    cartStore.cart &&
    cartStore.cart.cart_items.length &&
    openPreferences.value === false
  ) {
    Swal.fire({
      position: 'top-end',
      customClass: { popup: 'info-popup' },
      title: __('header', 'Changing the country will delete the shopping bag'),
      showConfirmButton: false,
      timer: 5000,
      toast: true,
    })
  }
  openPreferences.value = !openPreferences.value
}

const closePreferencesMenu = () => {
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0,
  )
  if (vw >= 992) {
    openPreferences.value = false
  }
}
</script>

<style lang="scss">
.site-header {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  width: 100%;
  z-index: 4;
  @include desktop-only {
    padding: 0;
    position: absolute;
  }

  .container-wrapper {
    background: $white;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
  }

  .new-container-wrapper {
    border-bottom: none;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5.9rem;
    max-height: 5.9rem;
    transition: 0.3s all;
    @include desktop-only {
      justify-content: flex-start;
    }
  }

  .logo {
    flex: 0 0 14.8rem;
    width: 100%;
    transition: none;

    &:nth-child(2) {
      flex: 0 0 9.8rem;
    }

    @include desktop-only {
      flex: 0 0 17.6rem;
      padding-right: 2.8rem;
      border-right: 1px solid $light-gray;
      margin-right: 1.8rem;
    }

    img {
      width: 100%;
    }
  }

  .right-menu {
    @include desktop-only {
      align-items: center;
      display: inline-flex;
      margin-left: auto;
    }
  }

  .options {
    display: flex;
    align-items: center;
    padding: 0.6rem 0;
    margin-right: 0.4rem;

    &.logged-in {
      padding: 0.6rem 2.8rem 0.6rem 0;
      margin-right: 2.8rem;

      &:not(:last-child) {
        border-right: 1px solid $light-gray;
      }
    }
  }

  .menu-toggle {
    display: flex;
    gap: 2rem;
  }

  .cart {
    position: relative;
    @include desktop-only {
      margin-left: 2.8rem;
    }

    .header-bubble {
      @include mobile-only {
        right: -1.2rem;
        top: 0.4rem;
      }
    }
  }

  .login-btn-row {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    button {
      padding: 1.4rem 1.6rem;
      white-space: nowrap;
    }
  }

  .burger,
  .exit {
    color: $black;
    position: relative;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .btn-mob-back {
    transform: rotate(90deg);
    padding: 0;
    margin-left: 0.4rem;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.menu,
.site-header,
.desktop-preferences {
  .profile,
  .cart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $black;
    margin-bottom: 2.4rem;
    cursor: pointer;

    @include desktop-only {
      margin-bottom: 0;
    }

    &:hover {
      color: $orange;
    }

    > div {
      display: flex;
      align-items: center;
      position: relative;
    }

    svg {
      width: 20px;
      height: 20px;
      transform: none;
      margin-right: 1.2rem;
      @include desktop-only {
        margin-right: 0;
      }

      &.chevron {
        width: 9px;
        height: 9px;
        transform: rotate(-90deg);
        margin-right: 0;
      }
    }
  }

  .login-btn-row .btn {
    margin-right: 2rem;
    @include desktop-only {
      margin-right: 0;
    }
  }

  .desktop-preferences {
    padding: 2.4rem 0;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    position: relative;
    @include desktop-only {
      margin: 0 2.8rem 0 1.2rem;
      padding: 0;
      border: none;
    }

    &:hover {
      > a {
        color: $orange;
      }
    }

    &-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    a {
      color: $black;
    }

    svg {
      cursor: pointer;
      transition: 0.3s all;
    }

    > a {
      > svg {
        width: 20px;
        height: 20px;
      }
    }

    .chevron {
      width: 12px;
      height: 12px;
    }

    .selected {
      color: $black;
      font-size: 1.4rem;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      span {
        &:first-child {
          font-size: 1.6rem;
          font-weight: 400;
        }

        &:last-child {
          margin-right: 2.8rem;
          font-weight: 400;
          color: $gray-10;
        }
      }

      a {
        position: relative;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.global-message-banner {
  padding: 1.1rem 0.6rem;
  background: $light-gray;
  color: $black;
  text-align: center;
  transition: all 0.3s;
  height: 100%;
  max-height: 5.8rem;

  &:deep(a) {
    color: $black;
    text-decoration: underline;
  }
}

.global-message-banner-text {
  font-size: 1.2rem;
  line-height: 1.5;
  max-height: 3.6rem; /* 2 rows of text */
  overflow: hidden;
}

.hide-via-max-height {
  max-height: 0 !important;
  overflow: hidden;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
