import { ref } from 'vue'
import { SortingDirection } from '@/Types/Shared'

export default function (
  initialSort: string | null = null,
  initialDirection = SortingDirection.asc,
) {
  const sort = ref('')
  const setSort = (property: string | number, direction: SortingDirection) => {
    sort.value = `${direction === SortingDirection.desc ? '-' : ''}${property}`
  }

  if (initialSort) setSort(initialSort, initialDirection)

  return { setSort, sort }
}
