<template>
  <h1 class="form-flow-heading">
    {{
      __('onboarding', 'newsletter.main-heading.text') +
      (!onboardingInfo!.first_name
        ? '!'
        : ', ' + onboardingInfo!.first_name + '!')
    }}
  </h1>
  <h5 class="form-flow-secondary-heading">
    {{ __('onboarding', 'newsletter.secondary-heading.text') }}
  </h5>
  <form
    action=""
    class="newsletter-form-container"
    @submit.prevent="handleSubmit"
  >
    <ReceiveEmailsToggle
      :error-message="getFieldError(errors, 'receive_emails')"
      size="large"
    >
      <ToggleSwitch id="receive-emails" v-model="receiveEmails" />
    </ReceiveEmailsToggle>
    <BaseButton
      class="continue-button"
      :is-loading="isSubmitting"
      type="submit"
      >{{ __('global', 'finish') }}</BaseButton
    >
  </form>
</template>

<script setup lang="ts">
import BaseButton from '@/Components/BaseButton.vue'
import { __ } from '@/Helpers/i18n'
import { onMounted, ref } from 'vue'
import ToggleSwitch from '@/Components/ToggleSwitch.vue'
import ReceiveEmailsToggle from '@/Components/ReceiveEmailsToggle.vue'
import { getFieldError } from '@/Helpers/Errors'
import {
  useOnboardingStore,
  useOnboardingStoreRefs,
} from '../Store/useOnboardingStore'

const receiveEmails = ref(false)
const onboardingStore = useOnboardingStore()
const { updateProfileNewsletter } = onboardingStore
const { isSubmitting, errors, onboardingInfo } = useOnboardingStoreRefs()

const handleSubmit = () => {
  updateProfileNewsletter(receiveEmails.value)
}

onMounted(() => {
  receiveEmails.value = onboardingInfo.value?.receive_emails || false
})
</script>

<style lang="scss" scoped>
.form-flow-heading {
  max-width: 650px;
  text-wrap: wrap;
  font-size: 32px;
  line-height: 38.4px;
  @media (min-width: 768px) {
    font-size: 44px;
    line-height: 57.2px;
  }
}

.form-flow-secondary-heading {
  margin-top: 32px;
}

.newsletter-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 440px;
  width: 100%;
  margin: auto;
  margin-top: 40px;
  @media (min-width: 1025px) {
    margin-top: 48px;
  }
}

.continue-button {
  margin-top: 48px;
}
</style>
