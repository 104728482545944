<template>
  <div v-if="allFiltersLoaded" class="products-catalog-filters">
    <div
      v-if="
        isDesktop ||
        (isMobile && dropdownOpen.length > 1) ||
        (isMobile && showFiltersPopup)
      "
      class="filter-dropdown-attribute"
      :class="{ 'mobile-popup': isMobile && showFiltersPopup }"
    >
      <div
        v-if="isMobile && showFiltersPopup"
        class="popup-title"
        role="button"
        tabindex="0"
      >
        {{ __('products', 'filters.mobile.dropdown.title.text') }}
        <SvgIcon
          class="svg-icon"
          name="exit-new"
          @click="closeFiltersPopup"
          @keydown.enter="closeFiltersPopup"
        />
      </div>
      <FilterSelect
        v-if="productCategories && productCategories.length"
        key="category"
        :active-filter="
          dropdownOpen.includes(FILTERS_TEXT.CATEGORY)
            ? FILTERS_TEXT.CATEGORY
            : ''
        "
        :filter-count="filtersCount"
        filter-type="category"
        :is-mobile="isMobile"
        :show-tax="showTax"
        :show-toggle="shouldShowToggle(FILTERS_TEXT.CATEGORY)"
        @toggle="toggleDropdown"
      >
        {{ __('products', 'catalog.filter-category-button.text') }}
        <template #filter-content>
          <CategoryDropdownFilter
            v-if="productCategories"
            v-click-away="isDesktop ? closeFiltersPopup : undefined"
            attribute-key="attribute_mob_"
            :base-url="baseUrl"
            :open-filter="dropdownOpen"
            @filter="filterCategories"
          />
        </template>
      </FilterSelect>

      <FilterSelect
        v-for="item in filteredAttributeGroups ?? []"
        :key="item.id"
        :active-filter="dropdownOpen.includes(item.name) ? item.name : ''"
        :filter-count="filtersCount"
        :filter-type="item.name"
        :is-mobile="isMobile"
        :open-filter="item.open"
        :show-tax="showTax"
        :show-toggle="shouldShowToggle(item.name)"
        @close="closeFiltersPopup"
        @toggle="toggleDropdown"
      >
        {{ item.name }}
        <template #filter-content>
          <TagDropdownFilter
            v-if="isAttributeType(item.id, 'tag')"
            v-click-away="isDesktop ? closeFiltersPopup : undefined"
            :item="item"
            @filter="filter"
          />
          <ProductConcernFilter
            v-if="isAttributeType(item.id, 'concern')"
            :key="item.id"
            v-click-away="isDesktop ? closeFiltersPopup : undefined"
            attribute-key="attribute_mob_"
            :filter="false"
            :item="item"
            :open-filter="dropdownOpen"
            @filter="filter"
          />
          <ProductDefaultFilter
            v-if="isAttributeType(item.id, 'default')"
            :key="item.id"
            v-click-away="isDesktop ? closeFiltersPopup : undefined"
            attribute-key="attribute_mob_"
            :filter="false"
            :item="item"
            :open-filter="dropdownOpen"
            @filter="filter"
          />
          <ColorDropdownFilter
            v-if="isAttributeType(item.id, 'color')"
            :key="item.id"
            v-click-away="isDesktop ? closeFiltersPopup : undefined"
            attribute-key="attribute_mob_"
            :base-url="baseUrl"
            :item="item"
            :open-filter="dropdownOpen"
            @filter="filter"
          />
        </template>
      </FilterSelect>
      <div
        v-if="isMobile && !loginStore?.profile?.vatVerified"
        class="vat"
        role="button"
        tabindex="0"
        @click="showTax = !showTax"
        @keydown.enter="showTax = !showTax"
      >
        <p>{{ __('global', 'VAT') }}</p>
        <Switch v-model="showTax" />
      </div>

      <button
        v-if="isMobile && showFiltersPopup"
        class="close-button"
        @click="clearFilters"
      >
        {{ __('products', 'filters.mobile.dropdown.button.text') }}
      </button>
    </div>

    <div class="products-filter-container">
      <div v-if="!loginStore?.profile?.vatVerified && isDesktop" class="vat">
        <p>{{ __('global', 'VAT') }}</p>
        <Switch v-model="showTax" />
      </div>
      <FilterSelect
        v-for="option in filteredFilterOptions"
        :key="option.type"
        :active-filter="dropdownOpen.includes(option.type) ? option.type : ''"
        :filter-count="filtersCount"
        :filter-type="option.type"
        :is-mobile="isMobile"
        :show-tax="showTax"
        :show-toggle="shouldShowToggle(option.type)"
        @toggle="toggleDropdown"
      >
        {{ option.label }}
        <div
          v-if="option.type === FILTERS_TEXT.FILTERS && filtersCount"
          class="filter-wrapper"
        >
          <span class="filter-count">{{ filtersCount }}</span>
        </div>

        <template #filter-content>
          <ProductsSortByDropdown
            v-if="option.type === FILTERS_TEXT.SORT"
            v-click-away="isDesktop ? closeFiltersPopup : undefined"
            :class="{ 'mobile-popup': isMobile && showFiltersPopup }"
            :filter-count="filtersCount"
            :set-sort="setSort"
            :sort="sort"
            @close="closeFiltersPopup"
          />
          <ProductsFiltersDropdown
            v-if="
              option.type === FILTERS_TEXT.FILTERS && filtersCount && isDesktop
            "
            v-click-away="isDesktop ? closeFiltersPopup : undefined"
            :attribute-groups-data="attributeGroupsData"
            :product-category-ids="productCatalogStore.filterCategoryIds.value"
            @category-filter="filterCategories"
            @clear="clearFilters"
            @filter="filter"
          />
        </template>
      </FilterSelect>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch, watchEffect } from 'vue'
import useScreenBreakpoints from '@/Composables/UseScreenBreakpoints'
import CategoryDropdownFilter from './CategoryDropdownFilter.vue'
import TagDropdownFilter from './TagDropdownFilter.vue'
import { __ } from '@/Helpers/i18n'
import { useLoginStore } from '@/Store/useLoginStore'
import { SCROLL_LOCK_CSS_CLASS, toggleBodyCssClass } from '@/Helpers/Global'
import Switch from '@/Partials/Switch.vue'
import { Modify, SortingDirection } from '@/Types/Shared'
import { ProductAttributeGroupResource } from '@/Types/AttributeGroup'
import { ProductAttributeResource } from '@/Types/ProductAttribute'
import { useQuery } from 'vue-query'
import { getAttributeFilterIds, getAttributeGroups } from '@/Api/Products'
import { analyticsProductFilter } from '@/Helpers/Analytics'
import { useRoute } from 'vue-router'
import ProductsSortByDropdown from './ProductsSortByDropdown.vue'
import ProductsFiltersDropdown from './ProductsFiltersDropdown.vue'
import ProductDefaultFilter from './ProductDefaultFilter.vue'
import FilterSelect from './FilterSelect.vue'
import ColorDropdownFilter from './ColorDropdownFilter.vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import { useProductsStore } from '@/Store/useProductsStore'
import useProductCatalogue from '@/Composables/useProductCatalogue'
import ProductConcernFilter from './ProductConcernFilter.vue'

defineProps<{
  baseUrl: string
  sort: string
  setSort: (property: string | number, direction: SortingDirection) => void
}>()

const emit = defineEmits<{
  filter: [ids: number[], categoryIds: number[]]
  reset: []
  'update-show-tax': [value: boolean]
}>()

const { isMobile, isDesktop } = useScreenBreakpoints()
const loginStore = useLoginStore()
const route = useRoute()

const FILTERS_TEXT = {
  FILTERS: 'filters',
  SORT: 'sort',
  CATEGORY: 'category',
}

const isFiltered = ref()

const attributeGroupsData = ref<
  Array<
    Modify<
      ProductAttributeGroupResource<'product_attributes'>,
      {
        product_attributes: Array<
          ProductAttributeResource & { active: boolean; icon_asset_url: string }
        >
      }
    > & { open: boolean }
  >
>([])

const attributeGroupIds = getAttributeFilterIds(
  'product-catalog.filter.attribute-group-ids',
)

const productsStore = useProductsStore()
const productCategories = computed(() => productsStore.productCategories)
const productCatalogStore = useProductCatalogue()

const showFiltersPopup = ref(false)

const closeFiltersPopup = () => {
  showFiltersPopup.value = false
  dropdownOpen.value = []
  toggleBodyCssClass(SCROLL_LOCK_CSS_CLASS, false)
}

const isAttributeType = (id: number, type: string): boolean =>
  attributeGroupIds.some(
    (attr: { id: number; type: string }) =>
      attr.id === id && attr.type === type,
  )

useQuery(
  'attribute-groups',
  async () => {
    attributeGroupsData.value = (await getAttributeGroups()).map((ag) => ({
      ...ag,
      open: false,
      product_attributes: ag.product_attributes.map((a) => ({
        ...a,
        active: false,
        icon_asset_url: a.icon_asset_url || '',
      })),
    }))
    setFromQuery()
    await filter()
  },
  { refetchOnWindowFocus: false },
)

const filtersCount = computed(() => {
  const categoryIds = productCatalogStore.filterCategoryIds.value
  const ids = productCatalogStore.filterIds.value

  return categoryIds.length + ids.length
})

const filter = async () => {
  if (!attributeGroupsData.value) return

  const activeAttributes = attributeGroupsData.value
    .flatMap((item) => item.product_attributes)
    .filter((attr) => attr.active)

  const ids = activeAttributes.map((i) => i.id)

  const queryParams: Record<string, string | undefined> = {}

  if (ids.length > 0) {
    queryParams.ids = ids.join(',')
  }

  emit('filter', ids, productCatalogStore.filterCategoryIds.value)
  analyticsProductFilter(activeAttributes.map((i) => i.name))
}

const filterCategories = async (categoryIds: number[] = []) => {
  emit('filter', productCatalogStore.filterIds.value, categoryIds)
}

const attributeGroupIdsArray = computed(() =>
  attributeGroupIds.map((attr) => attr.id),
)

const filteredAttributeGroups = computed(() =>
  attributeGroupsData.value.filter((group) =>
    attributeGroupIdsArray.value.includes(group.id),
  ),
)

const dropdownOpen = ref<string[]>([])

const toggleDropdown = (filterType: string) => {
  if (
    filterType === FILTERS_TEXT.FILTERS &&
    filtersCount.value === 0 &&
    !isMobile.value
  ) {
    return
  }

  const isFilterCurrentlyOpen = dropdownOpen.value.includes(filterType)

  if (!isMobile.value) {
    dropdownOpen.value = isFilterCurrentlyOpen ? [] : [filterType]
    attributeGroupsData.value.forEach(
      (group) => (group.open = dropdownOpen.value.includes(group.name)),
    )
    return
  }

  if (isFilterCurrentlyOpen) {
    if (
      filterType === FILTERS_TEXT.CATEGORY &&
      dropdownOpen.value.length === 1
    ) {
      return
    }
    dropdownOpen.value = dropdownOpen.value.filter(
      (openFilter) => openFilter !== filterType,
    )
  } else {
    dropdownOpen.value.push(filterType)
  }

  if (
    filterType === FILTERS_TEXT.FILTERS &&
    !dropdownOpen.value.includes(FILTERS_TEXT.CATEGORY) &&
    isMobile.value
  ) {
    dropdownOpen.value.push(FILTERS_TEXT.CATEGORY)
  }

  if (filterType === FILTERS_TEXT.FILTERS) {
    showFiltersPopup.value = true
    toggleBodyCssClass(SCROLL_LOCK_CSS_CLASS, true)
  } else if (dropdownOpen.value.length === 0) {
    toggleBodyCssClass(SCROLL_LOCK_CSS_CLASS, false)
  }

  attributeGroupsData.value.forEach(
    (group) => (group.open = dropdownOpen.value.includes(group.name)),
  )
}

const allFiltersLoaded = computed(
  () =>
    (productCategories.value?.length ?? 0) > 0 &&
    attributeGroupsData.value.length > 0 &&
    filteredFilterOptions.value.length > 0,
)

const clearFilters = async () => {
  if (!attributeGroupsData.value) return
  attributeGroupsData.value.forEach((item) => {
    if (item.product_attributes && item.product_attributes.length) {
      item.product_attributes.forEach((a) => (a.active = false))
    }
  })
  showFiltersPopup.value = false
  dropdownOpen.value = []
  toggleBodyCssClass(SCROLL_LOCK_CSS_CLASS, false)
  emit('reset')
  isFiltered.value = false
}

const showTax = ref(false)

watch(showTax, (newValue) => {
  emit('update-show-tax', newValue)
})

const setFromQuery = () => {
  if (!route.query.ids || !attributeGroupsData.value) {
    attributeGroupsData.value.forEach((group) => {
      group.product_attributes.forEach((attr) => {
        attr.active = false
      })
    })

    return
  }

  const queryData = route.query.ids.toString().split(',').map(Number)

  attributeGroupsData.value.forEach((group) => {
    group.product_attributes.forEach((attr) => {
      attr.active = queryData.includes(attr.id)
    })

    group.open = group.product_attributes.some((attr) => attr.active)
  })
}

onMounted(() => {
  setFromQuery()
})

watchEffect(() => {
  setFromQuery()
})

const filterOptions = [
  { type: FILTERS_TEXT.SORT, label: __('global', 'Sort by') },
  {
    type: FILTERS_TEXT.FILTERS,
    label: __('products', 'catalog.filter-group.button.text'),
  },
]

const filteredFilterOptions = computed(() =>
  filterOptions.filter((option) => {
    if (!isMobile.value) return true
    return !(
      option.type === FILTERS_TEXT.SORT &&
      dropdownOpen.value.includes(FILTERS_TEXT.FILTERS)
    )
  }),
)

const shouldShowToggle = (filterType: string): boolean =>
  isDesktop.value ||
  filterType === FILTERS_TEXT.SORT ||
  filterType === FILTERS_TEXT.FILTERS
</script>

<style lang="scss" scoped>
.products-catalog-filters {
  display: flex;
  flex-direction: row;
  background-color: rgba(241, 241, 244);
  border-radius: 16px;
  width: 100%;
  padding: 12px;

  @media (max-width: 991px) {
    padding: 8px;
    flex-direction: column-reverse;
    margin-top: 8px;
  }
}

.filter-dropdown-attribute {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  > *:last-child {
    width: 234px;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 6px;
    width: 100%;
  }
}

.products-filter-container {
  display: flex;
  margin-left: auto;
  gap: 8px;
  align-items: center;
  align-items: start;
  @media (max-width: 991px) {
    margin-left: inherit;
    width: 100%;
    align-items: center;
    gap: 8px;
    flex-direction: row;

    .filter-select:nth-child(1) {
      flex: 1;
    }

    .filter-select:nth-child(2) {
      flex: 3;
    }
  }
}

.filter-count {
  color: white;
  font-size: 10px;
  line-height: 16px;
  font-weight: 700;
}

.filter-wrapper {
  background-color: black;
  border-radius: 996px;
  width: 20px;
  height: 16px;
  display: flex;
  justify-content: center;
}

.vat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 0;

  @include desktop-only {
    margin-top: 8px;
    padding: 0;
    gap: 6px;
    margin-right: 14px;
    p {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.1px;
    }
  }

  @media (max-width: 991px) {
    padding: 11.5px 20px 14.5px 20px;
    background: rgb(255, 255, 255);
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 160%;
    font-weight: 500;
    border-bottom: 3px solid rgb(241, 241, 244);
  }
}

:deep(.switch .slider:before) {
  background-color: $white;
  width: 14px;
  height: 14px;
  bottom: 1px;
  left: 1.6px;
}

:deep(.switch .slider) {
  background-color: rgb(197, 199, 203);
  border-radius: 996px;
  transition: 0.4s;
  box-shadow: none !important;
  border: 1px solid transparent;
}

:deep(.switch input:checked + .slider:before) {
  transform: translateX(13px);
}

:deep(.switch input:checked + .slider) {
  background-color: rgb(41, 69, 56) !important;
}

.switch {
  width: 32px;
  height: 18px;
}

.filter-dropdown-attribute.mobile-popup {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: white;
  z-index: 5;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: calc(30px + env(safe-area-inset-bottom));
  flex-wrap: nowrap;
  transform: none;
  will-change: transform;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;

  :deep(.filter-select button) {
    padding: 11.5px 20px 14.5px 20px;
    border: none;
  }

  :deep(.filter-select .svg-icon) {
    margin-left: auto;
    width: 24px;
    height: 24px;
  }

  :deep(.filter-select .svg-icon.active) {
    width: 18px;
    height: 24px;
    margin-right: 3px;
  }

  :deep(.filter-select .button-content) {
    font-size: 14px;
    letter-spacing: -0.1px;
    line-height: 160%;
    font-weight: 500;
  }
}

.close-button {
  display: flex;
  justify-content: center;
  margin: 23px 20px 0 20px;
  padding: 13px 115.5px;
  background: rgb(246, 246, 247);
  color: rgb(12, 13, 18);
  border: none;
  line-height: 22.4px;
  font-size: 14px;
  width: auto !important;
  border-radius: 10px;
  cursor: pointer;
}

.popup-title {
  @media (max-width: 991px) {
    align-items: center;
    display: flex;
    padding: 29px 20px 9px 20px;
    background: rgb(255, 255, 255);
    font-size: 24px;
    letter-spacing: -0.4px;
    line-height: 160%;
    font-weight: 500;
  }
}

.svg-icon {
  width: 24px;
  height: 24px;
  margin-left: auto;
}
</style>
