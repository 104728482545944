<template>
  <label class="toggle-switch" :for="id">
    <input :id :checked="modelValue" type="checkbox" @change="updateSwitch" />
    <div class="slider"></div>
  </label>
</template>

<script lang="ts" setup>
defineProps<{
  modelValue: boolean
  id: string
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()

const updateSwitch = (event: Event) => {
  const target = event.target as HTMLInputElement
  emit('update:modelValue', target.checked)
}
</script>

<style scoped lang="scss">
.toggle-switch {
  --toggle-switch-width: 42px;
  --toggle-switch-height: 24px;

  display: inline-block;
  position: relative;
  height: var(--toggle-switch-height);
  width: var(--toggle-switch-width);
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.toggle-switch input {
  display: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

.slider {
  background-color: $gray-8;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  width: var(--toggle-switch-width);
  height: var(--toggle-switch-height);
  cursor: pointer;
  position: absolute;
  border-radius: 996px;
  transition: 0.2s;
}

.slider:before {
  position: absolute;
  bottom: 4px;
  left: 4px;
  content: '';
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #fff;
  transition: 0.2s;
}

input:checked + .slider {
  background-color: $checkbox-green;
}

input:checked + .slider:before {
  left: calc(var(--toggle-switch-width) - 20px);
}

.slider:after {
  content: '';
  position: absolute;
  top: 7.6px;
  left: 10px;
  width: 2.5px;
  height: 5.3px;
  border: solid $white;
  border-width: 0 1.8px 1.8px 0;
  transform: translate(0, 0) rotate(45deg); /* Initially hidden */
  transition: 0.2s;
  opacity: 0;
}

input:checked + .slider:after {
  transform: translateX(calc(var(--toggle-switch-width) - 24px)) rotate(45deg); /* Show the checkmark */
  border-color: $checkbox-green;
  opacity: 1;
}
</style>
