<template>
  <BaseBlock :data="data">
    <ul class="links-list">
      <li v-for="item in data.items" :key="item.title">
        <Link class="link-block" target-blank :url="item.url">
          <SvgIcon :name="item.icon" />
          <div>
            <h3 class="title">{{ item.title }}</h3>
            <p v-if="!showDescriptionText" class="description">
              {{ item.description }}
            </p>
          </div>
        </Link>
      </li>
    </ul>
  </BaseBlock>
</template>

<script setup lang="ts">
import { NavigationBlock } from '@/Partials/MainNavigation/navigationConfig'
import BaseBlock from '@/Partials/MainNavigation/BaseBlock.vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import Link from '@/Partials/Link.vue'
defineProps<{ data: NavigationBlock<'TEXTS_WITH_ICONS'> }>()

const showDescriptionText =
  import.meta.env.VITE_SHOW_NEW_LANDING_PAGES === 'true'
</script>

<style scoped lang="scss">
.links-list {
  display: flex;
  flex-direction: column;

  @include mobile-only {
    gap: 1.2rem;
  }
}

.link-block {
  color: $black;
  display: flex;
  gap: 1.2rem;
  align-items: v-bind('showDescriptionText ? "center" : "start"');
  @include border-radius(lg);

  svg {
    height: 2.8rem;
    width: 2.8rem;
    flex: 0 0 2.8rem;
  }

  @include mobile-only {
    margin: 0;
  }

  @include desktop-only {
    padding: 0.7rem 1.2rem;
    margin-left: -0.6rem;
    &:hover,
    &:focus {
      background: $gray-4;
    }
  }
}

.title {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.02em;
}

.description {
  color: $gray-7;
  font-size: 1.2rem;
  line-height: 1.6;
  margin-top: 0.8rem;
}
</style>
