<template>
  <h1 class="form-flow-heading">
    {{ __('onboarding', 'motivation.main-heading.text') }}
  </h1>
  <form
    action=""
    class="motivation-form-container"
    @submit.prevent="handleSubmit"
  >
    <div class="option-list">
      <label
        v-for="(option, index) in options"
        :key="index"
        class="option-item"
        :class="{ selected: selectedOption === option.value }"
        :for="option.value"
      >
        <input
          :id="option.value"
          v-model="selectedOption"
          class="radio-input"
          type="radio"
          :value="option.value"
        />
        <SvgIcon
          class="checkbox"
          :class="{ 'hide-checkbox': option.value !== selectedOption }"
          name="checkbox"
        />
        <span>{{ option.label }}</span>
      </label>
    </div>
    <div class="buttons-container">
      <BaseButton
        btn-style="btn-gray"
        :is-loading="isSkipping"
        type="button"
        @click.prevent="skipStep(OnboardingSteps.PRIMARY_MOTIVATION)"
        >{{ __('global', 'skip') }}</BaseButton
      >
      <BaseButton
        v-if="selectedOption"
        :disabled="!selectedOption"
        :is-loading="isSubmitting"
        type="submit"
        >{{ __('global', 'Continue') }}</BaseButton
      >
    </div>
  </form>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import {
  OnboardingPrimaryMotivation,
  OnboardingSteps,
} from '../Types/onboarding'
import SvgIcon from '@/Partials/SvgIcon.vue'
import BaseButton from '@/Components/BaseButton.vue'
import { __ } from '@/Helpers/i18n'
import {
  useOnboardingStore,
  useOnboardingStoreRefs,
} from '../Store/useOnboardingStore'

const {
  BUYING_FOR_MYSELF,
  PLAYING_AROUND,
  SELL_IN_PHYSICAL_LOCATIONS,
  SELL_ONLINE,
} = OnboardingPrimaryMotivation

const options = ref([
  {
    value: SELL_ONLINE,
    label: __('onboarding', 'motivation.sell-online-option.text'),
  },
  {
    value: SELL_IN_PHYSICAL_LOCATIONS,
    label: __(
      'onboarding',
      'motivation.sell-in-physical-locations-option.text',
    ),
  },
  {
    value: BUYING_FOR_MYSELF,
    label: __('onboarding', 'motivation.buy-for-myself-option.text'),
  },
  {
    value: PLAYING_AROUND,
    label: __('onboarding', 'motivation.playing-around-option.text'),
  },
])

const selectedOption = ref<null | OnboardingPrimaryMotivation>(null)
const onboardingStore = useOnboardingStore()
const { submitForm, skipStep } = onboardingStore
const { isSubmitting, onboardingInfo, isSkipping } = useOnboardingStoreRefs()

const handleSubmit = () => {
  selectedOption.value &&
    submitForm({
      current_step: OnboardingSteps.PRIMARY_MOTIVATION,
      primary_motivation: selectedOption.value,
    })
}

onMounted(() => {
  selectedOption.value = onboardingInfo.value?.primary_motivation || null
})
</script>

<style scoped lang="scss">
.motivation-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 32px;
  @media (min-width: 1025px) {
    margin-top: 40px;
  }
}

.option-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.option-item {
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  padding: 20px 24px;
  border: 1.8px solid transparent;
  border-radius: 16px;
  cursor: pointer;
  transition: 0.2s ease;
  transform: translateZ(0);
  user-select: none; /* Standard property */
  -webkit-user-select: none; /* For Safari and older browsers */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For Internet Explorer/Edge */
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16.8px;
  }

  .checkbox {
    position: absolute;
    left: 24px;
    color: $white;
    opacity: 1;
    width: 20px;
    height: 20px;
    transition: 0.2s ease;
  }

  .hide-checkbox {
    opacity: 0;
    left: 0px;
  }
}

@media (hover: hover) {
  .option-item:hover {
    background-color: $gray-6;
  }
}

.option-item.selected {
  background-color: $gray-6;
  border: 1.8px solid $black;
  padding-left: 56px;
  padding-right: 24px;
}

.radio-input {
  display: none;
}

.buttons-container {
  display: flex;
  gap: 6px;
  margin-top: 48px;
}
</style>
