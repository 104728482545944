<template>
  <div class="input-wrapper">
    <label
      class="input-container"
      :class="[
        size,
        {
          'input-error': !!errorMessage,
          'has-input': hasValue,
        },
      ]"
      :for="idName"
    >
      <span class="input-label">{{ label }}</span>
      <input
        :id="idName"
        :passwordrules="
          type === 'password'
            ? 'minlength: 8; required: lower; required: upper; required: digit; required: [-];'
            : ''
        "
        placeholder=" "
        :type="type"
        :value="modelValue"
        @input="updateValue"
      />
    </label>
    <span v-if="!!errorMessage" class="error-message">{{ errorMessage }}</span>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, ref, watch } from 'vue'
const props = withDefaults(
  defineProps<{
    idName: string
    type: string
    label: string
    size?: 'input-small' | 'input-medium' | 'input-large'
    errorMessage?: string
    modelValue: string | null | undefined
  }>(),
  { size: 'input-medium', errorMessage: '' },
)

const emit = defineEmits<{ 'update:modelValue': [value: string] }>()
const hasValue = ref(false)

const updateValue = (event: Event) => {
  const target = event.target as HTMLInputElement
  emit('update:modelValue', target.value)
}

watch(
  () => props.modelValue,
  (newValue) => {
    hasValue.value = !!newValue
  },
  { immediate: true },
)
</script>

<style lang="scss" scoped>
.input-small {
  --input-container-height: 48px;
}

.input-medium {
  --input-container-height: 60px;
}

.input-large {
  --input-container-height: 80px;
}

.input-wrapper {
  width: 100%;
}

.input-container {
  position: relative;
  display: flex;
  height: var(--input-container-height);
  background: $white;
  border-radius: 14px;
  border: 2px solid $light-gray !important;
  overflow: hidden;
  width: 100%;

  .input-label {
    font-size: 1.2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.6rem;
    right: 1.6rem;
    z-index: 1;
    transition: 0.3s all;
    pointer-events: none;
    color: $gray-1;
    font-weight: 500;
  }

  input {
    width: 100%;
    height: 100%;
    border: none !important;
    outline: none !important;
    font-size: 1.2rem;
    background-color: $white;
    border-radius: 14px;
    font-weight: 500;
    padding-top: calc(var(--input-container-height) * 0.35);
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
  }

  &:focus-within {
    border: 2px solid $black !important;

    input {
      height: 100%;
      width: 100%;
      border: none !important;
      outline: none !important;
      color: $gray-7;
    }

    .input-label {
      position: absolute;
      top: calc(var(--input-container-height) * 0.3);
      font-size: 1rem;
      color: $black;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    background-color: $white !important; /* Set your desired background */
    box-shadow: 0 0 0px 1000px $white inset !important; /* Force background */
  }
}

.has-input {
  input {
    color: $gray-7;
    border: none !important;
    outline: none !important;
  }
  .input-label {
    color: $gray-7;
    position: absolute;
    top: calc(var(--input-container-height) * 0.3);
    font-size: 1rem;
  }
}

.input-error {
  border: 2px solid $maroon !important;

  &:after {
    content: '';
    background-image: url('../../svg-sprites/input-error.svg');
    background-repeat: no-repeat;
    position: absolute;
    right: 1.6rem;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    z-index: 1;
  }
}

.error-message {
  font-weight: 500;
  font-size: 12px;
  color: $maroon;
  text-wrap: wrap;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 8px 10px 0 10px;
}
</style>
