import { MockupFileResource } from './Mockups'
import { CountryResource, CountryResourceExpandableFields } from './Country'
import {
  IngredientLayerResource,
  IngredientLayerResourceExpandableFields,
} from './IngredientLayer'
import { GalleryPhotoResource } from './GalleryPhoto'
import {
  ProductAttributeResource,
  ProductAttributeResourceExpandableFields,
} from './ProductAttribute'
import {
  ProductCategoryResource,
  ProductCategoryResourceExpandableFields,
} from './ProductCategory'
import { CertificationResource } from './Certification'
import labelsData from '../Widgets/DesignerTool/Data/labels.json'
import boxData from '../Widgets/DesignerTool/Data/boxes.json'
import {
  PrintDesignTemplateResource,
  PrintDesignTemplateResourceExpandableFields,
} from './PrintDesignTemplate'
import { ProductDescriptionResource } from './ProductDescription'
import { ProductTagResource } from './ProductTag'
import {
  CreateExpansionKeys,
  CreateExpansionMapping,
  InferSuffix,
} from '@/Types/Shared'

export type BulkDiscountThresholdResource = {
  id: number
  bulk_discount_id: number
  quantity: number
  discount_percent: string
}

export type LayoutCodeComboResource = {
  id: number
  label_layout_code: keyof typeof labelsData | null
  box_layout_code: keyof typeof boxData | null
}

// keep in sync with ProductTypeEnum.php
export enum ProductType {
  REGULAR = 'REGULAR',
  COLLECTION_BOX = 'COLLECTION_BOX',
  SAMPLE_SET = 'SAMPLE_SET',
  SERVICE = 'SERVICE',
}

export enum ExtendedProductType {
  REGULAR = 'REGULAR',
  COLLECTION_BOX = 'COLLECTION_BOX',
  SAMPLE_SET = 'SAMPLE_SET',
  SERVICE = 'SERVICE',
  PRO = 'PRO',
}

export enum ProductUnavailabilityStatusType {
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  COMING_SOON = 'COMING_SOON',
}

export type ProductResourceMainFields = {
  id: number
  title: string
  slug: string
  intro: string
  description: string
  ingredients: string
  code: string
  volume: number
  weight: number
  position: number | null
  gross_price: number | null
  net_price: number | null
  has_box: boolean
  tags: string[] | null
  type: ProductType
  meta_title: string | null
  meta_description: string | null
  unavailability_status: ProductUnavailabilityStatusType | null
  is_active: boolean
  inci: string | null
  usp: string | null
  production: string | null
  delivery: string | null
  price: number | null
  minimum_quantity: number | null
  is_on_sale: boolean
  is_new: boolean
  is_bestseller: boolean
  sale_discount: number | null
  sale_minimum_quantity: number | null
  natural_origin_total: number | null
  organic_origin_total: number | null
  aroma: string | null
  inci_title: string | null
  inci_ingredients_description: string | null
  is_pro: boolean
  unit_type: string | null
  us_title: string | null
  created_at: string

  category_id: number
  bulk_discount_id: number | null
  lead_time_id: number | null
}

export type ProductResourceExpansionKeys = CreateExpansionKeys<
  CreateExpansionMapping<
    keyof ProductResourceExpandableFields,
    {
      sample_set_products: keyof ProductResourceExpandableFields
      collection_box_products: keyof ProductResourceExpandableFields
      variant_products_count: keyof ProductResourceExpandableFields
      related_products: keyof ProductResourceExpandableFields
      ingredient_layers: keyof IngredientLayerResourceExpandableFields
      print_design_templates: keyof PrintDesignTemplateResourceExpandableFields
      product_attributes: keyof ProductAttributeResourceExpandableFields
      blacklisted_countries: keyof CountryResourceExpandableFields
      product_category: keyof ProductCategoryResourceExpandableFields
    }
  >
>

type ProductResourceExpandableFields<
  T extends ProductResourceExpansionKeys | never = never,
> = {
  sample_set_products: ProductResource<InferSuffix<'sample_set_products', T>>[]
  ingredient_layers: IngredientLayerResource<
    InferSuffix<'ingredient_layers', T>
  >[]
  print_design_templates: PrintDesignTemplateResource<
    InferSuffix<'print_design_templates', T>
  >[]
  product_attributes: ProductAttributeResource<
    InferSuffix<'product_attributes', T>
  >[]
  collection_box_products: ProductResource<
    InferSuffix<'collection_box_products', T>
  >[]
  related_products: ProductResource<InferSuffix<'related_products', T>>[]
  blacklisted_countries: CountryResource<
    InferSuffix<'blacklisted_countries', T>
  >[]
  product_category: ProductCategoryResource<InferSuffix<'product_category', T>>
  gallery_photos: GalleryPhotoResource[]
  inspiration_gallery_photos: GalleryPhotoResource[]
  layout_code_combo: LayoutCodeComboResource
  mockup_files: MockupFileResource[]
  certification: CertificationResource
  bulk_discount_thresholds: BulkDiscountThresholdResource[]
  product_descriptions: ProductDescriptionResource[]
  product_tags: ProductTagResource[]
  variant_products_count: number
}

export type ProductResource<
  T extends ProductResourceExpansionKeys | never = never,
> = ProductResourceMainFields & {
  [K in keyof ProductResourceExpandableFields]: K extends T
    ? ProductResourceExpandableFields<T>[K]
    : undefined
}

export type productFilterIds = { id: number; type: string; imageUrl: string }

export const ICON_NAMES = {
  HOVER_MINUS: 'status-hover-minus',
  HOVER_PLUS: 'status-hover-plus',
  STATUS_GREEN: 'status-icon-approved',
} as const

export const FILTER_TAGS_TYPES = {
  COMING_SOON: 'coming soon',
  DISCOUNT: 'discount',
  PRO: 'pro',
  NEW: 'new',
  BESTSELLER: 'bestseller',
} as const

export type IconAttribute = { id: number; active: boolean }

export const UNIT_TYPES = {
  WEIGHT: 'WEIGHT',
  VOLUME: 'VOLUME',
} as const
