import axios from 'axios'
import { SessionSetting } from '@/Types/SessionSetting'
import { useGlobalStore } from '@/Store/useGlobalStore'
import {
  ICON_NAMES,
  productFilterIds,
  ProductResource,
  ProductResourceExpansionKeys,
  ProductType,
  ProductUnavailabilityStatusType,
} from '@/Types/Product'
import ProductVM, { ProductVMType } from '@/ViewModels/ProductVM'
import { ProductAttributeGroupResource } from '@/Types/AttributeGroup'
import { ProductAttributeResource } from '@/Types/ProductAttribute'
import useLangStore from '@/Store/useLangStore'
import { redirectToError } from '@/Helpers/Redirect'
import { __ } from '@/Helpers/i18n'
import { FEATURE_FLAGS } from '@/Helpers/Global'

export type GetProductsParams = {
  categoryIds?: number | number[]
  per_page?: number | string
  idWhitelist?: number[]
  product_attribute_ids?: number[]
  page?: number
  category?: string | number
  hide_out_of_stock?: boolean
  types?: ProductType[]
  no_minimum_quantity?: boolean
  code?: string
  is_pro?: boolean
  sort?: string
  is_on_sale?: boolean
  coming_soon?: string
  is_new?: boolean
  is_bestseller?: boolean
}

export const getProducts = async <
  T extends ProductResourceExpansionKeys,
>(config?: {
  data?: GetProductsParams
  expandArray: readonly T[]
}) => {
  const globalStore = useGlobalStore()
  const langStore = useLangStore()

  const currentCountryId = globalStore.sessionSettings
    ? globalStore.sessionSettings.find((item) => item.id === 'CURRENT_COUNTRY')
        ?.value
    : null

  const countryObject = globalStore.countries
    ? globalStore.countries.find((c) => c.id === currentCountryId)
    : null

  const params: Record<string, unknown> = {
    'per-page':
      config?.data?.per_page ??
      (FEATURE_FLAGS.SHOW_NEW_PRODUCTS_CATALOG ? 20 : 18),
    'filter[is_active]': true,
  }

  if (config?.expandArray.length) {
    params['expand'] = config.expandArray.join(',')
  }

  if (countryObject) {
    params['filter[id][nin][]'] = countryObject.blacklisted_products.map(
      (p) => p.id,
    )
  }

  let idWhitelist = config?.data?.idWhitelist
  if (config?.data?.product_attribute_ids?.length) {
    const allowedIdsFromProductAttributes = (
      await getProductAttributes({ ids: config.data.product_attribute_ids })
    )
      .map((a) => a.products.map((p) => p.id))
      .flat()

    if (!idWhitelist) idWhitelist = allowedIdsFromProductAttributes
    else {
      idWhitelist = idWhitelist.filter((id) =>
        allowedIdsFromProductAttributes.includes(id),
      )
      if (!idWhitelist.length) return { data: [], page_count: 0, page: 1 }
    }
  }
  if (idWhitelist) params['filter[id][in][]'] = idWhitelist

  if (config?.data?.page) {
    params['page'] = config.data.page
  }
  if (config?.data?.category) {
    params['filter[category_id]'] = config?.data?.category
  }
  if (config?.data?.categoryIds) {
    params['filter[category_id][in]'] = config?.data?.categoryIds
  }

  if (config?.data?.hide_out_of_stock) {
    params['filter[unavailability_status]'] = 'NULL'
  }

  if (config?.data?.coming_soon) {
    params['filter[unavailability_status]'] = 'COMING_SOON'
  }

  if (config?.data?.is_new) {
    params['filter[is_new]'] = config.data.is_new
  }

  if (config?.data?.is_bestseller) {
    params['filter[is_bestseller]'] = config.data.is_bestseller
  }

  if (config?.data?.is_on_sale) {
    params['filter[is_on_sale]'] = config.data.is_on_sale
  }

  const types = config?.data?.types ?? [
    ProductType.REGULAR,
    ProductType.SAMPLE_SET,
    ProductType.COLLECTION_BOX,
  ]
  if (types.length === 1) {
    params['filter[type]'] = types[0]
  } else if (types.length > 1) {
    params['filter[type][in]'] = types
  }

  if (config?.data?.no_minimum_quantity) {
    params['filter[minimum_quantity]'] = 'NULL'
  }

  if (
    config?.data &&
    'is_pro' in config.data &&
    typeof config.data.is_pro === 'boolean'
  ) {
    params['filter[is_pro]'] = config.data.is_pro
  }
  if (config?.data?.code) {
    params['filter[code]'] = config.data.code
  }

  if (config?.data?.sort) {
    params['sort'] = config.data.sort
  }

  const response = await axios.get(`/${langStore.getLang()}/products`, {
    params,
  })
  return {
    data: (response.data as Array<ProductResource<T>>).map((product) =>
      ProductVM.createFrom(product),
    ),
    page_count: parseInt(response.headers['x-pagination-page-count'] as string),
    page: parseInt(response.headers['x-pagination-current-page'] as string),
  }
}

export const getProductById = async <T extends ProductResourceExpansionKeys>(
  id: number,
  config: {
    params?: Record<string, unknown>
    expandArray: readonly T[]
  },
) => {
  const lang = useLangStore().getLang()

  const response = await axios.get(`/${lang}/products/${id}`, {
    params: {
      ...config.params,
      expand: (config.expandArray ?? []).join(','),
    },
  })

  if (response.data) {
    return ProductVM.createFrom(response.data as ProductResource<T>)
  } else {
    redirectToError()
    return null
  }
}

export const getProductBySlug = async <T extends ProductResourceExpansionKeys>(
  slug?: string | null,
  expandArray: readonly T[] = [],
) => {
  const lang = useLangStore().getLang()

  const params: Record<string, unknown> = { 'filter[is_active]': true }
  if (slug) params['filter[slug]'] = slug
  if (expandArray) params.expand = expandArray.join(',')

  const response = await axios.get<ProductResource<T>[]>(`/${lang}/products`, {
    params,
  })

  if (!response.data?.length) {
    redirectToError()
    return null
  }

  return ProductVM.createFrom(response.data[0])
}

export const getAttributeGroups = async () => {
  const lang = useLangStore().getLang()

  const expandArray = ['product_attributes'] as const
  const expand = expandArray.join(',')

  const response = await axios.get(
    `/${lang}/product-attribute-groups?per-page=50&expand=${expand}`,
  )

  return response.data as ProductAttributeGroupResource<
    (typeof expandArray)[number]
  >[]
}

export const getProductAttributes = async (data: { ids?: number[] }) => {
  const lang = useLangStore().getLang()

  const expandArray = ['products'] as const
  const expand = expandArray.join(',')

  const params: Record<string, unknown> = {
    'per-page': 50,
    expand,
  }

  if (data?.ids?.length) {
    params['filter[id][in][]'] = data.ids
  }

  const response = await axios.get(`/${lang}/product-attributes`, {
    params,
  })

  return response.data as ProductAttributeResource<
    (typeof expandArray)[number]
  >[]
}

export const postWaitingListSubmission = async (
  id: number,
  email: string,
  recaptcha: string,
) => {
  const lang = useLangStore().getLang()

  return (
    await axios.post(`/${lang}/waiting-list-submissions`, {
      product_id: id,
      email,
      recaptcha,
    })
  ).data
}

export const getProductCategories = async () => {
  const lang = useLangStore().getLang()
  return (await axios.get(`/${lang}/product-categories`)).data
}

export const postNewsletter = async (data: {
  email: string
  recaptcha: string
  accept_newsletter: boolean
  accept_terms: boolean
}) =>
  (
    await axios.post(
      `/${useLangStore().getLang()}/pop-up-newsletter-submissions`,
      data,
    )
  ).data

export const postProSignup = async () =>
  await axios.post(`/${useLangStore().getLang()}/pro-user-submissions`)

export const isProductDisabled = (
  product: Pick<
    ProductVMType<'blacklisted_countries'>,
    | 'unavailability_status'
    | 'is_active'
    | 'blacklisted_countries'
    | 'lowestAvailablePrice'
  >,
) => {
  const globalStore = useGlobalStore()
  const country = globalStore.sessionSettings
    ? (globalStore.sessionSettings.find(
        (item: SessionSetting) => item.id === 'CURRENT_COUNTRY',
      )?.value as number | undefined)
    : null
  return (
    product.unavailability_status ||
    !product.is_active ||
    !product.lowestAvailablePrice ||
    (country &&
      product.blacklisted_countries.map((c) => c.id).includes(country))
  )
}

export const isProductDisabledExceptOutOfStock = (
  product: Pick<
    ProductVMType<'blacklisted_countries'>,
    | 'unavailability_status'
    | 'is_active'
    | 'blacklisted_countries'
    | 'lowestAvailablePrice'
  >,
) =>
  isProductDisabled(product) &&
  product.unavailability_status !== ProductUnavailabilityStatusType.OUT_OF_STOCK

export const isProductDisabledDueToBeingOutOfStock = (
  product: Pick<
    ProductVMType<'blacklisted_countries'>,
    | 'unavailability_status'
    | 'is_active'
    | 'blacklisted_countries'
    | 'lowestAvailablePrice'
  >,
) => {
  const globalStore = useGlobalStore()
  const country = globalStore.sessionSettings
    ? (globalStore.sessionSettings.find(
        (item: SessionSetting) => item.id === 'CURRENT_COUNTRY',
      )?.value as number | undefined)
    : null

  return (
    product.unavailability_status ===
      ProductUnavailabilityStatusType.OUT_OF_STOCK &&
    product.is_active &&
    product.lowestAvailablePrice &&
    !(
      country &&
      product.blacklisted_countries.map((c) => c.id).includes(country)
    )
  )
}

export const isProductDisabledDueToBeingComingSoon = (
  product: Pick<
    ProductVMType<'blacklisted_countries'>,
    | 'unavailability_status'
    | 'is_active'
    | 'blacklisted_countries'
    | 'lowestAvailablePrice'
  >,
) => {
  const globalStore = useGlobalStore()
  const country = globalStore.sessionSettings
    ? (globalStore.sessionSettings.find(
        (item: SessionSetting) => item.id === 'CURRENT_COUNTRY',
      )?.value as number | undefined)
    : null

  return (
    product.unavailability_status ===
      ProductUnavailabilityStatusType.COMING_SOON &&
    product.is_active &&
    product.lowestAvailablePrice &&
    !(
      country &&
      product.blacklisted_countries.map((c) => c.id).includes(country)
    )
  )
}

export const getAttributeFilterIds = (name: string): productFilterIds[] => {
  try {
    const parsedData: unknown = JSON.parse(__('config', name))
    if (!Array.isArray(parsedData)) {
      return []
    }
    return parsedData.filter(
      (item): item is productFilterIds =>
        typeof item === 'object' &&
        item !== null &&
        typeof (item as productFilterIds).id === 'number' &&
        typeof (item as productFilterIds).type === 'string',
    )
  } catch (error) {
    console.error(`Failed to parse attributeColorIds for ${name}:`, error)
    return []
  }
}

export const getIconName = (
  attribute: { id: number; active: boolean } & { active: boolean },
  hoveredItem: number | null,
  isDesktop: boolean,
) =>
  attribute.active && hoveredItem === attribute.id && isDesktop
    ? ICON_NAMES.HOVER_MINUS
    : hoveredItem === attribute.id && isDesktop
      ? ICON_NAMES.HOVER_PLUS
      : ICON_NAMES.STATUS_GREEN
