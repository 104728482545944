import { useWindowSize } from '@vueuse/core'
import { computed } from 'vue'
import { MIN_DESKTOP_PX } from '@/Helpers/Constants'

export default () => {
  const { width } = useWindowSize()
  const isDesktop = computed(() => width.value >= MIN_DESKTOP_PX)
  const isMobile = computed(() => !isDesktop.value)
  return { isDesktop, isMobile }
}
