<template>
  <a
    class="order-button"
    :class="{ sorted: sortedAsc || sortedDesc }"
    href="#"
    @click.prevent="order"
  >
    <span>{{ title }}</span>

    <span class="sort-icons">
      <SvgIcon
        class="asc-hover"
        :class="{ active: sortedAsc }"
        name="arrow-bold"
      />
      <SvgIcon
        class="desc-hover"
        :class="{ active: sortedDesc }"
        name="arrow-bold"
      />
    </span>

    <SvgIcon
      v-if="sortedAsc || sortedDesc"
      class="default-icon"
      :class="{
        asc: sortedAsc,
        desc: sortedDesc,
        active: sortedAsc || sortedDesc,
      }"
      name="arrow-bold"
    />
  </a>
</template>

<script setup lang="ts">
import SvgIcon from '../SvgIcon.vue'
import { computed } from 'vue'
import { SortingDirection } from '@/Types/Shared'

const props = defineProps<{
  title: string
  name: string
  sorted: string
}>()

const emit = defineEmits<{
  (e: 'order', name: string, order: SortingDirection): void
}>()

const sortedAsc = computed(() => props.sorted === props.name)
const sortedDesc = computed(() => props.sorted === `-${props.name}`)

const order = () => {
  emit(
    'order',
    props.name,
    sortedAsc.value ? SortingDirection.desc : SortingDirection.asc,
  )
}
</script>

<style lang="scss" scoped>
.order-button {
  display: inline-flex;
  align-items: center;
  color: $black;
  padding: 13px 16px;
  line-height: 22px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  position: relative;
  border-radius: 8px;

  @media (max-width: 991px) {
    padding: 13px 0 13px 0;
  }

  &:hover {
    @media (min-width: 992px) {
      background: rgba(246, 246, 247, 1);

      .sort-icons svg.active,
      .default-icon.active {
        background: rgba(41, 69, 56, 1);
        color: rgba(215, 233, 216, 1);
      }
    }
  }

  &.sorted {
    border-radius: 8px;
  }

  .sort-icons {
    display: flex;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;

    @media (max-width: 991px) {
      opacity: 1;
      position: static;
      transform: none;
      margin-left: auto;
    }
  }

  &:hover .sort-icons {
    opacity: 1;
  }

  .default-icon {
    display: block;
    margin-left: auto;
    opacity: 1;

    @media (max-width: 991px) {
      display: none;
    }
  }

  &:hover .default-icon {
    opacity: 0;
  }

  svg {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    margin-left: auto;
    transform: rotate(-90deg);
    background: transparent;
    color: $black;

    &.desc {
      transform: rotate(90deg);
    }

    &.asc-hover {
      transform: rotate(-90deg);
      @media (max-width: 991px) {
        margin-right: 20px;
      }
    }

    &.desc-hover {
      transform: rotate(90deg);
    }

    &.active {
      background: rgba(215, 233, 216, 1);
      color: rgba(41, 69, 56, 1);
    }
  }
}
</style>
