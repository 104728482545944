<template>
  <label class="switch" :class="{ disabled }">
    <input
      :checked="modelValue"
      type="checkbox"
      @change="emit('update:modelValue', !modelValue)"
    />
    <span class="slider"></span>
  </label>
</template>

<script setup lang="ts">
defineProps<{ modelValue: boolean; disabled?: boolean }>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void
}>()
</script>

<style lang="scss">
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;

  &.disabled {
    pointer-events: none;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      & + .slider {
        background-color: $dark-purple;

        &:before {
          transform: translateX(14px);
          background-color: $white;
        }
      }
    }

    &:focus {
      & + .slider {
        box-shadow: 0 0 1px $black;
      }
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $gray-6;
    transition: 0.4s;
    border-radius: 34px;
    border: 1px solid $light-gray;

    &:before {
      position: absolute;
      content: '';
      background-color: $black;
      transition: 0.4s;
      border-radius: 50%;
      height: 12px;
      width: 12px;
      left: 4px;
      bottom: 3px;
    }
  }
}
</style>
