<template>
  <div
    class="products-sort-by-filter"
    :class="{
      'mobile-fullscreen': isMobile,
      'filter-location': filterCount === 0,
    }"
    :style="isMobile ? { top: `${popupTop}px` } : {}"
  >
    <div v-if="isMobile" class="popup-header">
      <h2>{{ __('global', 'Sort by') }}</h2>
      <SvgIcon class="close-icon" name="exit-new" @click="$emit('close')" />
    </div>

    <SortButtonNew
      v-for="(sortButton, i) in sortButtons"
      :key="i"
      :name="sortButton.property"
      :sorted="sort"
      :title="sortButton.title"
      @order="setSort"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue'
import { __ } from '@/Helpers/i18n'
import { SortingDirection } from '@/Types/Shared'
import SortButtonNew from '@/Partials/Profile/SortButtonNew.vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import useScreenBreakpoints from '@/Composables/UseScreenBreakpoints'
import {
  getGlobalHeaderElementsHeights,
  SCROLL_LOCK_CSS_CLASS,
  toggleBodyCssClass,
} from '@/Helpers/Global'

defineProps<{
  filterCount?: number
  sort: string
  setSort: (property: string | number, direction: SortingDirection) => void
}>()

defineEmits<{
  (e: 'close'): void
}>()

const { isMobile } = useScreenBreakpoints()

const sortButtons = [
  {
    property: 'created_at',
    title: __('products', 'catalog.sort-by.date.text'),
  },
  { property: 'title', title: __('products', 'catalog.sort-by.name.text') },
  { property: 'price', title: __('products', 'catalog.sort-by.price.text') },
]

const popupTop = ref(0)
const calculatePopupTop = () => {
  const { headerHeight, bannerHeight } = getGlobalHeaderElementsHeights()
  popupTop.value = headerHeight + bannerHeight
}

onMounted(() => {
  if (isMobile.value) {
    toggleBodyCssClass(SCROLL_LOCK_CSS_CLASS, true)
  }
  calculatePopupTop()
  window.addEventListener('load', calculatePopupTop)
  setTimeout(calculatePopupTop, 100)
})

onUnmounted(() => {
  if (isMobile.value) {
    toggleBodyCssClass(SCROLL_LOCK_CSS_CLASS, false)
  }
})
</script>

<style lang="scss" scoped>
.products-sort-by-filter {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 248px;
  background: white;
  border: 2px solid rgba(241, 241, 244, 1);
  border-radius: 16px;
  z-index: 2;
  position: absolute;
  margin-top: 24px;
  @media (max-width: 991px) {
    z-index: 3;
    margin-top: 0;
    border: none;
    border-radius: 0;
  }

  &.mobile-fullscreen {
    position: fixed;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: white;
    z-index: 2;
    overflow-y: auto;
    padding: 20px;
  }
}

.products-sort-by-filter.filter-location {
  @media (min-width: 992px) {
    left: -140px;
  }
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    margin-bottom: 32px;
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.4px;
    line-height: 33.6px;
  }

  .close-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

::v-deep(.order-button svg) {
  margin-left: auto;
}
</style>
