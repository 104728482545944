<template>
  <h1 class="form-flow-heading">
    {{ __('onboarding', 'products-interested.main-heading.text') }}
  </h1>
  <form
    action=""
    class="products-interested-form-container"
    @submit.prevent="handleSubmit"
  >
    <div class="grid">
      <label
        v-for="option in products"
        :key="option.value"
        class="card"
        :class="{
          selected:
            lastSelectedProduct === option.value &&
            selectedProducts.includes(option.value),
        }"
        :for="option.value"
        :style="{
          backgroundColor: selectedProducts.includes(option.value)
            ? option.color
            : '#F6F6F7',
        }"
      >
        <input
          :id="option.value"
          class="radio-input"
          type="checkbox"
          :value="option.value"
          @change="handleSelect(option.value)"
        />
        <SvgIcon
          class="star"
          :class="{ 'star-active': selectedProducts.includes(option.value) }"
          name="star"
        />
        <div v-if="option" class="label">{{ option.label }}</div>
      </label>
    </div>
    <h5 class="form-flow-secondary-heading">
      {{ __('onboarding', 'products-interested.secondary-heading.text') }}
    </h5>
    <div class="buttons-container">
      <BaseButton
        btn-style="btn-gray"
        :is-loading="isSkipping"
        type="button"
        @click.prevent="skipStep(OnboardingSteps.PRODUCTS_INTERESTED)"
        >{{ __('global', 'skip') }}</BaseButton
      >
      <BaseButton
        v-if="selectedProducts.length !== 0"
        :is-loading="isSubmitting"
        type="submit"
      >
        {{ __('global', 'Continue') }}</BaseButton
      >
    </div>
  </form>
</template>

<script lang="ts" setup>
import BaseButton from '@/Components/BaseButton.vue'
import { __ } from '@/Helpers/i18n'
import { onMounted, ref } from 'vue'
import {
  OnboardingProductsInterested,
  OnboardingSteps,
} from '../Types/onboarding'
import SvgIcon from '@/Partials/SvgIcon.vue'
import {
  useOnboardingStore,
  useOnboardingStoreRefs,
} from '../Store/useOnboardingStore'

const selectedProducts = ref<OnboardingProductsInterested[]>([])
const lastSelectedProduct = ref<OnboardingProductsInterested | null>(null)
const onboardingStore = useOnboardingStore()
const { submitForm, skipStep } = onboardingStore
const { isSubmitting, onboardingInfo, isSkipping } = useOnboardingStoreRefs()

const {
  ALL,
  ANTI_AGEING,
  BODY_CARE,
  FACE_CARE,
  HAIR_CARE,
  MEN_SKINCARE,
  OTHER,
  SENSITIVE_SKIN,
  SUN_PROTECTION,
} = OnboardingProductsInterested

const colors = {
  LIGHT_BLUE: '#E6F0FE',
  GREEN: '#E3E8D2',
  ORANGE: '#FCDBCC',
  PURPLE: '#EBDCF9',
  YELLOW: '#F8EFC8',
  PINK: '#FAD5EA',
  BEIGE: '#EFE0D8',
  DARK_BLUE: '#DDDCF5',
  GREY: '#E2E4E8',
}

const products = [
  {
    value: ALL,
    label: __('onboarding', 'products-interested.all-products-option.text'),
    color: colors.LIGHT_BLUE,
  },
  {
    value: MEN_SKINCARE,
    label: __('onboarding', 'products-interested.men-skincare-option.text'),
    color: colors.GREEN,
  },
  {
    value: SENSITIVE_SKIN,
    label: __('onboarding', 'products-interested.sensitive-skin-option.text'),
    color: colors.ORANGE,
  },
  {
    value: ANTI_AGEING,
    label: __('onboarding', 'products-interested.anti-ageing-option.text'),
    color: colors.PURPLE,
  },
  {
    value: SUN_PROTECTION,
    label: __('onboarding', 'products-interested.sun-protection-option.text'),
    color: colors.YELLOW,
  },
  {
    value: FACE_CARE,
    label: __('onboarding', 'products-interested.face-care-option.text'),
    color: colors.PINK,
  },
  {
    value: BODY_CARE,
    label: __('onboarding', 'products-interested.body-care-option.text'),
    color: colors.BEIGE,
  },
  {
    value: HAIR_CARE,
    label: __('onboarding', 'products-interested.hair-care-option.text'),
    color: colors.DARK_BLUE,
  },
  {
    value: OTHER,
    label: __('onboarding', 'products-interested.other-option.text'),
    color: colors.GREY,
  },
]

const handleSelect = (productInterested: OnboardingProductsInterested) => {
  lastSelectedProduct.value = productInterested

  if (productInterested === ALL) {
    if (selectedProducts.value.includes(ALL)) {
      selectedProducts.value = []
    } else {
      selectedProducts.value = products.map((product) => product.value)
    }
  } else {
    if (selectedProducts.value.includes(productInterested)) {
      selectedProducts.value = selectedProducts.value.filter(
        (item) => item !== productInterested,
      )
    } else {
      selectedProducts.value.push(productInterested)
    }

    if (selectedProducts.value.includes(ALL)) {
      selectedProducts.value = selectedProducts.value.filter(
        (item) => item !== ALL,
      )
    }
  }
}

const handleSubmit = () => {
  submitForm({
    current_step: OnboardingSteps.PRODUCTS_INTERESTED,
    products_interested: selectedProducts.value,
  })
}

onMounted(() => {
  selectedProducts.value = onboardingInfo.value?.products_interested || []
})
</script>

<style scoped lang="scss">
.products-interested-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 32px;
  @media (min-width: 1025px) {
    margin-top: 40px;
  }
}

.grid {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, minmax(160px, 1fr));
  grid-auto-rows: minmax(104px, auto);
  justify-content: center;
  align-items: center;

  @media (max-width: 767px) {
    .card:last-child:nth-child(odd) {
      grid-column: span 2;
    }
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(184px, 1fr));
  }
}

.card {
  width: 100%;
  height: 100%;
  padding: 4px;
  position: relative;
  overflow: hidden;
  display: flex;
  border: 3px solid transparent;
  outline: transparent;
  border-radius: 16px;
  text-align: center;
  background: $gray-6;
  cursor: pointer;
  transition: 0.2s ease;
  transform: translateZ(0);
  user-select: none; /* Standard property */
  -webkit-user-select: none; /* For Safari and older browsers */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For Internet Explorer/Edge */
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}

@media (hover: hover) {
  .card:hover {
    outline: 1.8px solid $gray-8;
    border: 3px solid $white;
  }
}

.selected {
  outline: 1.8px solid $black !important;
}

.label {
  position: absolute;
  z-index: 2;
  bottom: 8px;
  left: 0;
  right: 0;
  margin: 0 12px;
  font-weight: 500;
  font-size: 12px;
  overflow: hidden;
  text-align: left;
  border-radius: 12px;
  padding: 12px 8px;
}

.radio-input {
  display: none;
}

.star {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 20px;
  height: 20px;
  fill: $gray-9;
  transition: 0.2s ease;
  &-active {
    fill: $black;
  }
}

.form-flow-secondary-heading {
  font-size: 10px;
  letter-spacing: 0.1px;
  line-height: 10px;
  margin-top: 32px;
  color: $gray-1;
}

.buttons-container {
  display: flex;
  gap: 6px;
  margin-top: 48px;
}
</style>
