<template>
  <div class="filter-dropdown-item">
    <div class="filter-dropdown" :class="filterDropdownClass">
      <div class="children" :class="childrenClass">
        <div
          v-if="isAttributeType(item.id, 'type')"
          :aria-checked="allActive"
          class="input-checkbox"
          :class="{ active: allActive }"
          role="checkbox"
          tabindex="0"
          @blur="hoveredItem = null"
          @click="activateAllTypes"
          @focus="hoveredItem = -1"
          @keydown.enter.prevent="activateAllTypes"
          @mouseenter="hoveredItem = -1"
          @mouseleave="hoveredItem = null"
        >
          <div class="wrap">
            <div class="icon-wrapper">
              <SvgIcon
                class="check-icon"
                :class="{
                  active: allActive,
                  hovered: allActive && hoveredItem === -1 && isDesktop,
                }"
                :name="getIcon({ id: -1, active: allActive })"
              />
            </div>
            <span class="label-text">{{
              __('products', 'filters.type.all-types.text')
            }}</span>
          </div>
        </div>

        <div
          v-for="attribute in item.product_attributes"
          :key="attribute.id"
          class="content-wrapper"
        >
          <div
            :aria-checked="attribute.active"
            class="input-checkbox"
            :class="{
              active: attribute.active,
              hovered: attribute.active && hoveredItem === attribute.id,
            }"
            role="checkbox"
            tabindex="0"
            @blur="hoveredItem = null"
            @click="toggleCheckbox(attribute)"
            @focus="hoveredItem = attribute.id"
            @keydown.enter.prevent="toggleCheckbox(attribute)"
            @mouseenter="hoveredItem = attribute.id"
            @mouseleave="hoveredItem = null"
          >
            <div class="wrap">
              <div class="icon-wrapper">
                <SvgIcon
                  class="check-icon"
                  :class="{
                    active: attribute.active,
                    hovered:
                      attribute.active &&
                      hoveredItem === attribute.id &&
                      isDesktop,
                  }"
                  :name="getIcon(attribute)"
                />
              </div>
              <label
                :id="`label-${attribute.id}`"
                class="label-text"
                :for="`checkbox-${attribute.id}`"
              >
                <input
                  :id="`checkbox-${attribute.id}`"
                  v-model="attribute.active"
                  type="checkbox"
                  @change="emit('filter')"
                  @click="toggleCheckbox(attribute)"
                />
                {{ attribute.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import { ProductAttributeGroupResource } from '@/Types/AttributeGroup'
import { Modify } from '@/Types/Shared'
import { ProductAttributeResource } from '@/Types/ProductAttribute'
import useScreenBreakpoints from '@/Composables/UseScreenBreakpoints'
import { __ } from '@/Helpers/i18n'
import { getAttributeFilterIds, getIconName } from '@/Api/Products'
import { IconAttribute } from '@/Types/Product'

const props = defineProps<{
  item: Modify<
    ProductAttributeGroupResource<'product_attributes'>,
    {
      product_attributes: Array<ProductAttributeResource & { active: boolean }>
    }
  > & { open: boolean }
  attributeKey: string
  filter: boolean
}>()

const emit = defineEmits<{
  (e: 'filter'): void
}>()

const hoveredItem = ref<number | null>(null)
const { isDesktop } = useScreenBreakpoints()

const isSmallDropdown = computed(() => props.item.product_attributes.length < 7)
const filterDropdownClass = computed(() => ({
  'small-dropdown': isSmallDropdown.value && isDesktop.value,
}))
const childrenClass = computed(() => ({
  'single-line': isSmallDropdown.value,
}))

const attributeGroupIds = getAttributeFilterIds(
  'product-catalog.filter.tag-type-id',
)

const isAttributeType = (id: number, type: string): boolean =>
  attributeGroupIds.some(
    (attr: { id: number; type: string }) =>
      attr.id === id && attr.type === type,
  )

const getIcon = (attribute: IconAttribute) =>
  getIconName(attribute, hoveredItem.value, isDesktop.value)

const toggleCheckbox = (
  attribute: ProductAttributeResource & { active: boolean },
) => {
  attribute.active = !attribute.active
  emit('filter')
}

const allActive = computed(() =>
  props.item.product_attributes.every((attr) => attr.active),
)

const activateAllTypes = () => {
  !allActive.value
    ? props.item.product_attributes.forEach((attr) => (attr.active = true))
    : props.item.product_attributes.forEach((attr) => (attr.active = false))
  emit('filter')
}
</script>

<style scoped lang="scss">
.filter-dropdown-item {
  width: 100%;

  .children {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 2px;
    @media (min-width: 992px) {
      max-height: 364px;
      overflow-y: auto;
    }
    @media (max-width: 991px) {
      display: flex;
      flex-direction: column;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(246, 246, 247, 1);
      border-radius: 1px;
      margin-bottom: 12px;
      margin-top: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(197, 199, 203, 1);
      border-radius: 1px;
    }
  }

  .children.single-line {
    display: flex;
    flex-direction: column;
  }

  .content-wrapper {
    @media (min-width: 992px) {
      align-content: center;
    }
  }

  .filter-dropdown {
    padding: 12px;
    background: #fff;
    border-radius: 16px;
    width: 484px;
    position: absolute;
    margin-top: 24px;
    border: 2px solid rgb(241, 241, 244);
    z-index: 3;
    @media (max-width: 991px) {
      position: relative;
      margin-top: -3px;
      padding: 0 12px 12px 12px;
      border: none;
      border-radius: 0;
      width: 100%;
    }
  }
  .filter-dropdown.small-dropdown {
    width: 242px;
  }

  .input-checkbox {
    display: flex;
    align-items: center;
    padding: 13px 0 13px 16px;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;
    position: relative;
    transition: background 0.3s ease-in-out;

    @media (max-width: 991px) {
      padding: 15px 8px;
    }

    &:focus {
      outline: transparent;
    }

    label {
      margin-left: 0;
    }
  }

  .input-checkbox::before {
    content: '';
    position: absolute;
    inset: 0;
    background: transparent;
    z-index: 1;
  }

  .icon-wrapper {
    z-index: 2;
  }

  .input-checkbox:hover {
    @media (min-width: 992px) {
      background: rgba(246, 246, 247, 1);
      border-radius: 8px;
    }
  }

  .wrap {
    gap: 12px;
    @media (max-width: 991px) {
      flex-direction: row-reverse;
      width: 100%;
    }
  }

  input {
    display: none;
  }

  .check-icon {
    width: 20px;
    height: 20px;
    pointer-events: none;
    border-radius: 6px;
    margin-left: auto;
    transition:
      0.3s ease-in-out,
      color 0.3s ease-in-out;
    color: rgba(241, 241, 244, 1);
    background-color: rgba(241, 241, 244, 1);
  }

  .check-icon.active {
    color: rgba(41, 69, 56, 1);
    background-color: rgba(215, 233, 216, 1);
  }

  .check-icon.active.hovered {
    color: rgba(215, 233, 216, 1);
    background-color: rgba(41, 69, 56, 1);
  }

  .label-text {
    font-size: 14px;
    line-height: 160%;
    font-weight: 400;
    letter-spacing: -0.1px;
    margin-right: auto;
  }
}
</style>
