<template>
  <div
    class="filter-select"
    :class="{
      'is-open': isArrowDisabled,
      'hide-filter': filterType === 'filters' && filterCount === 0 && !isMobile,
    }"
  >
    <button
      :class="{
        active: isActive,
        'is-arrow-disabled': isArrowDisabled,
        'filter-padding': filterType === 'filters',
      }"
      @click="$emit('toggle', filterType)"
    >
      <span class="button-content"><slot></slot></span>
      <SvgIcon
        class="svg-icon"
        :class="{ active: isActive }"
        :name="iconName"
      />
    </button>

    <div v-if="isActive">
      <slot name="filter-content"></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, computed } from 'vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import useScreenBreakpoints from '@/Composables/UseScreenBreakpoints'

const props = defineProps<{
  filterType: string
  activeFilter: string | null
  openFilter?: boolean
  showToggle: boolean
  filterCount?: number
}>()

defineEmits<{
  (e: 'toggle', value: string): void
}>()

const { isMobile } = useScreenBreakpoints()
const isActive = computed(() => props.activeFilter?.includes(props.filterType))

const isArrowDisabled = computed(
  () => !(props.filterType === 'sort' || props.filterType === 'filters'),
)

const iconName = computed(() => {
  if (props.filterType === 'sort') return 'sort-asc'
  if (props.filterType === 'filters') return 'list-simple'
  if (isMobile.value && !props.activeFilter) {
    return 'plus'
  }
  if (props.activeFilter && isMobile.value) {
    return 'minus'
  }

  return 'chevron'
})
</script>

<style lang="scss" scoped>
.filter-select {
  position: relative;
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}
.filter-select.is-open {
  @media (max-width: 991px) {
    &:not(:first-child) {
      border-bottom: 4px solid rgb(241, 241, 244);
    }
  }
}

button {
  display: flex;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  gap: 8px;
  padding: 6px 14px;
  border: 2px solid transparent;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  white-space: nowrap;
  &:focus {
    outline: transparent;
  }

  &:hover {
    @media (min-width: 992px) {
      border: 2px solid rgba(216, 217, 223, 1);
    }
  }

  @media (max-width: 991px) {
    padding: 14px 0;
    min-width: 116px;
    width: 100%;
  }
}

.hide-filter {
  display: none;
}

button.active {
  @media (min-width: 992px) {
    border: 2px solid black;
    padding: 6px 14px;
  }
  @media (max-width: 991px) {
    border: none;
  }
}

.svg-icon {
  width: 12px;
  height: 8px;
}

button:not(.is-arrow-disabled) {
  flex-direction: row-reverse;
  justify-content: center;
  border-radius: 8px;
  .svg-icon {
    width: 16px;
    height: 16px;
  }
}
.filter-padding {
  @media (max-width: 991px) {
    padding: 14px 0;
    min-width: 150px;
    width: 100%;
  }
}

.button-content {
  display: flex;
  gap: 8px;
  align-items: center;
}
.filter-content {
  position: relative;
  display: inline-block;
}
</style>
