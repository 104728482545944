<template>
  <div
    class="product-images"
    role="button"
    tabindex="0"
    @blur="stopAnimation"
    @click="emit('view-product', product, linkToProduct)"
    @focus="changeImages"
    @keydown.enter="emit('view-product', product, linkToProduct)"
    @mouseenter="changeImages"
    @mouseleave="stopAnimation"
  >
    <ProductBadge :product="product" />
    <div class="image-shadow-overlay"></div>

    <template v-for="item in allImages" :key="item.image">
      <OptimizedImg
        v-if="item.image"
        :alt="item.description ?? product.title"
        :class="{ visible: item.image === activeImage }"
        :size="{ height: 386, width: 290 }"
        :src="item.image"
      />
    </template>

    <div v-if="showActionButtons">
      <div class="action-button-wrapper">
        <div
          class="start-designing-button"
          role="button"
          tabindex="0"
          @click.stop="handleButtonClick"
          @keydown.enter="handleButtonClick"
          @keydown.space.prevent="handleButtonClick"
        >
          <SvgIcon class="button-icon" :name="buttonIcon" />
          {{ buttonLabel }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import ProductBadge from './ProductBadge.vue'
import OptimizedImg from '@/Partials/ui/OptimizedImg.vue'
import { GalleryPhotoResource } from '@/Types/GalleryPhoto'
import { __ } from '@/Helpers/i18n'
import useProductActions from '@/Composables/useProductActions'
import { ProductVMType } from '@/ViewModels/ProductVM'
import { useLoginStore } from '@/Store/useLoginStore'

const props = defineProps<{
  product: Omit<
    ProductVMType<
      | 'gallery_photos'
      | 'inspiration_gallery_photos'
      | 'blacklisted_countries'
      | 'product_attributes'
      | 'variant_products_count'
    >,
    'collection_box_products' | 'sample_set_products'
  >
  showActionButtons?: boolean
  linkToProduct: string
  activeImage?: string
}>()

const emit = defineEmits<{
  (e: 'join-waiting-list', product: typeof props.product): void
  (e: 'add-to-cart', product: typeof props.product): void
  (
    e: 'view-product',
    product: typeof props.product,
    linkToProduct: string,
  ): void
}>()

const { handleStartDesigningClick } = useProductActions()

const animationImages = ref<string[]>([])
const activeImage = ref(props.activeImage ?? props.product.image)
const animationInterval = ref()
const counter = ref(1)
const loginStore = useLoginStore()

const starredPhotos = computed(() => {
  const starred: GalleryPhotoResource[] = []
  props.product.gallery_photos?.forEach(
    (item) => item.is_starred && starred.push(item),
  )
  props.product.inspiration_gallery_photos?.forEach(
    (item) => item.is_starred && starred.push(item),
  )
  setAnimationImages(starred)
  return starred
})

const setAnimationImages = (starredPhotos: GalleryPhotoResource[]) => {
  animationImages.value = [
    props.product.image,
    ...starredPhotos.map((item) => item.image),
  ]
}

const allImages = computed(() => [
  { image: props.product.image, description: props.product.title },
  ...starredPhotos.value.map(({ image, description }) => ({
    image,
    description,
  })),
])

watch(allImages, (images) => {
  animationImages.value = images.map((item) => item.image)
})

const stopAnimation = () => {
  clearInterval(animationInterval.value)
  counter.value = 0
  activeImage.value = props.product.image
}

watch(
  () => props.activeImage,
  (newImage) => {
    if (newImage) activeImage.value = newImage
  },
)

const changeImages = () => {
  if (starredPhotos.value.length > 1) {
    executeAnimation()
    animationInterval.value = setInterval(executeAnimation, 750)
  } else if (starredPhotos.value.length === 1) {
    activeImage.value = animationImages.value[1]
  }
}

const executeAnimation = () => {
  if (!animationImages.value[counter.value]) counter.value = 0
  activeImage.value = animationImages.value[counter.value]
  counter.value++
}

const PRODUCT_STATES = {
  WAITING_LIST: 'WAITING_LIST',
  SAMPLE_SET: 'SAMPLE_SET',
  CREATE_DESIGN: 'CREATE_DESIGN',
}

const productState = computed(() => {
  if (props.product.unavailability_status) return PRODUCT_STATES.WAITING_LIST
  if (props.product.is_sample_set) return PRODUCT_STATES.SAMPLE_SET
  return PRODUCT_STATES.CREATE_DESIGN
})

const buttonConfig = computed(() => {
  if (
    productState.value === PRODUCT_STATES.WAITING_LIST ||
    (props.product.is_pro && !loginStore.profile?.is_pro)
  ) {
    return {
      label: __('products', 'Join waiting list'),
      icon: 'plus-circle',
      action: () => emit('join-waiting-list', props.product),
    }
  }

  if (productState.value === PRODUCT_STATES.SAMPLE_SET) {
    return {
      label: __('global', 'Add to cart'),
      icon: 'cart',
      action: () => emit('add-to-cart', props.product),
    }
  }

  return {
    label: __('global', 'Create design'),
    icon: 'brush',
    action: () => handleStartDesigningClick(props.product),
  }
})

const buttonLabel = computed(() => buttonConfig.value.label)
const buttonIcon = computed(() => buttonConfig.value.icon)
const handleButtonClick = () => {
  if (!loginStore.userLoggedIn) {
    emit('view-product', props.product, props.linkToProduct)
  }
  buttonConfig.value.action()
}
</script>

<style scoped lang="scss">
.product-images {
  position: relative;
  padding-top: 133%;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;

  img {
    transition: 0.3s all;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    border-radius: 6px;
    @media (max-width: 991px) {
      border-radius: 4px;
    }

    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &:hover {
    @media (min-width: 992px) {
      .image-shadow-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0) 20%,
          rgba(0, 0, 0, 0) 70%,
          rgba(0, 0, 0, 0.2) 100%
        );
        z-index: 1;
        border-radius: 6px;
      }
    }
  }

  .button-icon {
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }

  .action-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    z-index: 2;
    @media (max-width: 768px) {
      z-index: 1;
    }
  }
  .start-designing-button {
    display: none;
  }

  &:hover {
    .start-designing-button {
      @include desktop-only {
        display: inline-flex;
        justify-content: center;
        padding: 12px 0;
        width: 100%;
        background-color: #ffffff;
        border-radius: 8px;
        align-items: center;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
        line-height: 22.4px;
        letter-spacing: -0.1px;
      }
    }
  }
}
</style>
