export enum ColorMode {
  light = 'light',
  dark = 'dark',
}

export enum SortingDirection {
  asc = 'asc',
  desc = 'desc',
}

export type DropshippingActionButton = {
  title: string
  icon: string
  clickHandler: (...args: any) => void
}

export type NullableOptional<T> = {
  [K in keyof T]?: T[K] | null
}

export type Modify<T, R> = Omit<T, keyof R> & R

export type MakePartialKeysRequired<T, K extends keyof T> = Omit<T, K> &
  Required<Pick<T, K>>

export type InferSuffix<
  T extends string,
  U extends string,
> = U extends `${T}.${infer S}` ? S : never

export type CreateExpansionKeys<T extends Record<string, string>> = {
  [K in keyof T]: (string & K) | `${string & K}.${T[K]}`
}[keyof T]

export type CreateExpansionMapping<
  T extends string,
  U extends Record<string, unknown>,
> = Modify<Record<T, never>, U>
