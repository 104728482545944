import { ref } from 'vue'

const loading = ref(false)

const useLoading = () => {
  const setLoading = (value: boolean) => {
    loading.value = value
  }

  return {
    loading,
    setLoading,
  }
}

export default useLoading
