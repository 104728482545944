<template>
  <div v-if="data" class="cart-totals">
    <div class="item">
      <p>{{ __('cart', 'Subtotal') }}</p>
      <!-- <p>{{ floatToCurrency(data.orderTotal) }}</p> -->
      <p>{{ floatToCurrency(data.subtotal) }}</p>
    </div>
    <div v-if="!hideDeliveryCost && data.country_code" class="item">
      <p>{{ __('cart', 'Delivery Cost') }}</p>
      <p v-if="data.shipping_price">
        {{ floatToCurrency(data.shipping_price) }}
      </p>
      <p v-else class="small">
        {{ __('cart', 'Shipping calculated at checkout') }}
      </p>
    </div>
    <div class="total">
      <div
        v-if="data.discount > 0"
        class="item"
        :class="{ 'item-borderless': !!data.user_discount }"
      >
        <p>{{ __('cart', 'cart.totals.product-discount') }}</p>
        <p>-{{ floatToCurrency(data.discount) }}</p>
      </div>
      <div v-if="!!data.user_discount" class="item">
        <p>
          {{ __('cart', 'cart.totals.user-discount') }}
          <span v-if="userCatalogDiscount">{{ userCatalogDiscount }}%</span>
        </p>
        <p>-{{ floatToCurrency(data.user_discount) }}</p>
      </div>
      <div v-if="data.first_order_discount" class="item">
        <p>{{ __('cart', 'cart.totals.first-order-discount') }}</p>
        <p>-{{ floatToCurrency(data.first_order_discount) }}</p>
      </div>
      <div class="vat-amount">
        <p>{{ __('cart', 'VAT') }}({{ data.tax_rate }})</p>
        <p>{{ floatToCurrency(Number(data.tax ?? data.total_tax)) }}</p>
      </div>
      <div class="total-wrap">
        <p class="total-text">{{ __('global', 'Total') }}</p>
        <p class="price">
          <span class="total-text">{{ floatToCurrency(data.total) }}</span>
          <span class="vat"
            >{{ __('cart', 'VAT included') }} ({{ data.tax_rate }})</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getOrderFromLocal } from '@/Helpers/Checkout'
import { DISCOUNT_KEYS } from '@/Helpers/Constants'
import { floatToCurrency } from '@/Helpers/Global'
import { __ } from '@/Helpers/i18n'
import { useCartStore } from '@/Store/useCartStore'
import { useLoginStore } from '@/Store/useLoginStore'
import { computed, ref, watch } from 'vue'

withDefaults(defineProps<{ hideDeliveryCost?: boolean }>(), {
  hideDeliveryCost: false,
})
const cartStore = useCartStore()
const loginStore = useLoginStore()

const cart = computed(() => cartStore.cart)

const userCatalogDiscount = computed(() => {
  const userDiscount =
    loginStore.profile?.user_discounts?.[DISCOUNT_KEYS.SELFNAMED]

  return userDiscount ? Number(userDiscount) * 100 : null
})

const data = ref()

const initializeData = async () => (await getOrderFromLocal()) ?? cart.value

watch(
  cart,
  async () => {
    data.value = await initializeData()
  },
  { immediate: true },
)
</script>

<style lang="scss">
.cart-totals {
  padding: 2rem 3.2rem 4rem 3.2rem;
  border-bottom: 1px solid $light-gray;

  .item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0.8rem 0;

    &:not(.item-borderless) {
      margin-bottom: 1rem;
      border-bottom: 1px solid $light-gray;
    }

    > p {
      &:first-child {
        padding-right: 2rem;
        flex: none;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .total {
    .vat-amount {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 0.4rem;
    }

    .total-wrap {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .vat {
      font-size: 1.2rem;
      color: $gray-10;
    }
  }

  .small {
    font-size: 1.2rem;
  }

  .total-text {
    font-size: 1.8rem;
  }
}
</style>
