<template>
  <FormFlow
    v-if="!emailAccepted"
    :image-section-alt="__('auth', 'account-registration.image-section.alt')"
    :image-section-src="__('auth', 'account-registration.image-section.url')"
  >
    <template #form>
      <h2 class="form-flow-heading">
        {{ __('auth', 'account-registration.main-heading.text') }}
      </h2>
      <form
        v-if="signUpInputs"
        action=""
        class="account-registration-form"
        @submit.prevent="signUpUser"
      >
        <BaseInput
          v-for="item in signUpInputs"
          :key="item.name"
          v-model="item.value"
          :error-message="getFieldError(registrationErrors, item.name)"
          :id-name="item.name"
          :label="item.label"
          :type="item.type"
        />
        <ReceiveEmailsToggle
          :error-message="getFieldError(registrationErrors, 'receive_emails')"
        >
          <ToggleSwitch id="receive-emails" v-model="receiveEmails" />
        </ReceiveEmailsToggle>
        <BaseButton class="submit-button" :is-loading="loading" type="submit">
          {{ __('forms', 'sign-up-button.text') }}</BaseButton
        >
      </form>
    </template>
    <template #alternative-options>
      <div class="alternative-option">
        <span>{{
          __('auth', 'account-registration.agree-to-receive-emails.text')
        }}</span>
        <a :href="pageLinks ? pageLinks.value.terms_url : ''" target="_blank">{{
          __('auth', 'account-registration.terms-conditions-link.text')
        }}</a>
        <span> {{ __('global', 'and') }} </span>
        <a
          :href="pageLinks ? pageLinks.value.privacy_policy_url : ''"
          target="_blank"
          >{{ __('auth', 'account-registration.privacy-policy-link.text') }}</a
        >
      </div>
    </template>
  </FormFlow>
  <CheckEmail
    v-else
    :receive-emails="receiveEmails"
    :registration-email="registrationEmail"
    @change-email="handleEmailVal"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { getSignUpInputs, Input } from '@/Helpers/Forms'
import { __ } from '@/Helpers/i18n'
import { analyticsSignUp } from '@/Helpers/Analytics'
import { postRegistration } from '@/Api/Registration'
import BaseInput from '@/Components/BaseInput.vue'
import BaseButton from '@/Components/BaseButton.vue'
import FormFlow from '@/Components/FormFlow.vue'
import CheckEmail from './CheckEmail.vue'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { PageLinkSetting } from '@/Types/Setting'
import { getFieldError } from '@/Helpers/Errors'
import ToggleSwitch from '@/Components/ToggleSwitch.vue'
import ReceiveEmailsToggle from '@/Components/ReceiveEmailsToggle.vue'

const globalStore = useGlobalStore()

const signUpInputs = ref<Input[]>(getSignUpInputs())
const registrationErrors = ref()
const loading = ref(false)

const emailAccepted = ref(false)
const registrationEmail = ref('')
const receiveEmails = ref(true)

const handleEmailVal = () => {
  emailAccepted.value = !emailAccepted.value
  registrationEmail.value = ''
}

const pageLinks = computed(
  () =>
    globalStore.getSettingByKey('pagelinksetting') as
      | PageLinkSetting
      | undefined,
)

const signUpUser = async () => {
  loading.value = true
  registrationErrors.value = null
  const data: { [key: string]: any } = {}
  signUpInputs.value.forEach((item) => {
    data[item.name] = item.value
    if (item.type === 'email') {
      registrationEmail.value = item.value
    }
  })
  data.terms_accepted = true
  data.receive_emails = receiveEmails.value
  await postRegistration(data).catch((error: any) => {
    registrationErrors.value = error.response.data
  })

  loading.value = false
  if (registrationErrors.value) return
  emailAccepted.value = true
  analyticsSignUp(data)
}
</script>

<style lang="scss" scoped>
.form-flow-secondary-heading {
  max-width: 445px;
  text-align: center;
}

.account-registration-form {
  @include centered-flex-column;
  margin-top: 32px;
  width: 100%;
  max-width: 320px;
  @media (min-width: 768px) {
    margin-top: 40px;
  }

  .submit-button {
    margin-top: 48px;
  }
}
</style>
