<template>
  <div class="preferences-dropdown-desktop">
    <div class="top">
      <p class="title">{{ __('header', 'Preferences') }}</p>
      <button class="close" @click.prevent="() => emit('close')">
        <SvgIcon name="exit" />
      </button>
    </div>
    <template v-if="globalStore.countries">
      <div class="input-element">
        <label for="country">{{ __('global', 'Country') }}</label>
        <v-select
          id="country"
          v-model="country"
          :clearable="false"
          label="title"
          :options="globalStore.countries"
          :reduce="selectReduce"
          @option:selected="updateCurrency"
        >
          <template #open-indicator>
            <SvgIcon class="open-chevron" name="chevron" />
          </template>
          <template #option="{ code, title }">
            <p class="country-option">
              <svg>
                <use
                  :xlink:href="`/images/flags.svg#sprite-${code.toLowerCase()}`"
                />
              </svg>
              <span>{{ title }}</span>
            </p>
          </template>
          <template #selected-option="{ code, title }">
            <p class="country-option">
              <svg>
                <use
                  :xlink:href="`/images/flags.svg#sprite-${code.toLowerCase()}`"
                />
              </svg>
              <span>{{ title }}</span>
            </p>
          </template>
        </v-select>
      </div>
      <div class="input-element">
        <label for="currency">{{ __('global', 'Currency') }}</label>
        <v-select
          id="currency"
          v-model="currency"
          :clearable="false"
          label="name"
          :options="currencies"
          :reduce="selectReduce"
        >
          <template #open-indicator>
            <SvgIcon class="open-chevron" name="chevron" />
          </template>
        </v-select>
      </div>
    </template>
    <div class="payment-methods">
      <p>{{ __('header', 'Accepted payment methods') }}</p>
      <PaymentMethodsIcons />
    </div>
    <div v-if="globalStore.locales" class="input-element">
      <label for="locale">{{ __('header', 'Language') }}</label>
      <v-select
        id="locale"
        v-model="locale"
        label="name"
        :options="globalStore.locales"
        :reduce="addressSelectReduce"
      >
        <template #open-indicator>
          <SvgIcon class="open-chevron" name="chevron" />
        </template>
      </v-select>
    </div>
    <button
      class="btn btn-primary btn-update-preferences"
      :class="{ loading: loading }"
      @click.prevent="update"
    >
      <span>{{ __('header', 'Update preferences') }}</span>
    </button>
  </div>
</template>

<script setup lang="ts">
import PaymentMethodsIcons from '@/Partials/PaymentMethodsIcons.vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { __ } from '@/Helpers/i18n'
import { addressSelectReduce, getCurrentSessionValue } from '@/Helpers/Global'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { CountryResource, CountryResourceMainFields } from '@/Types/Country'
import { CurrencyResource } from '@/Types/Currency'
import useLangStore from '@/Store/useLangStore'

const emit = defineEmits<{ close: [] }>()

const globalStore = useGlobalStore()
const langStore = useLangStore()
const route = useRoute()

const country = ref(getCurrentSessionValue('CURRENT_COUNTRY').id)
const currency = ref(getCurrentSessionValue('CURRENT_COUNTRY').currency.id)
const locale = ref(langStore.getLang())
const currencies = ref([getCurrentSessionValue('CURRENT_COUNTRY').currency])

const loading = ref(false)
const update = async () => {
  loading.value = true

  try {
    await globalStore.putSessionSetting({
      id: 'CURRENT_COUNTRY',
      value: country.value,
    })

    localStorage.setItem('locationPopupCountry', country.value)
    window.location.href =
      langStore.getLang() === locale.value ? route.fullPath : `/${locale.value}`
  } catch (e) {
    console.error(e)
  } finally {
    loading.value = false
  }
}

const selectReduce = (item: CurrencyResource | CountryResourceMainFields) =>
  item.id

const updateCurrency = (item: CountryResource<'currency'>) => {
  currencies.value = [item.currency]
  currency.value = item.currency.id
}
</script>

<style lang="scss" scoped>
.preferences-dropdown-desktop {
  width: 100%;
  margin-top: 1.2rem;
  @include desktop-only {
    position: absolute;
    left: 50%;
    transform: translateX(-72%);
    top: 3.4rem;
    z-index: 11;
    width: 302px;
    border: 1px solid $light-gray;
    background: $white;
    padding: 2rem 2.4rem;
    margin-top: 0;
    @include border-radius(xs);
  }
}

.top {
  display: none;
  @include desktop-only {
    display: flex;
    margin-bottom: 1.8rem;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.title {
  font-weight: 500;
  font-size: 1.6rem;
}

.close {
  cursor: pointer;
  color: $black;

  svg {
    width: 13px;
    height: 13px;
  }
}

.input-element {
  position: relative;
  margin-bottom: 1.2rem;

  label {
    position: absolute;
    left: 1.6rem;
    top: 4px;
    font-size: 1rem;
    color: $gray-10;
    z-index: 1;
  }
}

.btn-update-preferences {
  width: 100%;
  margin-top: 0.8rem;
}

.country-option {
  display: flex;
  align-items: center;

  svg {
    width: 15px;
    height: 14px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    margin-right: 0.8rem;
    flex: none;
  }
}

.payment-methods {
  padding: 0 1.4rem 2rem 1.4rem;
  text-align: center;

  p {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }
}
</style>
