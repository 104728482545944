<template>
  <div
    :aria-checked="isSelected ? 'true' : 'false'"
    class="card"
    :class="{ selected: isSelected }"
    role="radio"
    tabindex="0"
    @click="selectCard"
    @keydown.enter="selectCard"
  >
    <template v-if="option">
      <OptimizedImg
        :alt="option.label"
        :size="{ height: 188, width: 144 }"
        :src="option.image"
      />
      <div class="label">{{ option.label }}</div>
    </template>
    <SvgIcon
      class="checkbox"
      :class="{ 'checkbox-active': isSelected }"
      name="checkbox"
    />
  </div>
</template>

<script setup lang="ts">
import SvgIcon from '@/Partials/SvgIcon.vue'
import OptimizedImg from '@/Partials/ui/OptimizedImg.vue'
import { defineProps, defineEmits } from 'vue'

interface Option {
  value: string
  label: string
  image: string
}

const props = defineProps<{
  option?: Option
  isSelected?: boolean
}>()

const emit = defineEmits<{
  (e: 'select', value: string): void
}>()

const selectCard = () => {
  if (props.option) {
    emit('select', props.option.value)
  }
}
</script>

<style scoped lang="scss">
.card {
  width: 144px;
  height: 188px;
  position: relative;
  overflow: hidden;
  display: flex;
  outline: 1.8px solid transparent;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease;
  user-select: none; /* Disable text selection */
  -webkit-user-select: none; /* For WebKit browsers */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For IE */
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  transform: translateZ(0);
  img {
    transition: 0.2s ease;
    border-radius: 20px;
    height: 100%;
    width: 100%;
  }
}

@media (hover: hover) {
  .card:hover {
    outline: 1.8px solid $gray-8;
    img {
      padding: 4px;
    }
  }

  .card.selected:hover {
    outline: 1.8px solid $black;
    img {
      padding: 4px;
    }
  }
}

.selected {
  outline: 1.8px solid $black;
  img {
    padding: 4px;
  }
}

.image {
  width: 100%;
  z-index: 1;
  border-radius: 20px;
  object-fit: cover;
  -webkit-user-drag: none; /* Disable dragging in WebKit browsers */
  pointer-events: none; /* Prevent interaction (optional, use only if needed) */
}

.label {
  position: absolute;
  z-index: 2;
  bottom: 8px;
  left: 0;
  right: 0;
  margin: 0 8px;
  font-weight: 500;
  font-size: 12px;
  overflow: hidden;
  text-align: left;
  background: #fff;
  border-radius: 12px;
  padding: 12px 16px;
  line-height: 16.8px;
  letter-spacing: -0.3px;
}

.checkbox {
  position: absolute;
  opacity: 0;
  top: 20px;
  right: 20px;
  width: 20px;
  height: 20px;
  z-index: 2;
  color: $white;
  transition: 0.2s ease;
}

.checkbox-active {
  opacity: 1;
}
</style>
