<template>
  <template v-if="!isPageLoading">
    <h1 class="form-flow-heading">
      {{ __('onboarding', 'success.main-heading.text') }}
    </h1>
    <h5 class="form-flow-secondary-heading">
      {{ __('onboarding', 'success.secondary-heading.text') }}
    </h5>
    <form
      action=""
      class="success-form-container"
      @submit.prevent="handleSubmit"
    >
      <div class="product-grid">
        <ProductCard
          v-for="product in products?.data"
          :key="product.id"
          :is-last-selected-product="
            isLastSelectedProduct === product.id &&
            selectedProductIds.includes(product.id)
          "
          :is-selected="selectedProductIds.includes(product.id)"
          :product="
            product as ProductVMType<'gallery_photos' | 'sample_set_products'>
          "
          @handle-select="handleSelect"
        />
      </div>
      <BaseButton
        class="continue-button"
        :is-loading="isSubmitting"
        type="submit"
        >{{ buttonText }}
        <template #icon-right>
          <span v-if="selectedProductCount" class="icon-right">{{
            selectedProductCount
          }}</span></template
        ></BaseButton
      >
    </form>
  </template>
</template>

<script lang="ts" setup>
import BaseButton from '@/Components/BaseButton.vue'
import { __ } from '@/Helpers/i18n'
import { computed, onMounted, ref } from 'vue'
import { getProducts } from '@/Api/Products'
import { ProductType } from '@/Types/Product'
import { ProductVMType } from '@/ViewModels/ProductVM'
import ProductCard from './ProductCard.vue'
import { useRouter } from 'vue-router'
import useLangStore from '@/Store/useLangStore'
import useProductActions from '@/Composables/useProductActions'

const products = ref<{
  data: ProductVMType<'gallery_photos' | 'sample_set_products'>[]
  page_count: number
} | null>(null)

const selectedProductIds = ref<number[]>([])
const isLastSelectedProduct = ref<number | null>(null)
const isSubmitting = ref(false)
const isPageLoading = ref(true)

const router = useRouter()
const { addToCart } = useProductActions()

const selectedProductCount = computed(() =>
  selectedProductIds.value.length > 0 ? selectedProductIds.value.length : null,
)

const handleSelect = (productId: number) => {
  isLastSelectedProduct.value = productId

  if (selectedProductIds.value.includes(productId)) {
    selectedProductIds.value = selectedProductIds.value.filter(
      (id) => id !== productId,
    )
  } else {
    selectedProductIds.value = [...selectedProductIds.value, productId]
  }
}

const handleSubmit = () => {
  isSubmitting.value = true
  if (selectedProductIds.value.length === 0) {
    redirectToProfile()
  } else {
    redirectToCart()
  }
  isSubmitting.value = false
}

const buttonText = computed(() => {
  if (selectedProductIds.value.length === 0) {
    return __('global', 'skip')
  } else {
    return __('global', 'Add to cart')
  }
})

const redirectToCart = () => {
  selectedProductIds.value.forEach(async (id: number) => {
    const product = products.value?.data.find((product) => product.id === id)
    if (product) {
      await addToCart(product, { is_sample: 1 })
    }
  })
  router.push(`/${useLangStore().getLang()}/cart`)
}

const redirectToProfile = () => {
  router.push(`/${useLangStore().getLang()}/profile/dashboard`)
}

onMounted(async () => {
  const sampleProducts = await getProducts({
    data: {
      per_page: 4,
      hide_out_of_stock: true,
      types: [ProductType.SAMPLE_SET],
    },
    expandArray: ['gallery_photos', 'sample_set_products'],
  })
  products.value = sampleProducts
  isPageLoading.value = false
})
</script>

<style scoped lang="scss">
.success-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 40px;
  @media (min-width: 768px) {
    margin-top: 48px;
  }
}

.form-flow-heading {
  font-size: 32px;
  @media (min-width: 768px) {
    font-size: 44px;
  }
}

.form-flow-secondary-heading {
  margin-top: 30px;
}

.product-grid {
  display: grid;
  grid-gap: 16px;
  margin-left: 96px;
  margin-right: 96px;
  grid-template-columns: repeat(4, minmax(170px, 170px));
  @media (max-width: 1350px) {
    grid-template-columns: repeat(2, minmax(150px, 170px));
    margin-left: 24px;
    margin-right: 24px;
  }
}

.continue-button {
  margin-top: 48px;
  @media (min-width: 768px) {
    margin-top: 64px;
  }
}

.icon-right {
  background-color: $gray-7;
  height: 16px;
  width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-left: 8px; /* Adjust spacing as needed */
  font-size: 10px;
}
</style>
