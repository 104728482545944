<template>
  <h1 class="form-flow-heading">
    {{ __('onboarding', 'referrals.main-heading.text') }}
  </h1>
  <form
    action=""
    class="referrals-interested-form-container"
    @submit.prevent="handleSubmit"
  >
    <div class="grid">
      <label
        v-for="option in referrals"
        :key="option.value"
        class="card"
        :class="{ selected: selectedReferral === option.value }"
        :for="option.value"
      >
        <input
          :id="option.value"
          v-model="selectedReferral"
          class="radio-input"
          type="radio"
          :value="option.value"
        />
        <div class="referral-social-container">
          <div class="referral-icon">
            <SvgIcon :name="option.icon" />
          </div>
          <div class="referral-details">
            <div class="label">{{ option.label }}</div>
            <div class="subtitle">{{ option.subtitle }}</div>
          </div>
        </div>
        <div
          class="checkbox"
          :class="{ 'checkbox-active': option.value === selectedReferral }"
        >
          <SvgIcon name="checkbox" />
        </div>
      </label>
    </div>

    <div class="buttons-container">
      <BaseButton
        btn-style="btn-gray"
        :is-loading="isSkipping"
        type="button"
        @click.prevent="skipStep(OnboardingSteps.REFERRAL)"
        >{{ __('global', 'skip') }}</BaseButton
      >
      <BaseButton
        v-if="selectedReferral"
        :is-loading="isSubmitting"
        type="submit"
        >{{ __('global', 'Continue') }}</BaseButton
      >
    </div>
  </form>
</template>

<script lang="ts" setup>
import BaseButton from '@/Components/BaseButton.vue'
import { __ } from '@/Helpers/i18n'
import { onMounted, ref } from 'vue'
import { OnboardingReferral, OnboardingSteps } from '../Types/onboarding'
import SvgIcon from '@/Partials/SvgIcon.vue'
import {
  useOnboardingStore,
  useOnboardingStoreRefs,
} from '../Store/useOnboardingStore'

const selectedReferral = ref<OnboardingReferral | null>(null)
const onboardingStore = useOnboardingStore()
const { submitForm, skipStep } = onboardingStore
const { isSubmitting, onboardingInfo, isSkipping } = useOnboardingStoreRefs()

const {
  YOUTUBE,
  INSTAGRAM,
  PARTNERS,
  OTHER_PEOPLE,
  FACEBOOK,
  TIKTOK,
  SEARCH_ENGINES,
  OTHERS,
} = OnboardingReferral

const referrals = [
  {
    value: YOUTUBE,
    icon: 'youtube-referral',
    label: __('onboarding', 'referrals.youtube-option.text'),
    subtitle: '',
  },
  {
    value: FACEBOOK,
    icon: 'facebook-referral',
    label: __('onboarding', 'referrals.facebook-option.text'),
    subtitle: '',
  },
  {
    value: INSTAGRAM,
    icon: 'instagram-referral',
    label: __('onboarding', 'referrals.instagram-option.text'),
    subtitle: '',
  },
  {
    value: TIKTOK,
    icon: 'tiktok',
    label: __('onboarding', 'referrals.tiktok-option.text'),
    subtitle: '',
  },
  {
    value: PARTNERS,
    icon: 'shopify',
    label: __('onboarding', 'referrals.partners-option.text'),
    subtitle: __('onboarding', 'referrals.partners-option.subtitle.text'),
  },
  {
    value: SEARCH_ENGINES,
    icon: 'google-referral',
    label: __('onboarding', 'referrals.search-engines-option.text'),
    subtitle: __('onboarding', 'referrals.search-engines-option.subtitle.text'),
  },
  {
    value: OTHER_PEOPLE,
    icon: 'other-people',
    label: __('onboarding', 'referrals.other-people-option.text'),
    subtitle: __('onboarding', 'referrals.other-people-option.subtitle.text'),
  },
  {
    value: OTHERS,
    icon: 'other',
    label: __('onboarding', 'referrals.others-option.text'),
    subtitle: '',
  },
]

const handleSubmit = async () => {
  selectedReferral.value &&
    submitForm({
      current_step: OnboardingSteps.REFERRAL,
      referral: selectedReferral.value,
    })
}

onMounted(() => {
  selectedReferral.value = onboardingInfo.value?.referral || null
})
</script>

<style scoped lang="scss">
.referrals-interested-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 32px;
  @media (min-width: 1025px) {
    margin-top: 40px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(280px, 350px));
  grid-auto-rows: 80px;
  gap: 8px;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 280px);
  }
}

.card {
  width: 100%;
  height: 100%;
  padding: 24px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 3px solid transparent;
  outline: transparent;
  border-radius: 16px;
  background: $gray-6;
  cursor: pointer;
  transition: 0.2s ease;
  transform: translateZ(0);
  user-select: none; /* Disable text selection */
  -webkit-user-select: none; /* For WebKit browsers */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For IE */
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  .checkbox {
    color: $white;
    width: 20px;
    height: 20px;
    opacity: 0;
    transition: 0.2s ease;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .checkbox-active {
    opacity: 1;
  }
}

@media (hover: hover) {
  .card:hover {
    outline: 1.8px solid $gray-8;
    border: 3px solid $white;
  }
}

.referral-social-container {
  display: flex;
  align-items: center;
  gap: 12px;

  .referral-details {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 500;
    .subtitle {
      color: $gray-3;
    }
  }
}

.referral-icon {
  height: 32px;
  width: 32px;
  svg {
    height: 32px;
    width: 32px;
  }
}

.selected {
  outline: 1.8px solid $black !important;
}

.radio-input {
  display: none;
}

.star {
  position: absolute;
  top: 24px;
  left: 24px;
  width: 24px;
  height: 24px;
  display: flex;
}

.buttons-container {
  display: flex;
  gap: 6px;
  margin-top: 48px;
}
</style>
