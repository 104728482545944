<template>
  <li>
    <template v-if="data.type === 'CATALOGUE'">
      <router-link :to="productsUrl" @click="closeMenu">
        <Badge
          border-radius-size="full"
          class="badge"
          padding-size="md"
          :variant="
            'variant' in data.titleBadge ? data.titleBadge.variant : 'darkgray'
          "
        >
          {{ data.titleBadge.text }} <Arrow class="arrow" />
        </Badge>
      </router-link>
    </template>
    <template v-else>
      <Badge
        border-radius-size="full"
        class="badge"
        padding-size="md"
        :variant="
          'variant' in data.titleBadge ? data.titleBadge.variant : 'darkgray'
        "
      >
        {{ data.titleBadge.text }}
      </Badge>
    </template>
    <div class="content"><slot></slot></div>
  </li>
</template>

<script setup lang="ts">
import Badge from '@/Partials/Badge.vue'
import { NavigationBlock } from '@/Partials/MainNavigation/navigationConfig'
import { computed } from 'vue'
import { useGlobalStore } from '@/Store/useGlobalStore'
import Arrow from '@/Partials/Arrow.vue'
import useLayoutStore from '@/Store/useLayoutStore'

defineProps<{ data: NavigationBlock }>()
const globalStore = useGlobalStore()
const layoutStore = useLayoutStore()
const productsUrl = computed(() => globalStore.productsUrl as string)

const closeMenu = () => {
  layoutStore.mobileMenuOpen = false
}
</script>

<style scoped lang="scss">
.content {
  margin-top: 2.6rem;
  margin-left: 0.6rem;

  @include desktop-only {
    margin-top: 1.2rem;
    margin-left: 0;
  }
}

.arrow {
  font-size: 12px;
}
</style>
