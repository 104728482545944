<template>
  <h1 class="form-flow-heading">
    {{
      __('onboarding', 'brand.main-heading.text') +
      (!onboardingInfo!.first_name
        ? '!'
        : ', ' + onboardingInfo!.first_name + '!')
    }}
  </h1>
  <form action="" class="brand-form-container" @submit.prevent="handleSubmit">
    <BaseInputGroup>
      <BaseInput
        v-model="brandName"
        :error-message="getFieldError(errors, 'brand_name')"
        id-name="brand_name"
        :label="__('onboarding', 'brand.brand-name-input.text')"
        type="text"
      />
      <BaseInput
        v-model="publicUrl"
        :error-message="getFieldError(errors, 'public_url')"
        id-name="public_url"
        :label="__('onboarding', 'brand.public-url-input.text')"
        type="text"
      />
    </BaseInputGroup>
    <div class="buttons-container">
      <BaseButton :is-loading="isSubmitting" type="submit">
        {{ __('global', 'Continue') }}</BaseButton
      >
    </div>
  </form>
</template>

<script lang="ts" setup>
import BaseButton from '@/Components/BaseButton.vue'
import BaseInput from '@/Components/BaseInput.vue'
import { getFieldError } from '@/Helpers/Errors'
import { __ } from '@/Helpers/i18n'
import { onMounted, ref } from 'vue'
import { OnboardingSteps } from '../Types/onboarding'
import BaseInputGroup from '@/Components/BaseInputGroup.vue'
import {
  useOnboardingStore,
  useOnboardingStoreRefs,
} from '../Store/useOnboardingStore'

const brandName = ref('')
const publicUrl = ref('')

const onboardingStore = useOnboardingStore()
const { submitForm } = onboardingStore
const { isSubmitting, onboardingInfo, errors } = useOnboardingStoreRefs()

const handleSubmit = () => {
  submitForm({
    current_step: OnboardingSteps.BRAND,
    brand_name: brandName.value,
    public_url: publicUrl.value,
  })
}

onMounted(() => {
  brandName.value = onboardingInfo.value?.brand_name || ''
  publicUrl.value = onboardingInfo.value?.public_url || ''
})
</script>

<style scoped lang="scss">
.brand-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 320px;
  width: 100%;
  margin: auto;
  margin-top: 32px;
  @media (min-width: 1025px) {
    margin-top: 40px;
  }
}

.buttons-container {
  display: flex;
  gap: 6px;
  margin-top: 48px;
}
</style>
