<template>
  <div class="form-flow-wrapper">
    <div class="input-section">
      <div class="logo">
        <OptimizedImg
          alt="Logo"
          :size="{ height: 18, width: 148 }"
          src="selfnamed-logo.svg"
        />
      </div>
      <div
        v-if="showProgressComponent"
        class="progress-container"
        :style="{ display: showPreviousStepButton ? 'grid' : 'flex' }"
      >
        <button
          v-if="showPreviousStepButton"
          class="back-button"
          @click="handleGoPreviousStep"
        >
          <SvgIcon
            :alt="__('global', 'back')"
            class="arrow-left"
            name="arrow-left"
          />
          {{ __('global', 'back') }}
        </button>
        <slot name="progress-bar"></slot>
      </div>
      <div class="form-container" :class="`form-container-${formMargin}`">
        <slot name="form"></slot>
      </div>
      <div class="alternative-options">
        <slot name="alternative-options"></slot>
      </div>
    </div>
    <div class="image-section">
      <OptimizedImg
        :alt="imageSectionAlt"
        :size="{
          height: 808,
          maxWidth: 1400,
          displayWidth: 'calc(100vw - 100px)',
        }"
        :src="imageSectionSrc"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { __ } from '@/Helpers/i18n'
import SvgIcon from '@/Partials/SvgIcon.vue'
import OptimizedImg from '@/Partials/ui/OptimizedImg.vue'
import { useRouter } from 'vue-router'

const props = withDefaults(
  defineProps<{
    showPreviousStepButton?: boolean
    showProgressComponent?: boolean
    showLogo?: boolean
    imageSectionSrc: string
    imageSectionAlt: string
    emitPreviousStep?: boolean
    formMargin?: 'margin-medium' | 'margin-large'
  }>(),
  {
    showLogo: true,
    showPreviousStepButton: true,
    showProgressComponent: true,
    emitPreviousStep: false,
    formMargin: 'margin-medium',
  },
)

const emit = defineEmits<{
  (e: 'goPreviousStep'): void
}>()

const router = useRouter()

const handleGoPreviousStep = () => {
  if (props.emitPreviousStep) {
    emit('goPreviousStep')
  } else {
    router.back()
  }
}
</script>

<style lang="scss">
.form-flow-heading {
  text-transform: none;
  font-weight: 500;
  letter-spacing: 0;
  font-size: 24px;
  text-align: center;
  letter-spacing: -0.03em;
  line-height: 28.8px;
  @media (min-width: 768px) {
    text-wrap: nowrap;
  }
}

.form-flow-secondary-heading {
  text-transform: none;
  font-weight: 500;
  letter-spacing: -0.02rem;
  line-height: 24px;
  color: $gray-7;
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
}

.form-flow-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1025px) {
    display: grid;
    grid-template-columns: 59.35% 40.65%;
    height: 100vh;
    overflow: hidden;
  }

  .input-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px 20px 20px;
    height: 100%;
    @media (min-width: 768px) {
      flex: 1;
      padding: 64px 48px 20px 48px;
    }

    @media (min-width: 1025px) {
      overflow-y: scroll;
      scrollbar-width: none;
    }

    .progress-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 16px;
      margin-top: 5rem;
      @media (min-width: 768px) {
        margin-top: 5rem;
      }
    }

    .back-button {
      display: flex;
      align-items: center;
      left: 0;
      gap: 8px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      color: $black;
      background: none;
      border: none;
      outline: none;
      height: 16px;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: transparent;

      .arrow-left {
        height: 16px;
        width: 16px;
      }
    }

    .input-section::-webkit-scrollbar {
      display: none; /* Hide scrollbar for WebKit browsers */
    }
  }

  .form-container {
    @include centered-flex;
    flex-direction: column;
    width: 100%;

    &-margin-medium {
      margin-top: 50px;
      @media (min-width: 768px) {
        margin-top: 60px;
      }
    }

    &-margin-large {
      margin-top: 64px;
    }
  }

  .alternative-options {
    margin-top: 15px;
    max-width: 272px;
    text-align: center;
    .alternative-option {
      margin-top: 8px;
      font-size: 1.2rem;
      span {
        color: $gray-3;
      }
      a {
        color: $black;
        text-decoration: none;
        margin: 0 2.5px;
      }
    }
  }

  .image-section {
    display: none;
    @media (min-width: 1025px) {
      display: flex;
      justify-content: end;
      height: 100vh;
      width: 100%;
      padding: 10px 10px 10px 0;
      img {
        border-radius: 100px;
        object-fit: cover;
        object-position: center;
        height: 100% !important;
        width: 100% !important;
      }
    }
  }
}
</style>
