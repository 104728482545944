<template>
  <h1 class="form-flow-heading">
    {{ __('onboarding', 'monthly-orders.main-heading.text') }}
  </h1>
  <form
    action=""
    class="monthly-orders-form-container"
    @submit.prevent="handleSubmit"
  >
    <div ref="containerRef" class="custom-slider-container">
      <input
        v-model="sliderValue"
        type="range"
        @input="updateLabel"
        @mousedown="setTransition(false)"
        @mouseup="snapToClosestPoint"
        @touchend="snapToClosestPoint"
        @touchstart="setTransition(false)"
      />
      <span
        ref="labelRef"
        class="label"
        :class="{ 'label-transition': isTransitionActive }"
        :style="{
          left: adjustedLabelLeftPosition
            ? adjustedLabelLeftPosition + 'px'
            : leftPosition + 'px',
        }"
        >{{ label }}
      </span>
      <div
        class="label-tip"
        :class="{ 'label-tip-transition': isTransitionActive }"
        name="slider-triangle"
        :style="{
          left: leftPosition + 'px',
        }"
      >
        <SvgIcon name="slider-triangle" />
      </div>
      <div class="default-range-line"></div>
      <div
        :class="{
          'active-range-line': sliderValue >= 0,
          'active-range-line-transition': isTransitionActive,
        }"
        :style="{
          width: leftPosition + 'px',
        }"
      ></div>

      <SvgIcon
        class="custom-thumb"
        :class="{ 'custom-thumb-transition': isTransitionActive }"
        name="thumb-slider"
        :style="{
          left: leftPosition + 'px',
        }"
      />
    </div>
    <h5 class="form-flow-secondary-heading">
      {{ __('onboarding', 'monthly-orders.secondary-heading.text') }}
    </h5>
    <div class="buttons-container">
      <BaseButton
        btn-style="btn-gray"
        :is-loading="isSkipping"
        type="button"
        @click.prevent="skipStep(OnboardingSteps.MONTHLY_ORDER_COUNT)"
        >{{ __('global', 'skip') }}</BaseButton
      >
      <BaseButton :is-loading="isSubmitting" type="submit">{{
        __('global', 'Continue')
      }}</BaseButton>
    </div>
  </form>
</template>

<script lang="ts" setup>
import BaseButton from '@/Components/BaseButton.vue'
import { __ } from '@/Helpers/i18n'
import { computed, nextTick, onMounted, ref } from 'vue'
import { toNumber } from 'lodash'
import SvgIcon from '@/Partials/SvgIcon.vue'
import { OnboardingSteps } from '../Types/onboarding'
import {
  useOnboardingStore,
  useOnboardingStoreRefs,
} from '../Store/useOnboardingStore'

const isTransitionActive = ref(false)
const sliderValue = ref(0)
const labelRef = ref(null)
const containerRef = ref(null)
const adjustedLabelLeftPosition = ref(0)
const label = ref(__('onboarding', 'monthly-orders.slider.text'))
const onboardingStore = useOnboardingStore()
const { submitForm, skipStep } = onboardingStore
const { isSubmitting, onboardingInfo, isSkipping } = useOnboardingStoreRefs()

type OrderCount = 0 | 100 | 1000 | 5000 | 10001

let orderCount: OrderCount = 0

const referencePoints = [0, 25, 50, 75, 100]

const leftPosition = computed(() => {
  const containerElement = containerRef.value
  if (containerElement) {
    const containerWidth = (containerElement as HTMLElement).clientWidth
    const percentageValue = sliderValue.value / 100
    const pixelValue = containerWidth * percentageValue
    const adjustedValue = pixelValue + 14 - 32 * percentageValue
    return adjustedValue
  }
  return 0
})

const handleSubmit = () => {
  submitForm({
    current_step: OnboardingSteps.MONTHLY_ORDER_COUNT,
    monthly_order_count: orderCount,
  })
}

const setTransition = (isActive: boolean) => {
  isTransitionActive.value = isActive
}

const updateLabel = () => {
  const parsedValue = toNumber(sliderValue.value)
  if (parsedValue >= 0 && parsedValue <= 12.5) {
    label.value = __('onboarding', 'monthly-orders.slider.text')
    orderCount = 0
  } else if (parsedValue > 12.5 && parsedValue <= 37.5) {
    label.value = '100'
    orderCount = 100
  } else if (parsedValue > 37.5 && parsedValue <= 62.5) {
    label.value = '1000'
    orderCount = 1000
  } else if (parsedValue > 62.5 && parsedValue <= 87.5) {
    label.value = '5000'
    orderCount = 5000
  } else {
    label.value = '10000+'
    orderCount = 10001
  }
  adjustLabelPosition()
}

const adjustLabelPosition = async () => {
  await nextTick() // Wait for the DOM to update
  const labelElement = labelRef.value
  if (labelElement && window.innerWidth < 768) {
    const labelRect = (labelElement as HTMLElement).getBoundingClientRect()
    adjustedLabelLeftPosition.value = 0
    if (leftPosition.value - labelRect.width < 0) {
      adjustedLabelLeftPosition.value = labelRect.width / 2 - 10
      return
    }
  }
}

const updateSlider = () => {
  const monthlyOrderCount: OrderCount =
    (onboardingInfo.value?.monthly_order_count as OrderCount) || 0
  if (monthlyOrderCount === 0) {
    sliderValue.value = 0
  } else if (monthlyOrderCount === 100) {
    sliderValue.value = 25
  } else if (monthlyOrderCount === 1000) {
    sliderValue.value = 50
  } else if (monthlyOrderCount === 5000) {
    sliderValue.value = 75
  } else {
    sliderValue.value = 100
  }
}

const snapToClosestPoint = () => {
  setTransition(true)
  const closestPoint = referencePoints.reduce((prev, curr) =>
    Math.abs(curr - sliderValue.value) < Math.abs(prev - sliderValue.value)
      ? curr
      : prev,
  )
  sliderValue.value = closestPoint
  updateLabel()
}

onMounted(() => {
  sliderValue.value = onboardingInfo.value?.monthly_order_count || 0
  updateSlider()
  updateLabel()
})
</script>

<style scoped lang="scss">
.monthly-orders-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 70px;
  padding: 0 10px;
  @media (min-width: 1025px) {
    margin-top: 80px;
  }
}

.custom-slider-container {
  position: relative;
  width: 100%;
  max-width: 560px;

  input[type='range'] {
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    background-color: transparent;
    width: 100%;
    height: 6px;
    z-index: 1;
    border-radius: 3px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    &::-webkit-slider-runnable-track,
    &::-moz-range-track {
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent !important; /* Make the slider track transparent */
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none !important;
      appearance: none !important;
      outline: none !important;
      border: none;
      background-color: transparent !important;
      box-shadow: 0 0 0 0 transparent;
      width: 32px;
      height: 16px;
      cursor: pointer;
      z-index: 3;
    }

    &::-moz-range-thumb {
      width: 32px;
      height: 16px;
      outline: none;
      border: none;
      background: transparent;
      cursor: pointer;
      z-index: 3;
    }

    &::-ms-thumb {
      -webkit-appearance: none !important;
      appearance: none !important;
      width: 32px;
      height: 16px;
      background-color: transparent !important;
      outline: none !important;
      border: none;
      cursor: pointer;
      z-index: 3;
    }
  }
}

.label {
  position: absolute;
  top: -35px;
  transform: translateX(-50%);
  background-color: $black;
  color: #fff;
  padding: 5px 10px;
  border-radius: 996px;
  font-size: 12px;
  margin: 0 auto;
  font-weight: 700;
  &-transition {
    transition: left 0.3s ease;
    -webkit-transition: left 0.3s ease; /* Safari */
    -moz-transition: left 0.3s ease; /* Firefox */
    -o-transition: left 0.3s ease; /* Opera */
  }

  svg {
    height: 6px;
    width: 16px;
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    margin: auto;
  }

  .slider-triangle {
    position: absolute;
  }
}

.label-tip {
  position: absolute;
  height: 6px;
  width: 16px;
  top: -6.7px;
  transform: translateX(-50%);
  &-transition {
    transition: left 0.3s ease;
    -webkit-transition: left 0.3s ease; /* Safari */
    -moz-transition: left 0.3s ease; /* Firefox */
    -o-transition: left 0.3s ease; /* Opera */
  }
  svg {
    height: 6px;
    width: 16px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.default-range-line {
  position: absolute;
  height: 4px;
  background-color: $light-gray;
  width: 99%;
  top: 50%;
  transform: translateY(50%);
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 3px;
  z-index: -1;
}

.active-range-line {
  position: absolute;
  top: 50%;
  left: 0;
  height: 6px;
  border-radius: 3px;
  background-color: $black;
  z-index: -1;

  &-transition {
    transition: width 0.3s ease;
    -webkit-transition: width 0.3s ease; /* Safari */
    -moz-transition: width 0.3s ease; /* Firefox */
    -o-transition: width 0.3s ease; /* Opera */
  }
}

.custom-thumb {
  position: absolute;
  top: 6px;
  transform: translateX(-50%);
  margin: auto;
  z-index: 4;
  cursor: pointer;
  fill: $white;
  height: 16px;
  width: 32px;
  z-index: -1;

  &-transition {
    transition: left 0.3s ease;
    -webkit-transition: left 0.3s ease; /* Safari */
    -moz-transition: left 0.3s ease; /* Firefox */
    -o-transition: left 0.3s ease; /* Opera */
  }
}

.form-flow-secondary-heading {
  font-size: 10px;
  letter-spacing: 0.1px;
  color: $gray-1;
}

.buttons-container {
  display: flex;
  gap: 6px;
  margin-top: 40px;
}
</style>
