<template>
  <div class="filter-dropdown">
    <div class="category-filter">
      <div
        class="category-item"
        :class="{
          active: isAllProductsActive,
          imageError: imageErrors.includes(ALL_PRODUCTS_CATEGORY_ID),
        }"
        role="button"
        tabindex="0"
        @click="selectAllProducts"
        @keydown.enter.prevent="selectAllProducts"
      >
        <div class="icon-wrapper">
          <SvgIcon
            class="check-icon"
            :class="{ active: isAllProductsActive }"
            :name="
              getIcon({
                id: ALL_PRODUCTS_CATEGORY_ID,
                active: isAllProductsActive,
              })
            "
          />
        </div>

        <OptimizedImg
          v-if="!imageErrors.includes(ALL_PRODUCTS_CATEGORY_ID)"
          alt="Logo"
          class="category-image"
          :size="{ height: 140, width: 128 }"
          :src="getCategoryImage(ALL_PRODUCTS_CATEGORY_ID)"
          @error="handleImageError(ALL_PRODUCTS_CATEGORY_ID)"
        />

        <SvgIcon
          v-else
          class="category-icon"
          :class="{
            active: isAllProductsActive,
          }"
          name="star-color"
        />

        <span class="category-label">{{
          __('products', 'filters.category.all-products.text')
        }}</span>
      </div>

      <div
        v-for="category in localProductCategories"
        :key="category.id"
        class="category-item"
        :class="{
          active: category.active,
          imageError: imageErrors.includes(category.id),
        }"
        role="button"
        tabindex="0"
        @blur="hoveredItem = null"
        @click="toggleCategory(category)"
        @focus="hoveredItem = category.id"
        @keydown.enter.prevent="toggleCategory(category)"
        @mouseenter="hoveredItem = category.id"
        @mouseleave="hoveredItem = null"
      >
        <div class="icon-wrapper">
          <SvgIcon
            class="check-icon"
            :class="{
              active: category.active,
              hovered: hoveredItem === category.id && isDesktop,
              hoveredActive:
                category.active && hoveredItem === category.id && isDesktop,
            }"
            :name="getIcon(category)"
          />
        </div>

        <OptimizedImg
          v-if="!imageErrors.includes(category.id)"
          alt="Logo"
          :size="{ height: 140, width: 128 }"
          :src="getCategoryImage(category.id)"
          @error="handleImageError(category.id)"
        />

        <SvgIcon
          v-else
          class="category-icon"
          :class="{
            active: category.active,
          }"
          name="star-color"
        />

        <span class="category-label">{{ category.name }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import SvgIcon from '@/Partials/SvgIcon.vue'
import { __ } from '@/Helpers/i18n'
import { useProductsStore } from '@/Store/useProductsStore'
import useScreenBreakpoints from '@/Composables/UseScreenBreakpoints'
import { getAttributeFilterIds, getIconName } from '@/Api/Products'
import OptimizedImg from '@/Partials/ui/OptimizedImg.vue'
import { IconAttribute } from '@/Types/Product'

defineProps<{
  baseUrl: string
  attributeKey: string
}>()

const emit = defineEmits<{
  (e: 'filter', selectedIds: number[]): void
}>()

const route = useRoute()
const productsStore = useProductsStore()
const { isDesktop } = useScreenBreakpoints()
const hoveredItem = ref<number | null>(null)
const ALL_PRODUCTS_CATEGORY_ID = -1

const attributeGroupIds = getAttributeFilterIds(
  'product-catalog.filter.category-attributes',
)

const getIcon = (attribute: IconAttribute) =>
  getIconName(attribute, hoveredItem.value, isDesktop.value)

const imageErrors = ref<number[]>([])

const handleImageError = (categoryId: number) => {
  if (!imageErrors.value.includes(categoryId)) {
    imageErrors.value.push(categoryId)
  }
}

const getCategoryImage = (categoryId: number) => {
  const matchedCategory = attributeGroupIds.find(
    (attr) => attr.id === categoryId,
  )
  return matchedCategory ? matchedCategory.imageUrl : ''
}

const localProductCategories = ref(
  (productsStore.productCategories || []).map((category) => ({
    ...category,
    active: false,
  })),
)

const selectedIds = ref<number[]>([])

const isAllProductsActive = computed(() => {
  const activeCount = localProductCategories.value.filter(
    (cat) => cat.active,
  ).length
  return (
    activeCount === 0 || activeCount === localProductCategories.value.length
  )
})

const initializeActiveCategories = () => {
  const categoryIdsFromUrl =
    typeof route.query.categoryIds === 'string'
      ? route.query.categoryIds.split(',').map(Number)
      : []

  if (categoryIdsFromUrl.length) {
    localProductCategories.value.forEach((category) => {
      if (categoryIdsFromUrl.includes(category.id)) {
        category.active = true
      }
    })

    selectedIds.value = categoryIdsFromUrl
  }
}

onMounted(() => {
  initializeActiveCategories()
})

const selectAllProducts = () => {
  localProductCategories.value.forEach((category) => (category.active = false))
  selectedIds.value = []
  emit('filter', selectedIds.value)
}

const toggleCategory = (category: { id: number; active: boolean }) => {
  category.active = !category.active

  selectedIds.value = localProductCategories.value
    .filter((cat) => cat.active)
    .map((cat) => cat.id)

  emit('filter', selectedIds.value)
}
</script>

<style lang="scss" scoped>
.category-filter {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 10px;
  background: #fff;
  border-radius: 16px;
  width: 536px;
  position: absolute;
  margin-top: 24px;
  border: 2px solid rgb(241, 241, 244);
  z-index: 2;
  left: -10px;
  @media (max-width: 991px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    gap: 8px;
    padding: 0px 16px 16px 16px;
    z-index: 3;
    margin-top: 0;
    width: 100%;
    position: relative;
    border-radius: 0;
    margin-top: -3px;
    border: none;
    left: 0;
  }
}

.check-icon {
  width: 20px;
  height: 20px;
  pointer-events: none;
  border-radius: 6px;
  margin-left: auto;
  transition:
    0.3s ease-in-out,
    color 0.3s ease-in-out;
  color: transparent;
  background-color: transparent;
}

.check-icon.active {
  color: rgba(41, 69, 56, 1);
  background-color: rgba(215, 233, 216, 1);
}

.category-image {
  display: block;
  margin: auto;
}

.check-icon.hovered,
.check-icon.active.hoveredActive {
  color: rgba(215, 233, 216, 1);
  background-color: rgba(41, 69, 56, 1);
}

.icon-wrapper {
  position: absolute;
  align-self: start;
  top: 12px;
  left: 12px;
}

.category-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 128px;
  height: 140px;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-align: center;

  @media (max-width: 991px) {
    width: 100%;
    height: 141px;
    gap: 10px;
    background-color: rgb(246, 246, 247);
  }
  &:focus {
    outline: transparent;
  }
}

@media (min-width: 992px) {
  .category-item {
    &:hover {
      background: rgb(241, 241, 244);
    }
  }
}

.category-item.imageError {
  gap: 31px;
}

.category-icon {
  width: 20px;
  height: 20px;
  margin-top: 30px;
  fill: rgb(197, 199, 203);
  transition: fill 0.3s ease-in-out;
}

.category-icon.active {
  fill: black;
}

.color-box {
  width: 28px;
  height: 28px;
  border-radius: 8px;
}

.category-label {
  margin-top: auto;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: black;
  position: absolute;
  bottom: 16px;
  @media (max-width: 991px) {
    bottom: 20px;
  }
}
</style>
