<template>
  <h1 class="form-flow-heading">
    {{ __('onboarding', 'category.main-heading.text') }}
  </h1>
  <form
    action=""
    class="category-form-container"
    @submit.prevent="handleSubmit"
  >
    <div class="grid">
      <Card
        v-for="option in categories"
        :key="option.value"
        :is-selected="selectedCategory === option.value"
        :option="option"
        @select="handleSelect"
      />
    </div>
    <div class="buttons-container">
      <BaseButton
        btn-style="btn-gray"
        :is-loading="isSkipping"
        type="button"
        @click.prevent="skipStep(OnboardingSteps.CATEGORY)"
        >{{ __('global', 'skip') }}</BaseButton
      >
      <BaseButton
        v-if="selectedCategory"
        :is-loading="isSubmitting"
        type="submit"
        >{{ __('global', 'Continue') }}</BaseButton
      >
    </div>
  </form>
</template>

<script lang="ts" setup>
import BaseButton from '@/Components/BaseButton.vue'
import { __ } from '@/Helpers/i18n'
import { onMounted, ref } from 'vue'
import Card from './Card.vue'
import { OnboardingCategory, OnboardingSteps } from '../../Types/onboarding'
import {
  useOnboardingStore,
  useOnboardingStoreRefs,
} from '../../Store/useOnboardingStore'

const selectedCategory = ref<OnboardingCategory | null>(null)
const onboardingStore = useOnboardingStore()
const { submitForm, skipStep } = onboardingStore
const { isSubmitting, onboardingInfo, isSkipping } = useOnboardingStoreRefs()
const { AGENCY, BEAUTY_SERVICE, CONTENT_CREATOR, ECOMMERCE, HORECA, OTHER } =
  OnboardingCategory

const categories = [
  {
    value: ECOMMERCE,
    label: __('onboarding', 'category.ecommerce-card.text'),
    image: __('onboarding', 'category.ecommerce-card.image'),
    alt: __('onboarding', 'category.ecommerce-card.alt'),
  },
  {
    value: HORECA,
    label: __('onboarding', 'category.horeca-card.text'),
    image: __('onboarding', 'category.horeca-card.image'),
    alt: __('onboarding', 'category.horeca-card.alt'),
  },
  {
    value: CONTENT_CREATOR,
    label: __('onboarding', 'category.content-creator-card.text'),
    image: __('onboarding', 'category.content-creator-card.image'),
    alt: __('onboarding', 'category.content-creator-card.alt'),
  },
  {
    value: BEAUTY_SERVICE,
    label: __('onboarding', 'category.beauty-service-card.text'),
    image: __('onboarding', 'category.beauty-service-card.image'),
    alt: __('onboarding', 'category.beauty-service-card.alt'),
  },
  {
    value: AGENCY,
    label: __('onboarding', 'category.agency-card.text'),
    image: __('onboarding', 'category.agency-card.image'),
    alt: __('onboarding', 'category.agency-card.alt'),
  },
  {
    value: OTHER,
    label: __('onboarding', 'category.other-card.text'),
    image: __('onboarding', 'category.other-card.image'),
    alt: __('onboarding', 'category.other-card.alt'),
  },
]

const handleSelect = (value: string) => {
  const categoryValue = value as OnboardingCategory
  selectedCategory.value = categoryValue
}

const handleSubmit = () => {
  if (selectedCategory.value) {
    submitForm({
      current_step: OnboardingSteps.CATEGORY,
      category: selectedCategory.value,
    })
  }
}

onMounted(() => {
  selectedCategory.value = onboardingInfo.value?.category || null
})
</script>

<style scoped lang="scss">
.category-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 32px;
  @media (min-width: 1025px) {
    margin-top: 40px;
  }
}

.grid {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.buttons-container {
  display: flex;
  gap: 6px;
  margin-top: 48px;
}
</style>
