export enum OnboardingCategory {
  ECOMMERCE = 'ECOMMERCE',
  HORECA = 'HORECA',
  CONTENT_CREATOR = 'CONTENT_CREATOR',
  AGENCY = 'AGENCY',
  BEAUTY_SERVICE = 'BEAUTY_SERVICE',
  OTHER = 'OTHER',
}

export enum OnboardingPrimaryMotivation {
  SELL_ONLINE = 'SELL_ONLINE',
  SELL_IN_PHYSICAL_LOCATIONS = 'SELL_IN_PHYSICAL_LOCATIONS',
  BUYING_FOR_MYSELF = 'BUYING_FOR_MYSELF',
  PLAYING_AROUND = 'PLAYING_AROUND',
}

export enum OnboardingProductsInterested {
  ALL = 'ALL',
  MEN_SKINCARE = 'MEN_SKINCARE',
  SENSITIVE_SKIN = 'SENSITIVE_SKIN',
  ANTI_AGEING = 'ANTI_AGEING',
  SUN_PROTECTION = 'SUN_PROTECTION',
  FACE_CARE = 'FACE_CARE',
  BODY_CARE = 'BODY_CARE',
  HAIR_CARE = 'HAIR_CARE',
  OTHER = 'OTHER',
}

export enum OnboardingReferral {
  YOUTUBE = 'YOUTUBE',
  INSTAGRAM = 'INSTAGRAM',
  PARTNERS = 'PARTNERS',
  OTHER_PEOPLE = 'OTHER_PEOPLE',
  FACEBOOK = 'FACEBOOK',
  TIKTOK = 'TIKTOK',
  SEARCH_ENGINES = 'SEARCH_ENGINES',
  OTHERS = 'OTHERS',
}

export enum OnboardingSteps {
  NAME = 'NAME',
  BRAND = 'BRAND',
  CATEGORY = 'CATEGORY',
  MONTHLY_ORDER_COUNT = 'MONTHLY_ORDER_COUNT',
  PRIMARY_MOTIVATION = 'PRIMARY_MOTIVATION',
  PRODUCTS_INTERESTED = 'PRODUCTS_INTERESTED',
  REFERRAL = 'REFERRAL',
  NEWSLETTER = 'NEWSLETTER',
}

export enum OnboardingStepIndexes {
  NAME,
  BRAND,
  CATEGORY,
  MONTHLY_ORDER_COUNT,
  PRIMARY_MOTIVATION,
  PRODUCTS_INTERESTED,
  REFERRAL,
  NEWSLETTER,
  SUCCESS,
}

export type OnboardingData = {
  id: number
  current_step: OnboardingSteps | null
  last_step: OnboardingSteps | null
  first_name: string | null
  last_name: string | null
  brand_name: string | null
  public_url: string | null
  category: OnboardingCategory | null
  monthly_order_count: number | null
  primary_motivation: OnboardingPrimaryMotivation | null
  products_interested: OnboardingProductsInterested[] | null
  referral: OnboardingReferral | null
  receive_emails: boolean | null
}

export type NameStep = {
  first_name: string
  last_name: string
}

export type BrandStep = {
  current_step: OnboardingSteps.BRAND
  brand_name: string
  public_url: string
}

export type CategoryStep = {
  current_step: OnboardingSteps.CATEGORY
  category: OnboardingCategory
}

export type MonthlyOrderCountStep = {
  current_step: OnboardingSteps.MONTHLY_ORDER_COUNT
  monthly_order_count: number
}

export type PrimaryMotivationStep = {
  current_step: OnboardingSteps.PRIMARY_MOTIVATION
  primary_motivation: OnboardingPrimaryMotivation
}

export type ProductsInterestedStep = {
  current_step: OnboardingSteps.PRODUCTS_INTERESTED
  products_interested: OnboardingProductsInterested[]
}

export type ReferralStep = {
  current_step: OnboardingSteps.REFERRAL
  referral: OnboardingReferral
}

export type NewsletterStep = {
  current_step: OnboardingSteps.NEWSLETTER
  receive_emails: boolean
}

export type FieldError = {
  field: string
  message: string
}

export type ProgressBarItem = {
  step: OnboardingSteps
  isCompleted: boolean
}

export type ProgressBarItemNew = {
  step: number
  isCompleted: boolean
}

export type ProgressBarItemOrGroup = ProgressBarItem | ProgressBarItem[]

export type onBoardingStep = {
  component: any
  imageAlt: string
  imageSrc: string
}
