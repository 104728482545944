<template>
  <div v-if="visible" class="design-offer-popup-wrap">
    <div class="design-offer-popup">
      <div v-if="success" class="success-wrap">
        <div class="exit-wrap">
          <a href="#" @click.prevent="close">
            <SvgIcon class="exit" name="exit" />
          </a>
        </div>
        <SvgIcon class="success" name="success" />
        <h5>
          {{ __('design-offer-popup', 'Thank you! You are subscribed now!') }}
        </h5>
        <p>
          {{
            __(
              'design-offer-popup',
              'You will receive newsletters and marketing related materials on your email from now on.',
            )
          }}
        </p>
      </div>
      <div v-else>
        <div class="popup-top">
          <a href="#" @click.prevent="close">
            <SvgIcon class="exit" name="exit" />
          </a>
        </div>
        <div class="image">
          <OptimizedImg
            :alt="__('alt-tags', 'Design offer')"
            :size="{ height: 302, width: 442 }"
            src="design-offer.jpg"
          />
        </div>
        <div class="bottom">
          <h5>{{ __('design-offer-popup', 'Let’s Keep in Touch!') }}</h5>
          <p class="text">
            {{
              __(
                'design-offer-popup',
                'Sign up for newsletter and be the first to receive latest news from Selfnamed.',
              )
            }}
          </p>
          <form action="" @submit.prevent="submitForm">
            <div class="form-wrap">
              <p class="mobile-info-text">
                {{
                  __(
                    'design-offer-popup',
                    'Submit your e-mail and we will contact you.',
                  )
                }}
              </p>
              <div class="email-wrap">
                <div
                  class="input-element"
                  :class="{ error: errors && findErrorField(errors, 'email') }"
                >
                  <div class="wrap">
                    <input
                      id="email"
                      v-model.trim="email"
                      placeholder=" "
                      type="email"
                    />
                    <label for="email">{{ __('forms', 'Email') }}</label>
                  </div>
                </div>
                <button
                  class="btn btn-primary desktop"
                  :class="{ loading: loading }"
                  type="submit"
                >
                  {{ __('global', 'Submit') }}
                </button>
              </div>
              <div
                class="input-checkbox input-element small terms-checkbox"
                :class="{ error: errors && findErrorField(errors, 'terms') }"
              >
                <div class="wrap">
                  <input
                    id="terms-design-offer"
                    v-model="terms"
                    type="checkbox"
                  />
                  <label for="terms-design-offer"
                    >{{ __('design-offer-popup', 'I agree to') }}
                    <a
                      :href="pageLinks ? pageLinks.value.terms_url : ''"
                      target="_blank"
                      >{{ __('global', 'Terms & Conditions') }}</a
                    >
                    {{ __('global', 'and') }}
                    <a
                      :href="
                        pageLinks ? pageLinks.value.privacy_policy_url : ''
                      "
                      target="_blank"
                      >{{ __('global', 'Privacy policy') }}</a
                    >
                  </label>
                </div>
              </div>
              <div
                class="input-checkbox input-element small"
                :class="{
                  error: errors && findErrorField(errors, 'newsletter'),
                }"
              >
                <div class="wrap">
                  <input
                    id="newsletter-design-offer"
                    v-model="newsletter"
                    type="checkbox"
                  />
                  <label for="newsletter-design-offer">{{
                    __(
                      'design-offer-popup',
                      'I agree to receive Newsletter & Marketing related materials etc.',
                    )
                  }}</label>
                </div>
              </div>
              <div class="mobile-button-wrap">
                <button
                  class="btn btn-primary mobile"
                  :class="{ loading: loading }"
                  type="submit"
                >
                  {{ __('global', 'Submit') }}
                </button>
              </div>
            </div>
            <div v-for="item in errors" :key="item.field" class="errors">
              <p>{{ item.message }}</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import SvgIcon from './SvgIcon.vue'
import { __ } from '@/Helpers/i18n'
import { computed, onMounted, ref } from 'vue'
import { findErrorField } from '@/Helpers/Global'
import Cookies from 'js-cookie'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { postNewsletter } from '@/Api/Products'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { useLoginStore } from '@/Store/useLoginStore'
import { PageLinkSetting } from '@/Types/Setting'
import OptimizedImg from '@/Partials/ui/OptimizedImg.vue'

const props = defineProps<{ time: number }>()

const globalStore = useGlobalStore()
const loginStore = useLoginStore()

//@ts-ignore
const { executeRecaptcha } = useReCaptcha()

const email = ref()
const newsletter = ref(false)
const terms = ref(false)
const errors = ref()
const success = ref(false)
const loading = ref(false)
const visible = ref(false)

const userLoggedIn = computed(() => loginStore.userLoggedIn)

const pageLinks = computed(
  () =>
    globalStore.getSettingByKey('pagelinksetting') as
      | PageLinkSetting
      | undefined,
)

onMounted(() => {
  if (
    !Cookies.get('design-offer-popup') &&
    !userLoggedIn.value &&
    !sessionStorage.getItem('design-offer-popup')
  ) {
    setTimeout(() => (visible.value = true), props.time)
  }
})

const submitForm = async () => {
  errors.value = null
  success.value = false
  loading.value = true
  const token = await executeRecaptcha('submitDesignOfferForm')

  await postNewsletter({
    email: email.value,
    recaptcha: token,
    accept_newsletter: newsletter.value,
    accept_terms: terms.value,
  }).catch((error: any) => {
    errors.value = error.response.data
  })

  loading.value = false

  if (!errors.value) {
    Cookies.set('design-offer-popup', '1', { expires: 15 })
    success.value = true
  }
}

const close = () => {
  sessionStorage.setItem('design-offer-popup', '1')
  visible.value = false
}
</script>

<style lang="scss">
.design-offer-popup-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#eeeff1, 0.8);
  z-index: 5;
  overflow-y: auto;
}

.design-offer-popup {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 442px;
  width: 90%;
  background: $white;
  @include border-radius(md);
  @media (min-width: 768px) {
    width: 100%;
    top: 30px;
    transform: translateX(-50%);
  }

  .popup-top {
    padding: 1.6rem 2rem 1.6rem 1.6rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @media (min-width: 768px) {
      padding: 2.4rem 2.4rem 2.4rem 4rem;
    }
  }

  svg.exit {
    width: 12px;
    height: 12px;
    color: $black;
  }

  .bottom {
    padding: 1.6rem;
    @media (min-width: 768px) {
      padding: 3rem 4rem;
    }
  }

  h5 {
    margin-bottom: 0.8rem;
    @media (min-width: 768px) {
      margin-bottom: 1.2rem;
    }
  }

  .text {
    margin-bottom: 2.4rem;
    letter-spacing: 0.3px;
    @media (min-width: 768px) {
      margin-bottom: 4rem;
    }
  }

  .mobile-info-text {
    font-size: 1.2rem;
    margin-bottom: 1.2rem;
  }

  .email-wrap {
    display: flex;
    margin-bottom: 2rem;

    .btn {
      width: 129px;
    }
  }

  .input-element {
    margin-bottom: 0;

    &:not(.input-checkbox) {
      width: 100%;
      @media (min-width: 768px) {
        margin-right: 0.8rem;
        width: auto;
      }
    }
  }

  .input-checkbox {
    margin-bottom: 2.4rem;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }

    &.terms-checkbox {
      margin-bottom: 1.4rem;
    }
  }

  button {
    &.desktop {
      display: none;
      @media (min-width: 768px) {
        display: block;
      }
    }
  }

  .mobile-button-wrap {
    display: flex;
    justify-content: flex-end;
    @media (min-width: 768px) {
      display: none;
    }
  }

  .success-wrap {
    padding: 2.4rem 2.4rem 7rem 2.4rem;
    text-align: center;

    .exit-wrap {
      display: flex;
      justify-content: flex-end;
    }

    h5 {
      text-transform: none;
      font-weight: 700;
      max-width: 282px;
      margin: 3.2rem auto 1.6rem auto;
    }
  }

  svg.success {
    color: $vibrant-green;
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }

  .errors {
    color: $red;
    font-size: 1.4rem;
  }
}
</style>
