<template>
  <div
    class="product-block"
    role="button"
    @focusin="productHovered = true"
    @focusout="productHovered = false"
  >
    <ProductCard
      :active-image="activeImage"
      :link-to-product="linkToProduct"
      :product="product"
      :show-action-buttons="showActionButtons"
      @add-to-cart="tryAddingSampleSetToCart"
      @join-waiting-list="openWaitingListForm"
      @view-product="handleViewProductClick"
    />

    <div v-if="filteredAttributes.length" class="tags-container">
      <span
        v-for="(tag, index) in filteredAttributes"
        :key="index"
        class="tag-item"
      >
        {{ tag.name }}
      </span>
    </div>

    <div class="info-wrapper">
      <p
        class="info"
        :class="{
          'info-capitalize':
            product.type === ProductType.SAMPLE_SET ||
            product.type === ProductType.COLLECTION_BOX,
        }"
      >
        {{ product.cartInfoText }}
        <SvgIcon v-if="variantProductsCount" class="dot-text" name="dot-text" />
      </p>
      <span v-if="variantProductsCount" class="product-count">
        {{ variantProductsCount }}
        {{
          variantProductsCount > 1
            ? __('products', 'catalog.variants.product-attribute.tag.text')
            : __('products', 'catalog.variant.product-attribute.tag.text')
        }}
      </span>
    </div>
    <a
      class="title"
      :href="linkToProduct"
      @blur="stopAnimation"
      @click="handleViewProductClick(product, linkToProduct)"
      @click.prevent="handleViewProductClick(product, linkToProduct)"
      @focus="changeImages"
      @keydown.enter="handleViewProductClick(product, linkToProduct)"
      @mouseenter="changeImages"
      @mouseleave="stopAnimation"
    >
      {{ product.title }}
    </a>

    <ProductPriceDisplay
      :highlighted="false"
      :product="product"
      :show-tax="showTax"
    />

    <WaitingListForm
      v-if="productForWaitingList"
      ref="desktopWaitingListForm"
      :class="{ 'overflows-body': joinWaitingListFormOverflowsBodyOnDesktop }"
      :product="productForWaitingList"
      @close="closeWaitingListForm"
    />

    <Teleport v-if="signUpOpen" to="body">
      <SignUpModal
        :login="false"
        :no-redirect="noRedirectAfterSignup"
        :open="signUpOpen"
        :redirect-url="signupRedirectUrl"
        @close="closeSignUp"
      />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import WaitingListForm from '@/Partials/Products/WaitingListForm.vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import { computed, ref, useTemplateRef, watch, watchEffect } from 'vue'
import { __ } from '@/Helpers/i18n'
import useProductActions from '@/Composables/useProductActions'
import { useProductsStore } from '@/Store/useProductsStore'
import { ProductVMType } from '@/ViewModels/ProductVM'
import { GalleryPhotoResource } from '@/Types/GalleryPhoto'
import { createLinkToProduct } from '@/Helpers/SiteTreeHelper'
import SignUpModal from '@/Components/SignUpModal.vue'
import { useLoginStore } from '@/Store/useLoginStore'
import { useElementBounding } from '@vueuse/core'
import ProductCard from './ProductCard.vue'
import ProductPriceDisplay from '@/Widgets/SampleSetsTool/Partials/ProductPriceDisplay.vue'
import { ProductType } from '@/Types/Product'
import { getAttributeFilterIds } from '@/Api/Products'

const props = withDefaults(
  defineProps<{
    product: Omit<
      ProductVMType<
        | 'gallery_photos'
        | 'inspiration_gallery_photos'
        | 'blacklisted_countries'
        | 'product_attributes'
        | 'variant_products_count'
      >,
      'collection_box_products' | 'sample_set_products'
    >
    showActionButtons?: boolean
    routerLinkTo?: string | (Record<string, unknown> & { name: string }) | null
    showProBadge?: boolean
    showTax: boolean
  }>(),
  { showActionButtons: true, routerLinkTo: null, showProBadge: false },
)
const emit = defineEmits<{ 'open-side-cart': [] }>()

const productsStore = useProductsStore()
const loginStore = useLoginStore()

const {
  productForWaitingList,
  openWaitingListForm,
  closeWaitingListForm,
  signUpOpen,
  openSignUp,
  closeSignUp,
  signupRedirectUrl,
  noRedirectAfterSignup,
  addToCart,
  handleViewProductClick,
} = useProductActions()

const desktopWaitingListForm = useTemplateRef<HTMLDivElement>(
  'desktopWaitingListForm',
)

const attributeGroupIds = computed(() =>
  getAttributeFilterIds('product-catalog.badge.product-attribute-type'),
)

const filteredAttributes = computed(() => {
  if (!attributeGroupIds.value || !props.product.product_attributes) return []

  const attributeMap = new Map(
    attributeGroupIds.value.map((group) => [group.id, group.type]),
  )

  const attributesByType: {
    [key: string]: typeof props.product.product_attributes
  } = {}

  props.product.product_attributes.forEach((attr) => {
    if (!attr.parent_id) return

    const type = attributeMap.get(attr.parent_id)

    if (!type) return

    if (!attributesByType[type]) attributesByType[type] = []

    attributesByType[type].push(attr)
    return attributesByType
  })

  const attributeLists = Object.values(attributesByType)

  if (attributeLists.length === 0) return []

  if (attributeLists.length === 1) {
    return attributeLists[0].slice(0, 2)
  } else {
    return attributeLists.flatMap((list) => list.slice(0, 1))
  }
})

const { x, width } = useElementBounding(desktopWaitingListForm)

const joinWaitingListFormOverflowsBodyOnDesktop = computed(
  () => x.value + width.value >= window.innerWidth,
)

const variantProductsCount = computed(
  () => props.product.variant_products_count ?? 0,
)

const category = ref()

const linkToProduct = computed(
  () => (props.routerLinkTo ?? createLinkToProduct(props.product)) as string,
)

watchEffect(() => {
  category.value = productsStore.productCategories?.find(
    (item) => item.id === props.product.category_id,
  )?.slug
})

const animationImages = ref()

const setAnimationImages = (starredPhotos: GalleryPhotoResource[]) => {
  const starredImages: string[] = []
  starredPhotos.forEach((item) => starredImages.push(item.image))
  animationImages.value = [...[props.product.image], ...starredImages]
}

const starredPhotos = computed(() => {
  const starred: GalleryPhotoResource[] = []
  if (!props.product) return []

  props.product.gallery_photos.forEach((item) => {
    if (item.is_starred) starred.push(item)
  })

  props.product.inspiration_gallery_photos.forEach((item) => {
    if (item.is_starred) starred.push(item)
  })

  setAnimationImages(starred)

  return starred
})

const allImages = computed(() => [
  { image: props.product.image, description: props.product.title },
  ...[
    ...(props.product?.gallery_photos ?? []),
    ...(props.product?.inspiration_gallery_photos ?? []),
  ]
    .filter(({ is_starred }) => is_starred)
    .map(({ image, description }) => ({ image, description })),
])

watch(allImages, (images) => {
  animationImages.value = images.map((item) => item.image)
})

const activeImage = ref(props.product.image)
const animationInterval = ref()
const counter = ref(1)

const stopAnimation = () => {
  if (animationInterval.value) {
    clearInterval(animationInterval.value)
  }
  counter.value = 0
  activeImage.value = props.product.image
}

const changeImages = () => {
  if (starredPhotos.value.length > 1) {
    executeAnimation()
    animationInterval.value = setInterval(() => {
      executeAnimation()
    }, 750)
  } else if (starredPhotos.value.length === 1) {
    activeImage.value = animationImages.value[1]
  }
}

const executeAnimation = () => {
  if (!animationImages.value[counter.value]) {
    counter.value = 0
  }
  activeImage.value = animationImages.value[counter.value]
  counter.value++
}

const tryAddingSampleSetToCart = async () => {
  if (!loginStore.userLoggedIn) {
    signupRedirectUrl.value = null
    noRedirectAfterSignup.value = true
    openSignUp()
  } else {
    await addToCart(props.product, { is_sample: 1 })
    emit('open-side-cart')
  }
}

const productHovered = ref(false)
</script>

<style scoped lang="scss">
.product-block {
  .badge {
    background: $black;
    color: $white;
    text-transform: uppercase;
    white-space: nowrap;
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
    border-radius: 8px;
    font-weight: 800;
    letter-spacing: 0.7px;

    &.status {
      background-color: $gray-1;
      white-space: nowrap;
    }

    &--gray {
      background: $gray-1;
    }
  }
  .dot-text {
    width: 4px;
    height: 4px;
    margin: 0 8px 0 9px;
  }

  .tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 12px;
  }

  .tag-item {
    background-color: rgb(241, 241, 244);
    color: #333;
    border-radius: 6px;
    padding: 6px 8px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    white-space: nowrap;
  }

  .info {
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  .info-capitalize {
    text-transform: capitalize;
  }

  .info-wrapper {
    font-size: 12px;
    margin-top: 12px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    line-height: 18px;
    color: #7b7c83;
    @media (max-width: 991px) {
      flex-direction: column;
      align-items: start;
    }
  }

  a {
    color: $black;
    display: block;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.2px;
  }

  .desktop-waitlist-form-wrapper {
    display: none;
    @include desktop-only {
      display: block;
    }
  }

  .product-count {
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
  }

  div.mobile-fixed-content {
    position: fixed;
    bottom: calc(30px + env(safe-area-inset-bottom));
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    border-radius: 31px;
    z-index: 2;
    @include desktop-only {
      display: none;
    }
  }
}
</style>
