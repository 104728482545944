<template>
  <div class="tag-filter">
    <div
      v-for="tag in tags"
      :key="tag.id"
      class="tag-item"
      :class="[{ active: selectedTags.includes(tag.id) }]"
      role="button"
      tabindex="0"
      @click="toggleAttribute(tag)"
      @focusin="hoveredItem = tag.id"
      @focusout="hoveredItem = null"
      @keydown.enter.prevent="toggleAttribute(tag)"
      @mouseenter="hoveredItem = tag.id"
      @mouseleave="hoveredItem = null"
    >
      <ProProductBadge v-if="tagData[tag.id].type === TAG_TYPES.PRO" />
      <span v-else class="tag-label" :class="tagData[tag.id].cssClass">
        {{ tagData[tag.id].label }}
      </span>

      <div class="icon-wrapper">
        <SvgIcon
          class="check-icon"
          :class="{
            active: tag.active,
            hovered: tag.active && hoveredItem === tag.id && isDesktop,
          }"
          :name="tagData[tag.id].iconName"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getAttributeFilterIds, getIconName } from '@/Api/Products'
import useScreenBreakpoints from '@/Composables/UseScreenBreakpoints'
import ProProductBadge from '@/Partials/Products/ProProductBadge.vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import { ProductAttributeGroupResource } from '@/Types/AttributeGroup'
import { ProductAttributeResource } from '@/Types/ProductAttribute'
import { Modify } from '@/Types/Shared'
import { computed, defineProps, ref } from 'vue'
import { __ } from '@/Helpers/i18n'
import { IconAttribute } from '@/Types/Product'

const props = defineProps<{
  item: Modify<
    ProductAttributeGroupResource<'product_attributes'>,
    {
      product_attributes: Array<ProductAttributeResource & { active: boolean }>
    }
  > & { open: boolean }
}>()

const emit = defineEmits<{ (e: 'filter'): void }>()

const TAG_TYPES = {
  PRO: 'pro',
  DISCOUNT: 'discount',
  COMING_SOON: 'coming soon',
  NEW: 'new',
  BESTSELLER: 'bestseller',
}

const TAG_TRANSLATIONS = {
  [TAG_TYPES.DISCOUNT]: __('products', 'catalog.badge.discount.text'),
  [TAG_TYPES.COMING_SOON]: __('products', 'catalog.badge.coming-soon.text'),
  [TAG_TYPES.NEW]: __('products', 'catalog.badge.new.text'),
  [TAG_TYPES.BESTSELLER]: __('products', 'catalog.badge.bestseller.text'),
}

const { isDesktop } = useScreenBreakpoints()

const selectedTags = ref<number[]>([])
const hoveredItem = ref<number | null>(null)

const tags = computed(() => props.item.product_attributes ?? [])

const tagData = computed(() => {
  if (!props.item.product_attributes) return {}

  return props.item.product_attributes.reduce<
    Record<
      number,
      { type: string; cssClass: string; iconName: string; label: string }
    >
  >((acc, attr) => {
    const { type } = getTagInfo(attr.id)

    if (!acc[attr.id]) {
      acc[attr.id] = {
        type,
        cssClass: getTagClass(attr.id),
        iconName: getIcon(attr),
        label: TAG_TRANSLATIONS[type] ?? type,
      }
    }

    return acc
  }, {})
})

const attributeGroupIds = getAttributeFilterIds(
  'product-catalog.filter.tag-product-ids',
)

const getTagInfo = (id: number) => {
  const group = attributeGroupIds.find((group) => group.id === id)
  return group
    ? { type: group.type, exists: true }
    : { type: '', exists: false }
}

const getTagClass = (id: number) => {
  const type = getTagInfo(id).type
  return type ? `tag-${type.replace(' ', '-').toLowerCase()}` : ''
}

const getIcon = (attribute: IconAttribute) =>
  getIconName(attribute, hoveredItem.value, isDesktop.value)

const toggleAttribute = (
  attribute: ProductAttributeResource & { active: boolean },
) => {
  attribute.active = !attribute.active
  selectedTags.value = props.item.product_attributes
    .filter((attr) => attr.active)
    .map((attr) => attr.id)
  emit('filter')
}
</script>

<style lang="scss" scoped>
.tag-filter {
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 248px;
  background: white;
  border: 2px solid rgba(241, 241, 244, 1);
  border-radius: 16px;
  z-index: 3;
  position: absolute;
  margin-top: 24px;

  @media (max-width: 991px) {
    position: relative;
    width: 100%;
    margin-top: 0;
    padding: 0 12px 12px 12px;
    border-radius: 0;
    border: none;
    margin-top: -3px;
  }
}

.tag-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 8px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  transition: background 0.3s ease-in-out;

  @media (min-width: 992px) {
    &:hover {
      background: rgba(246, 246, 247, 1);
    }

    padding: 13px 16px;
  }

  &:focus {
    outline: transparent;
  }
}

.tag-item::before {
  content: '';
  position: absolute;
  inset: 0;
  background: transparent;
  z-index: 1;
}

.icon-wrapper {
  z-index: 2;
}

.tag-label {
  padding: 6px;
  border-radius: 6px;
  text-transform: capitalize;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
}

.tag-discount {
  background: rgb(12, 13, 18);
  color: rgb(255, 255, 255);
}

.tag-coming-soon {
  background: rgb(245, 205, 145);
  color: rgb(12, 13, 18);
}

.tag-new {
  background: rgb(143, 189, 99);
  color: rgb(255, 255, 255);
}

.tag-bestseller {
  background: rgb(243, 128, 130);
  color: rgb(255, 255, 255);
}

.check-icon {
  width: 20px;
  height: 20px;
  pointer-events: none;
  color: rgb(241, 241, 244);
  fill: rgb(241, 241, 244);
  background-color: rgb(241, 241, 244);
  border-radius: 6px;
  transition: 0.3s ease-in-out;
}

.check-icon.active {
  color: #294538;
  background-color: #d7e9d8;
}

.check-icon.active.hovered {
  color: #d7e9d8;
  background-color: #294538;
}
</style>
