import { ref } from 'vue'

const useSideCartActions = () => {
  const sideCartOpen = ref(false)
  const openSideCart = () => (sideCartOpen.value = true)
  const closeSideCart = () => (sideCartOpen.value = false)

  return {
    sideCartOpen,
    openSideCart,
    closeSideCart,
  }
}

export default useSideCartActions
