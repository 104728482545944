<template>
  <div class="products-filters-dropdown">
    <div class="scroll-bar">
      <div
        v-for="category in matchedCategoriesFormatted"
        :key="category.id"
        class="filter-group"
      >
        <h3 class="parent-category">{{ category.name }}</h3>
        <ul>
          <li
            v-for="attribute in category.product_attributes"
            :key="attribute.id"
            class="attribute-item"
            :class="{ active: attribute.active }"
            role="button"
            tabindex="0"
            @click="toggleAttribute(attribute)"
            @focusin="hoveredItem = attribute.id"
            @focusout="hoveredItem = null"
            @keydown.enter.prevent="toggleAttribute(attribute)"
            @keydown.space.prevent="toggleAttribute(attribute)"
            @mouseenter="hoveredItem = attribute.id"
            @mouseleave="hoveredItem = null"
          >
            <span class="child-category">{{ attribute.name }}</span>

            <SvgIcon
              v-if="attribute.active"
              class="check-icon"
              :class="{
                hovered:
                  attribute.active && hoveredItem === attribute.id && isDesktop,
              }"
              :name="getIcon(attribute)"
            />
          </li>
        </ul>
      </div>

      <div v-for="group in filteredGroups" :key="group.id" class="filter-group">
        <h3 class="parent-category">{{ group.name }}</h3>
        <ul>
          <li
            v-for="attribute in group.product_attributes"
            :key="attribute.id"
            class="attribute-item"
            :class="{ active: attribute.active }"
            role="button"
            tabindex="0"
            @click="toggleAttribute(attribute)"
            @focusin="hoveredItem = attribute.id"
            @focusout="hoveredItem = null"
            @keydown.enter.prevent="toggleAttribute(attribute)"
            @keydown.space.prevent="toggleAttribute(attribute)"
            @mouseenter="hoveredItem = attribute.id"
            @mouseleave="hoveredItem = null"
          >
            <span class="child-category">{{ attribute.name }}</span>

            <SvgIcon
              v-if="attribute.active"
              class="check-icon"
              :class="{
                hovered:
                  attribute.active && hoveredItem === attribute.id && isDesktop,
              }"
              :name="getIcon(attribute)"
            />
          </li>
        </ul>
      </div>

      <div class="divider-line"></div>

      <button class="clear-filters" @click="clearFilters">
        {{ __('products', 'products.filters.button.text') }}
        <SvgIcon name="exit-new" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref, defineEmits } from 'vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import { __ } from '@/Helpers/i18n'
import useScreenBreakpoints from '@/Composables/UseScreenBreakpoints'
import { getIconName } from '@/Api/Products'
import { IconAttribute } from '@/Types/Product'
import { useProductsStore } from '@/Store/useProductsStore'

const props = defineProps<{
  attributeGroupsData: Array<{
    id: number
    name: string
    product_attributes: Array<{
      id: number
      name: string
      active: boolean
    }>
  }>
  productCategoryIds: number[]
}>()

const emit = defineEmits<{
  filter: [ids: number[]]
  clear: []
  categoryFilter: [ids: number[]]
}>()

const localAttributeGroups = reactive([...props.attributeGroupsData])
const { isDesktop } = useScreenBreakpoints()
const hoveredItem = ref<number | null>(null)

const productsStore = useProductsStore()

const matchedCategoriesFormatted = computed(() => {
  if (!props.productCategoryIds.length) {
    return []
  }

  const categories = (productsStore.productCategories || [])
    .filter((category) => props.productCategoryIds.includes(category.id))
    .map((category) => ({
      id: category.id,
      name: category.name,
      active: true,
    }))

  if (!categories.length) {
    return []
  }

  return [
    {
      id: -1,
      name: __('products', 'catalog.filter-category-button.text'),
      product_attributes: categories,
    },
  ]
})

const getIcon = (attribute: IconAttribute) =>
  getIconName(attribute, hoveredItem.value, isDesktop.value)

const toggleAttribute = (attribute: { id: number; active: boolean }) => {
  attribute.active = !attribute.active

  if (props.productCategoryIds.includes(attribute.id)) {
    updateFiltersCategory()
  } else {
    updateFilters()
  }
}

const updateFilters = () => {
  const activeFilters = localAttributeGroups
    .flatMap((group) => group.product_attributes)
    .filter((attr) => attr.active)
    .map((attr) => attr.id)

  if (activeFilters.length === 0 && props.productCategoryIds.length === 0) {
    clearFilters()
    return
  }

  emit('filter', activeFilters)
}

const updateFiltersCategory = () => {
  const activeCategoryIds = (
    matchedCategoriesFormatted.value[0]?.product_attributes ?? []
  )
    .filter(({ id, active }) => active && props.productCategoryIds.includes(id))
    .map(({ id }) => id)

  emit('categoryFilter', activeCategoryIds)
}

const filteredGroups = computed(() =>
  localAttributeGroups
    .map((group) => ({
      ...group,
      product_attributes: group.product_attributes.filter(
        (attr) => attr.active,
      ),
    }))
    .filter(({ product_attributes }) => product_attributes.length),
)

const clearFilters = () => {
  emit('clear')
}
</script>

<style lang="scss" scoped>
.products-filters-dropdown {
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 248px;
  background: white;
  border: 2px solid rgba(241, 241, 244, 1);
  border-radius: 16px;
  z-index: 3;
  position: absolute;
  margin-top: 24px;
  left: -118px;
  max-height: 364px;
}

.scroll-bar {
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(246, 246, 247, 1);
    border-radius: 1px;
    margin-bottom: 12px;
    margin-top: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(197, 199, 203, 1);
    border-radius: 1px;
  }
}

.filter-group {
  margin-bottom: 12px;
  width: 212px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(246, 246, 247, 1);
    border-radius: 1px;
    margin-bottom: 12px;
    margin-top: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(197, 199, 203, 1);
    border-radius: 1px;
  }
}

.check-icon.hovered {
  color: #d7e9d8;
  background-color: #294538;
}

.clear-filters {
  background-color: white;
  color: black;
  padding: 13px 16px 13px 16px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  width: 212px;
  border-radius: 8px;
  text-align: start;
  display: flex;

  svg {
    width: 16px;
    height: 16px;
    margin-left: auto;
  }

  &:hover {
    background: rgba(246, 246, 247, 1);
  }
}

.parent-category {
  color: #a0a0a0;
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  padding: 13px 0 13px 16px;
  width: 212px;
}

.child-category {
  color: #000;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.1px;
  cursor: pointer;
}

.hidden-checkbox {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.attribute-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 16px 13px 16px;
  width: 212px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background: rgba(246, 246, 247, 1);
  }
}

.check-icon {
  margin-left: auto;
  background: rgba(215, 233, 216, 1);
  color: rgba(41, 69, 56, 1);
  width: 20px;
  height: 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.divider-line {
  width: 184px;
  height: 1px;
  background: rgba(228, 228, 231, 1);
  margin: 10px 0;
  margin-left: 13px;
}

.visually-hidden {
  width: 0;
  height: 0;
  padding: 0;
  margin: -1px;
  border: 0;
}
</style>
