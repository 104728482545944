<template>
  <main
    class="products-catalog"
    :class="{ 'products-catalog--with-top-margin': shouldAddMargin }"
  >
    <div class="container">
      <ProductsCatalogFilters
        :base-url="baseUrl"
        class="filters"
        :set-sort="setSort"
        :sort="sort"
        @filter="filter"
        @reset="
          () => {
            reset()
            router.push(baseUrl)
          }
        "
        @update-show-tax="updateShowTax"
      />

      <ProfileDropshippingDiscountBanner
        v-if="hasFirstOrderDiscount"
        :banner-data="bannerTexts['firstOrderDiscount']"
      />

      <div
        v-if="
          productsLoaded && products && !(products?.pages[0].data ?? []).length
        "
        class="no-products"
      >
        <h2>{{ __('products', 'catalog.no-products-title.text') }}</h2>
        <p>{{ __('products', 'catalog.no-products-description.text') }}</p>
        <button class="reset-button" @click="reset">
          {{ __('products', 'catalog.no-products-button.text') }}
        </button>
      </div>

      <div v-else-if="products && products.pages" class="products-wrapper">
        <div
          v-for="(group, index) in products.pages"
          :key="index"
          class="products"
        >
          <ProductCatalogItem
            v-for="item in group.data"
            :key="item.id"
            :product="item"
            :show-tax="showTax"
            @open-side-cart="openSideCart"
          />
          <SideCart v-if="cart" :open="sideCartOpen" @close="closeSideCart" />
        </div>
      </div>

      <div v-if="hasNextPage" ref="buttonWrap" class="button-wrap">
        <button class="btn btn-primary" @click.prevent="() => fetchNextPage">
          <span>{{ __('global', 'Load more') }}</span>
        </button>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import ProductsCatalogFilters from './ProductsCatalogFilters.vue'
import ProductCatalogItem from './ProductCatalogItem.vue'
import { ExtendedProductType } from '@/Types/Product'
import { DISCOUNT_KEYS, PRO_PRODUCTS_SLUG } from '@/Helpers/Constants'
import { useGlobalStore } from '@/Store/useGlobalStore'
import useProductCatalogue from '@/Composables/useProductCatalogue'
import { useRouter } from 'vue-router'
import useSideCartActions from '@/Composables/useSideCartActions'
import SideCart from '@/Components/SideCart.vue'
import { useCartStore } from '@/Store/useCartStore'
import { __ } from '@/Helpers/i18n'
import { useElementVisibility } from '@vueuse/core'
import { getGlobalHeaderElementsHeights } from '@/Helpers/Global'
import useScreenBreakpoints from '@/Composables/UseScreenBreakpoints'
import ProfileDropshippingDiscountBanner from '../ProfileDashboard/ProfileDropshippingDiscountBanner.vue'
import { useLoginStore } from '@/Store/useLoginStore'

const { openSideCart, closeSideCart, sideCartOpen } = useSideCartActions()

const { isMobile } = useScreenBreakpoints()
const loginStore = useLoginStore()
const shouldAddMargin = computed(() => {
  const { headerHeight, bannerHeight } = getGlobalHeaderElementsHeights()
  return headerHeight + bannerHeight > 100 && isMobile.value
})

const hasFirstOrderDiscount = computed(
  () => loginStore.profile?.user_discounts?.[DISCOUNT_KEYS.FIRST_ORDER] ?? null,
)

const globalStore = useGlobalStore()
const productsUrl = computed(() => globalStore.productsUrl as string)
const router = useRouter()
const cartStore = useCartStore()
const baseUrl = computed(() =>
  productType.value === ExtendedProductType.PRO
    ? `${productsUrl.value}/${PRO_PRODUCTS_SLUG}`
    : productsUrl.value,
)
const cart = computed(() => cartStore.cart)

const {
  productType,
  products,
  hasNextPage,
  productsLoaded,
  fetchNextPage,
  filter,
  reset,
  sort,
  setSort,
} = useProductCatalogue({ baseUrl: productsUrl })

const buttonWrap = ref()

const buttonWrapIsVisible = useElementVisibility(buttonWrap)

watch(buttonWrapIsVisible, () => {
  if (buttonWrapIsVisible.value) {
    fetchNextPage.value()
  }
})

const bannerTexts = {
  firstOrderDiscount: {
    headerText: __(
      'products',
      'products.user-first-order-discount.banner.header',
    ),
    serviceProductCode: __(
      'products',
      'products.user-first-order-discount.banner.service-product.code',
    ),
    bannerClass: 'first-order-banner',
    bannerIcon: 'first-order-icon-wrapper',
    bannerText: 'first-order-global-text',
    textClass: 'first-order-text',
    textWrapper: 'first-order-text-wrapper',
    iconName: 'diamond-percent',
    iconClass: 'first-order-icon',
    showButton: false,
  },
}

const productTypeModel = ref<ExtendedProductType>(
  productType.value as ExtendedProductType,
)

watch(
  productType,
  () => (productTypeModel.value = productType.value as ExtendedProductType),
)

const showTax = ref(false)
const updateShowTax = (value: boolean) => {
  showTax.value = value
}
</script>

<style scoped lang="scss">
.products-catalog--with-top-margin {
  margin-top: 18px;
}

.products-catalog {
  padding: 0 0 5rem 0;
  opacity: 1;

  @include desktop-only {
    padding: 7px 0 100px 0;
    opacity: 1;
  }

  .button-wrap {
    display: flex;
    justify-content: center;
    margin-top: 72px;
  }

  .products {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 40px;
    margin-bottom: 40px;

    @media (max-width: 375px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 992px) {
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 36px;
      grid-row-gap: 56px;
      margin-bottom: 5.4rem;
    }
  }

  .products-wrapper {
    margin-top: 32px;
    margin-left: 8px;
    margin-right: 8px;

    @media (max-width: 991px) {
      margin-top: 24px;
    }
  }
}

.no-products {
  text-align: center;
  padding: 164px 0;

  @media (max-width: 991px) {
    padding: 72px 16px;
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 28.8px;
    letter-spacing: -0.3px;
    margin-bottom: 16px;

    @media (max-width: 991px) {
      margin-bottom: 24px;
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    font-weight: 500;
    color: rgba(77, 79, 86, 1);
    margin-bottom: 64px;

    @media (max-width: 991px) {
      margin-bottom: 88px;
    }
  }

  .reset-button {
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;
    padding: 22px 24px;
    background-color: #f4f4f4;
    border: none;
    font-size: 12px;
    line-height: 12px;
    border-radius: 100px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s ease;

    @media (max-width: 991px) {
      margin-bottom: 88px;
      width: 100%;
    }

    @media (min-width: 992px) {
      &:hover {
        background-color: #e0e0e0;
      }
    }
  }
}
</style>
