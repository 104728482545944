<template>
  <section
    class="faq-block"
    :style="{ background: footerStore.preFooterBackground }"
  >
    <div class="container">
      <div class="block">
        <h2 v-if="supportBlockSettings">
          {{ supportBlockSettings.value.button_block_title }}
        </h2>
        <p v-if="supportBlockSettings">
          {{ supportBlockSettings.value.button_block_subtitle }}
        </p>

        <div class="button-wrap">
          <Link
            v-if="supportBlockSettings"
            class="btn btn-cta"
            :url="supportBlockSettings.value.button_block_button_url"
            >{{ supportBlockSettings.value.button_block_button_title }}
          </Link>
        </div>
      </div>
      <div class="block">
        <div class="block-inner">
          <h2 v-if="supportBlockSettings">
            {{ supportBlockSettings.value.email_block_title }}
          </h2>
          <p v-if="supportBlockSettings">
            {{ supportBlockSettings.value.email_block_subtitle }}
          </p>
          <div class="button-wrap">
            <a
              v-if="!isMobile"
              aria-label="copy"
              class="btn btn-orange-inverted btn-outlined"
              href="#"
              @click.prevent="copyEmail"
            >
              <SvgIcon name="copy" />
            </a>
            <a
              v-if="supportBlockSettings"
              class="btn btn-orange-inverted btn-outlined"
              :href="`mailto:${supportBlockSettings.value.email_block_email}`"
            >
              {{ supportBlockSettings.value.email_block_email }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import Link from '@/Partials/Link.vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import { computed } from 'vue'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { SupportBlockSetting } from '@/Types/Setting'
import useScreenBreakpoints from '../Composables/UseScreenBreakpoints'
import useFooterStore from '@/Store/useFooterStore'

const globalStore = useGlobalStore()
const footerStore = useFooterStore()
const { isMobile } = useScreenBreakpoints()

const supportBlockSettings = computed(
  () =>
    globalStore.getSettingByKey('supportblocksetting') as
      | SupportBlockSetting
      | undefined,
)

const copyEmail = () => {
  navigator.clipboard.writeText(
    supportBlockSettings.value?.value.email_block_email ?? '',
  )
}
</script>

<style lang="scss">
.faq-block {
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  border-radius: 34px;
  @include desktop-only {
    margin-bottom: 1.4rem;
    border-radius: 60px;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }

  .block {
    padding-left: 2.8rem;
    padding-right: 2.8rem;
    width: 100%;
    @include desktop-only {
      width: 50%;
      margin-bottom: 0;
      padding: 12rem !important;
    }

    &:first-child {
      border-bottom: 1px solid $gray-8;
      padding-top: 4.8rem;
      padding-bottom: 6rem;
      @include desktop-only {
        border-right: 1px solid $gray-8;
        border-bottom: 0;
      }
    }

    &:last-child {
      padding-top: 6rem;
      padding-bottom: 6.8rem;
      @include desktop-only {
        padding: 12rem;
      }

      .button-wrap {
        margin-top: 3.4rem;
        display: flex;
        justify-content: flex-start;
        @include desktop-only {
          justify-content: flex-end;
        }
      }

      .btn {
        margin-left: 0.4rem;
      }
    }
  }

  h2 {
    margin-bottom: 1.4rem;
    @include desktop-only {
      margin-bottom: 1.8rem;
    }
  }

  .button-wrap {
    margin-top: 3.4rem;
    @include desktop-only {
      margin-top: 5.4rem;
    }
  }

  svg {
    width: 16px;
    height: 16px;
  }

  .email {
    margin-top: 2.2rem;
    color: $orange;
    font-weight: 800;
    text-decoration: underline;
    display: inline-block;
    @include desktop-only {
      margin-top: 5.2rem;
    }
  }
}
</style>
