import { Profile, VatStatus } from '@/Types/Profile'

export default class ProfileVM implements Profile {
  constructor(
    public id: string,
    public first_name: string | null,
    public last_name: string | null,
    public email: string | null,
    public phone: string | null,
    public is_confirmed: boolean,
    public receive_emails: boolean,
    public receive_sms: boolean,
    public is_privileged: boolean,
    public new_sign_up: boolean,
    public legal_entity: boolean,
    public company_name: string | null,
    public registration_number: string | null,
    public country: string | null,
    public vat_id: string | null,
    public address: string | null,
    public vat_status: VatStatus | null,
    public vat_status_decoded: string | null,
    public vat_certificate: string | null,
    public is_pro: boolean,
    public user_discounts: { [key: string]: string } | null,
    public is_onboarding_completed: boolean,
  ) {}

  public static createFrom(profile: Profile) {
    return new this(
      profile.id,
      profile.first_name,
      profile.last_name,
      profile.email,
      profile.phone,
      profile.is_confirmed,
      profile.receive_emails,
      profile.receive_sms,
      profile.is_privileged,
      profile.new_sign_up,
      profile.legal_entity,
      profile.company_name,
      profile.registration_number,
      profile.country,
      profile.vat_id,
      profile.address,
      profile.vat_status,
      profile.vat_status_decoded,
      profile.vat_certificate,
      profile.is_pro,
      profile.user_discounts,
      profile.is_onboarding_completed,
    )
  }

  get initials() {
    return this.first_name
      ? this.first_name.charAt(0) +
          (this.last_name ? this.last_name.charAt(0) : '')
      : this.email?.charAt(0)
  }

  get displayText() {
    return this.first_name ? `${this.first_name} ${this.last_name}` : this.email
  }

  get shortDisplayText() {
    return this.first_name ?? this.last_name ?? this.email
  }

  get vatVerified() {
    return this.vat_status === VatStatus.VERIFIED
  }
}
