import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useLoginStore } from '@/Store/useLoginStore'
import { useCartStore } from '@/Store/useCartStore'
import { postCartItem } from '@/Api/Cart'
import { withLang } from '@/routes'
import { analyticsSelectContent } from '@/Helpers/Analytics'
import { CartItemForm } from '@/Types/CartItem'
import { ProductVMType } from '@/ViewModels/ProductVM'
import useLangStore from '@/Store/useLangStore'
import { ProductResource, ProductType } from '@/Types/Product'
import useSignupPopupActions from './useSignupPopupActions'

export type ProductForWaitingList = Pick<
  ProductResource,
  'id' | 'unavailability_status'
>

export default function () {
  const router = useRouter()
  const loginStore = useLoginStore()
  const cartStore = useCartStore()
  const langStore = useLangStore()
  const {
    signUpOpen,
    openSignUp,
    closeSignUp,
    signupRedirectUrl,
    noRedirectAfterSignup,
  } = useSignupPopupActions()

  const userLoggedIn = computed(() => loginStore.userLoggedIn)

  const productForWaitingList = ref<ProductForWaitingList | null>(null)
  const openWaitingListForm = (product: ProductForWaitingList) =>
    (productForWaitingList.value = product)
  const closeWaitingListForm = () => (productForWaitingList.value = null)

  const showProSignupPopup = ref(false)

  const redirectToStudio = async ({
    product,
    showDropshippingFooter = false,
    designId = null,
  }: {
    product: Pick<ProductVMType, 'slug'>
    showDropshippingFooter?: boolean
    designId?: number | null
  }) => {
    await router.push(
      withLang({
        name: 'studio',
        params: { slug: product.slug, designId },
        query: { showDropshippingFooter },
      }),
    )
  }

  const redirectToCollectionBoxCustomization = async (
    product: Pick<ProductVMType, 'slug'>,
  ) => {
    await router.push(
      withLang({
        name: 'collection-box-customization',
        params: { productSlug: product.slug },
      }),
    )
  }

  const handleStartDesigningClick = (
    product: Pick<ProductVMType, 'type' | 'slug'>,
    showDropshippingFooter = false,
  ) => {
    if (userLoggedIn.value) {
      if (product.type === ProductType.COLLECTION_BOX) {
        redirectToCollectionBoxCustomization(product)
        return
      }

      redirectToStudio({ product, showDropshippingFooter })
    } else {
      const page =
        product.type === ProductType.COLLECTION_BOX
          ? 'collection-box'
          : 'studio'

      signupRedirectUrl.value = `/${langStore.getLang()}/${page}/${
        product.slug
      }`

      openSignUp()
    }
  }

  const handleViewProductClick = (
    product: Pick<
      ProductVMType,
      'id' | 'title' | 'lowestAvailablePrice' | 'is_pro'
    >,
    linkTo: string,
  ) => {
    analyticsSelectContent(product)

    if (userLoggedIn.value) {
      router.push(linkTo)
      return
    }

    signupRedirectUrl.value = linkTo
    openSignUp()
  }

  const addingToCart = ref(false)

  const addToCart = async (
    product: Pick<ProductVMType, 'id' | 'minimumQuantityToUse'>,
    attributes: Partial<CartItemForm> = {},
  ) => {
    if (!cartStore.cart) {
      openSignUp()
      return
    }

    addingToCart.value = true

    try {
      await postCartItem({
        cart_id: cartStore.cart.id,
        product_id: product.id,
        quantity: product.minimumQuantityToUse,
        is_sample: 0,
        ...attributes,
      })
      await cartStore.setCarts()
    } catch (e) {
      console.error(e)
    } finally {
      addingToCart.value = false
    }
  }

  return {
    productForWaitingList,
    openWaitingListForm,
    closeWaitingListForm,
    signUpOpen,
    openSignUp,
    closeSignUp,
    showProSignupPopup,
    handleStartDesigningClick,
    addToCart,
    redirectToStudio,
    addingToCart,
    signupRedirectUrl,
    noRedirectAfterSignup,
    handleViewProductClick,
  }
}
