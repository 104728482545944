import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import { Error } from '@/Types/Error'
import { __ } from './i18n'
import { CountryResourceMainFields } from '@/Types/Country'
import { SessionSetting } from '@/Types/SessionSetting'
import { useGlobalStore } from '@/Store/useGlobalStore'
import useLangStore from '@/Store/useLangStore'

export const SCROLL_LOCK_CSS_CLASS = 'scroll-lock'

export const toggleBodyCssClass = (className: string, force: boolean): void => {
  document.body.classList.toggle(className, force)
}

export const getGlobalHeaderElementsHeights = () => {
  const header = document.querySelector('.container') as HTMLElement | null
  const banner = document.querySelector(
    '.global-message-banner',
  ) as HTMLElement | null

  return {
    headerHeight: header ? header.offsetHeight : 0,
    bannerHeight: banner ? banner.offsetHeight : 0,
  }
}

export const fireErrorMessage = (message: string | null = null) => {
  Swal.fire({
    position: 'top-end',
    icon: 'error',
    title: message ?? __('global', 'Something went wrong'),
    showConfirmButton: false,
    timer: 3000,
    toast: true,
  })
}

export const fireSuccessMessage = (message: string) => {
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: message,
    showConfirmButton: false,
    timer: 3000,
    toast: true,
  })
}

export const parseErrorAndFireErrorMessage = (e: unknown, prefix?: string) => {
  //TODO: use something like zod to parse the error
  let errorMessage = 'Something went wrong, please reload the page'
  if (
    e &&
    typeof e === 'object' &&
    'response' in e &&
    e.response &&
    typeof e.response === 'object' &&
    'data' in e.response &&
    e.response.data
  ) {
    if (
      typeof e.response.data === 'object' &&
      'message' in e.response.data &&
      typeof e.response.data.message === 'string'
    ) {
      errorMessage = e.response.data.message
    } else if (typeof e.response.data === 'string') {
      errorMessage = e.response.data
    } else if (
      Array.isArray(e.response.data) &&
      e.response.data[0] &&
      typeof e.response.data[0] === 'object' &&
      'message' in e.response.data[0] &&
      typeof e.response.data[0].message === 'string'
    ) {
      errorMessage = e.response.data[0].message
    }
  }

  fireErrorMessage((prefix ?? '') + errorMessage)
}

export const findErrorField = (
  errors: { message: string; field: string }[],
  fieldName: string,
) => errors && errors.length && errors.find((i: Error) => i.field === fieldName)

export const addressSelectReduce = (item: CountryResourceMainFields) =>
  item.code

export const setPageTitle = (title: string) => {
  ;(document.querySelector('title') as HTMLElement).innerText = `${title} | ${(
    document.querySelector('#app') as HTMLElement
  ).getAttribute('data-title')}`
}

export const setMetaTitle = (title: string) => {
  ;(document.querySelector('meta[name="title"]') as HTMLElement).setAttribute(
    'content',
    title,
  )
}

export const setMetaDescription = (description: string) => {
  ;(
    document.querySelector('meta[name="description"]') as HTMLElement
  ).setAttribute('content', description)
}

export const setPrerenderStatus = (status: string) => {
  ;(
    document.querySelector('meta[name="prerender-status-code"]') as HTMLElement
  ).setAttribute('content', status)
}

export const floatToCurrency = (
  float: number,
  {
    maximumFractionDigits = 2,
    currency,
  }: { maximumFractionDigits?: number; currency?: string | null } = {},
) => {
  const currentCountry = getCurrentSessionValue('CURRENT_COUNTRY')
  const langStore = useLangStore()

  return new Intl.NumberFormat(
    `${langStore.getLang()}-${currentCountry.code}`,
    {
      style: 'currency',
      currency: currency || currentCountry.currency.name,
      maximumFractionDigits,
      currencyDisplay: 'narrowSymbol' /* $ instead of US$ */,
    },
  ).format(float)
}

export const getCurrentSessionValue = (key: SessionSetting['id']): any => {
  const globalStore = useGlobalStore()
  const sessionSettings = globalStore.sessionSettings
  const countries = globalStore.countries

  if (!countries) return null

  const id = sessionSettings?.find(
    (item: SessionSetting) => item.id === key,
  )?.value
  if (!id) return null
  if (key === 'CURRENT_COUNTRY') {
    return countries.find((item: CountryResourceMainFields) => item.id === id)
  } else {
    return id
  }
}

export const triggerTax = async (value: boolean) => {
  const globalStore = useGlobalStore()
  await globalStore.putSessionSetting({ id: 'SHOW_TAX', value })

  await globalStore.setSessionSettings()
}

export const getCurrentCountry = (): string => {
  const globalStore = useGlobalStore()
  const sessionSettings = globalStore.sessionSettings!
  const countries = globalStore.countries!

  if (sessionSettings.find((item) => item.id === 'CURRENT_COUNTRY')) {
    const id = sessionSettings.find(
      (item) => item.id === 'CURRENT_COUNTRY',
    )?.value
    const country = countries.find((item) => item.id === id)
    if (country) {
      return country.code
    }

    return ''
  }
  return ''
}

export const getDate = (date: Date) =>
  date.toLocaleString(useLangStore().getLang(), { month: 'long' }) +
  ' ' +
  date.getDate() +
  ', ' +
  date.getFullYear()

export const formatDateWithDots = (date: Date) => {
  const zeroPaddedMonth = (date.getMonth() + 1).toString().padStart(2, '0')
  const zeroPaddedDate = date.getDate().toString().padStart(2, '0')
  return [zeroPaddedDate, zeroPaddedMonth, date.getFullYear()].join('.')
}

export const getStaticContentDomain = () =>
  import.meta.env.VITE_STATIC_CONTENT_DOMAIN

export const getAwsFileUrl = (fileRoute: string) =>
  `${getStaticContentDomain()}/static/${fileRoute}`

export const fileUrlToFile = async (
  fileUrl: string,
  fileNameWithoutExtension: string,
): Promise<File> => {
  const defaultFormat = 'jpg'
  let fileUrlCopy = fileUrl

  const format =
    fileUrl.includes('.') && fileUrl.split('.').pop() !== ''
      ? fileUrl.split('.').pop()
      : defaultFormat

  // Add timestamp to prevent CORS errors on safari due to caching
  const paramPrefix = fileUrlCopy.includes('?') ? '&' : '?'
  fileUrlCopy += `${paramPrefix}t=${Date.now()}`

  return new File(
    [await (await fetch(fileUrlCopy)).blob()],
    `${fileNameWithoutExtension}.${format}`,
    { type: `image/${format}` },
  )
}

export const dataUrlToFile = (dataURI: string, fileName: string) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  const byteString =
    dataURI.split(',')[0].indexOf('base64') >= 0
      ? atob(dataURI.split(',')[1])
      : unescape(dataURI.split(',')[1])

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new File([new Blob([ia], { type: mimeString })], fileName, {
    type: mimeString,
  })
}

export const downloadBase64File = (dataUrl: string, fileName: string) => {
  const downloadLink = document.createElement('a')
  downloadLink.href = dataUrl
  downloadLink.download = fileName
  downloadLink.click()
}

const getLocaleBasedOnLang = () => {
  const languageToLocale = { en: 'en-US', de: 'de-DE' }
  const lang = useLangStore().getLang()
  return languageToLocale[lang as keyof typeof languageToLocale] ?? 'en-US'
}

export const formatCurrencyEur = (number: number | null) => {
  if (number === null) return ''
  return new Intl.NumberFormat(getLocaleBasedOnLang(), {
    style: 'currency',
    currency: 'EUR',
  }).format(number)
}

export const isLessThanOnePercentDifferent = (a: number, b: number) => {
  const onePercent = 0.01
  return Math.abs(a - b) / a <= onePercent
}

export const arrayToPattern = <T>(inputArray: T[]) => {
  const result: T[][] = []
  const pattern = [2, 3]
  let i = 0

  while (i < inputArray.length) {
    for (const size of pattern) {
      if (i + size > inputArray.length) {
        result.push(inputArray.slice(i))
        i = inputArray.length
      } else {
        result.push(inputArray.slice(i, i + size))
        i += size
      }

      if (i >= inputArray.length) break
    }
  }

  return result
}

export const convertDate = (date: Date, lang: string) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  return date.toLocaleDateString(lang, options as Intl.DateTimeFormatOptions)
}

export const isMoreThanDaysAgo = (date: Date, days: number) => {
  const dateDaysAgo = new Date()
  dateDaysAgo.setDate(dateDaysAgo.getDate() - days)

  return date < dateDaysAgo
}

export const isSvgSrc = (src: string) => src.split('.').pop() === 'svg'

export const isValidUrl = (str: string) => {
  try {
    new URL(str)
    return true
  } catch (e) {
    return false
  }
}

export const FEATURE_FLAGS = {
  SHOW_SIGN_UP_FLOW: import.meta.env.VITE_SHOW_SIGN_UP_FLOW === 'true',
  SHOW_ONBOARDING_FLOW: import.meta.env.VITE_SHOW_ONBOARDING_FLOW === 'true',
  SHOW_NEW_PRODUCTS_CATALOG:
    import.meta.env.VITE_SHOW_NEW_PRODUCTS_CATALOG === 'true',
  SHOW_NEW_HOME_PAGE: import.meta.env.VITE_SHOW_NEW_HOME_PAGE === 'true',
}
